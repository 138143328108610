import React, { useState, useEffect } from 'react';
import  { URL_BASE } from '../../api/managerData';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import IdiomaData from '../../model/IdiomaData';
import './UltimoLancamento.scss';

const UltimoLancamento = (props) => {

    const [lancamentos_empty, setEmptyLancamento] = useState(false);
    const [lancamentos, setLancamento] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        async function getLancamentos() {

            setLoad(true);

            let payload = {
                safra: props.paramData.safraid,
                registro: props.paramData.fazendaid,
                tipo_registro: 1,
            };

            let headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${props.userData.access_token}`,
            }

            try {
                const { data } = await axios.post(`${URL_BASE}/dashboard/lancamentos`, payload, { "headers": headers });
                const data_length = data.length
                const items_length = data.filter((item) => item.last_data === false).length

                if (data_length === items_length) {
                    setEmptyLancamento(true);
                }

                setLancamento(data);

            } catch (error) {
                console.log(error);
            }

            setLoad(false);
        }

        if(props.showLancamentos){
            getLancamentos();
        }

     }, [props.showLancamentos, props.paramData.fazendaid, props.paramData.safraid, props.userData.access_token]);

    return (
        <Modal show={props.show_lancamentos} size="lg" onHide={props.handleCloseLancamentos}>
                    <Modal.Header closeButton>
                        <Modal.Title>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'ultimos_lancamentos_titulo').text}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {load &&
                            <div className="d-flex align-itens-center w-100 justify-content-center">
                                <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" /> <h2 className="ml-2">Carregando</h2>
                            </div>
                        }
                        {!load && !lancamentos_empty &&
                            <Table bordered hover striped className="mt-5">
                                <thead>
                                    <tr>
                                        <th>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'modulo').text}</th>
                                        <th className="text-center">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'data').text}</th>
                                        <th>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'operacao').text}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {lancamentos.map((item, index) => {
                                        return (
                                            <tr key={index} className={item.last_data === false ? "d-none" : ''}>
                                                <td>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', item.modulo).text}</td>
                                                <td className="text-center">{item.last_data}</td>
                                                {item.operacao === 'perfil_financeiro_centro_custo' ?
                                                    <td>
                                                        {IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_financeiro').text} {IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'centro_custo').text.toLowerCase()}
                                                    </td>
                                                    :
                                                    <td>
                                                        {IdiomaData.selectGroupItem(props.idiomaData, 'messages', item.operacao).text}
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }

                        {lancamentos_empty && !load &&
                            <h4 className="text-center py-5">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'ultimos_lancamentos_sem_registros').text}</h4>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.handleCloseLancamentos}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
    );
}

export default UltimoLancamento;
