import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn } from '../../actions';
import { isNullOrUndefined } from '@syncfusion/ej2-base';

const PrivateRoute = (props) => {
    
    return props.isSignedIn || !isNullOrUndefined(localStorage.getItem('USER_DATA_SESSION_BKM')) ? <Outlet /> : <Navigate to="/login" />;
}

const mapStateToProps = state => {
    return { isSignedIn: state.auth.isSignedIn };
};
    
export default connect( mapStateToProps, { signIn } )(PrivateRoute);