class NotificaData {

    static TIPO_MESSAGE = { 
        AVISO: 0,
        SUCESSO: 1,
        ERRO: 2,
        INFORMATIVO: 3
    };

    static NIVEL_MESSAGE = {
        DESCISAO: 0,
        INFORMATIVO: 1
    };

    static TIPO_OBJ={
        TOAST: 0,
        DIALOG: 1
    };
   
    constructor(){
        this.tipo=NotificaData.TIPO_TOAST;
        this.data=null;
        this.buttomModel=[];
        this.paramData=null;
    }

    static createDialogNotifica(data, buttomModel=[], paramData=null){
        let notificaData = new NotificaData();
        notificaData.tipo=NotificaData.TIPO_OBJ.DIALOG;
        notificaData.data=data;
        notificaData.buttomModel=buttomModel;
        notificaData.paramData=paramData;

        return notificaData;
    }

    /* postion X: Left, Right, Center  e position Y: Top, Bottom*/
    static createToastNotifica(data, tipo_message=NotificaData.TIPO_MESSAGE.AVISO, position={ X: 'Center', Y: 'Top'}, paramData=null){
        let notificaData = new NotificaData();
        notificaData.tipo=NotificaData.TIPO_OBJ.TOAST;
        notificaData.data=data;
        notificaData.position=position;
        notificaData.tipo_message=tipo_message;
        notificaData.paramData=paramData;

        return notificaData;
    }
}

export default NotificaData;