import axios from "axios";
import {URL_BASE} from "../component/template/Header";
import ParamData from "../model/ParamData";

export const looksForNotifications = async (user, fazenda_id, cli_codigo, safra_id) => {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${user.access_token}`,
    }

    let params = {
        user_tipo: user.user_info.user.tipo,
        registro: fazenda_id,
        cli_codigo: cli_codigo,
        safra: safra_id
    }

    try {
        const {data} = await axios.get(`${URL_BASE}/notificacoes/looks/for/notifications`, {params, headers})
        return await (data);
    } catch (e) {
        console.log(e)
    }
}

export const createNotification = async (modulo_id, operacao_id, tipo_id, situacao_id, mensagem_id) => {

    const user_data_local = JSON.parse(localStorage.getItem('USER_DATA_SESSION_BKM'));
    const param_data = ParamData.recoverParamDataFromStorage()

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${user_data_local.access_token}`,
    }

    let params = {
        fazenda_id: param_data.fazendaid,
        cli_codigo: param_data.clienteid,
        safra_id: param_data.safraid,
        modulo_id: modulo_id,
        operacao_id: operacao_id,
        tipo_id: tipo_id,
        situacao_id: situacao_id,
        mensagem_id: mensagem_id,
        user_tipo: user_data_local.user_info.user.tipo
    }

    try {
        const {data} = await axios.get(`${URL_BASE}/notificacoes/creates`, {params, headers})
        return await (data);
    } catch (e) {
        console.log(e)
    }
}
