import React, { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { signOut } from '../actions';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { connect } from 'react-redux';
import logoWhite from '../image/logos/logo-resultta.png';
import logoWhiteShortSvg from '../image/logos/logo_simples_white_jsx.svg';
import IdiomaData from '../model/IdiomaData';
import { USUARIO, MODULO, checkUserAcessoModulo } from '../api/Modulo';
import Util from '../api/Util';
import './SidebarLeft.scss';

export function SidebarLeft(props) {
    const [dockOpen, setDockOpen] = useState(true)
    const [displayExportacao, setDisplayExportacao] = useState('none')
    const [displayProcessamento, setDisplayProcessamento] = useState('none')
    const [displayDashboard, setDisplayDashboard] = useState('none')
    const [displayAdmin, setDisplayAdmin] = useState('none')
    const [displayAcompanhamento, setDisplayAcompanhamento] = useState('none')
    //const dockBar = useRef(null)
    let dockBar;
    const {userData} = props

    const checkPermissionAccessItem = useCallback(() => {
        if (userData.user_info.user.tipo === USUARIO.INTTEGRA) {
            setDisplayProcessamento('flex')
            setDisplayDashboard('flex')
            setDisplayAcompanhamento('flex')
        } else if (userData.user_info.user.tipo === USUARIO.FRANQUIA) {
            setDisplayAcompanhamento('flex')
        } else if (userData.user_info.user.tipo === USUARIO.CLIENTE) {
            setDisplayAcompanhamento('flex')
        }

        if (checkUserAcessoModulo(userData, MODULO.REPORTAGEM)) {
            setDisplayProcessamento('flex')
        }

        if (checkUserAcessoModulo(userData, MODULO.DASHBOARD)) {
            setDisplayDashboard('flex')
        }

        if (checkUserAcessoModulo(userData, MODULO.ADMIN)) {
            setDisplayAdmin('flex')
        }

        if (checkUserAcessoModulo(userData, MODULO.EXPORTACAO_XLSX)) {
            setDisplayExportacao('flex')
        }
    }, [userData])

    const toggleClick = () => {
        dockBar.toggle();
    }

    const onChange = () => {
        setDockOpen(dockBar.isOpen)

        const event = document.createEvent("CustomEvent");
        event.initCustomEvent("resize_sidebar", true, true, "Object");
        event.dock_open=dockBar.isOpen;
        window.dispatchEvent(event);
        const controlSection = document.getElementsByClassName("control-section e-content-animation");
        if (dockBar.isOpen) controlSection[0].style.marginLeft = '250px'
    }

    const checkSelectedUrl = (path) =>{

        if(process.env.NODE_ENV === 'production'){
            const arr = process.env.REACT_APP_ROUTER_BASENAME.split("/").filter(x=>x!=="");
            arr.push(path);
            return Util.checkPathUrl(arr);
        }

        return Util.checkPathUrl([path]);
    }

    useEffect(() => {
        checkPermissionAccessItem()
    }, [checkPermissionAccessItem])

  return (
    <SidebarComponent id="dockSidebar" change={onChange} created={() => {}} ref={Sidebar => dockBar = Sidebar} enableDock={true} dockSize="72px" width="250px" zIndex={10000}>
        <div className="dock">
            <div className="sidebar-item-logo">
                <Link to="/">
                    <img alt="Resultta" src={logoWhite} style={{ display: dockOpen ? "block" : "none", width: "66%", margin: "auto", padding: "2px 0" }} />
                    <img alt="Resultta" src={logoWhiteShortSvg} style={{ height: "55px", display: dockOpen ? "none" : "block", width: "auto", padding: "0" }} />
                </Link>
            </div>
            <div className="sidebar-item-toggle" id="toggle" onClick={toggleClick}>
                <span className="e-icons expand" style={{ float: "right", fontWeight: "bold" }} ></span>
            </div>
            <ul>
                <li className={`sidebar-item ${checkSelectedUrl('lancamento') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/lancamento" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'lancamento').text}>
                        <span className="material-icons sidebar-item-icon">assignment</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'lancamento').text}</span>
                    </Link>
                </li>

                <li className={`sidebar-item ${checkSelectedUrl('checklist') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/checklist" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'checklist').text}>
                        <span className="material-icons sidebar-item-icon">assignment_turned_in</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'checklist').text}</span>
                    </Link>
                </li>

                <li style={{ display: displayExportacao }} className={`sidebar-item ${checkSelectedUrl('exportacao') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/exportacao" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'exportacao').text}>
                        <span className="material-icons sidebar-item-icon">assignment_returned</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'exportacao').text}</span>
                    </Link>
                </li>

                <li className={`sidebar-item ${checkSelectedUrl('configuracao') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/configuracao" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'configuracao').text}>
                        <span className="material-icons sidebar-item-icon">settings_applications</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'configuracao').text}</span>
                    </Link>
                </li>

                <li style={{ display: displayProcessamento }} className={`sidebar-item ${checkSelectedUrl('processamento') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/processamento" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'processamento').text}>
                        <span className="material-icons sidebar-item-icon">poll</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'processamento').text}</span>
                    </Link>
                </li>

                <li style={{ display: displayDashboard }} className={`sidebar-item ${checkSelectedUrl('dashboard') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/dashboard" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'dashboard').text}>
                        <span className="material-icons sidebar-item-icon">personal_video</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'dashboard').text}</span>
                    </Link>
                </li>

                <li style={{ display: displayAcompanhamento }} className={`sidebar-item ${checkSelectedUrl('perfil') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/perfil/fazenda" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_da_fazenda').text}>
                        <span className="material-icons sidebar-item-icon">assignment_ind</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_da_fazenda').text}</span>
                    </Link>
                </li>

                <li className={`sidebar-item ${checkSelectedUrl('suporte') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/suporte" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_da_fazenda').text}>
                        <span className="material-icons sidebar-item-icon">message</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'suporte').text}</span>
                    </Link>
                </li>

                <li className={`sidebar-item ${checkSelectedUrl('pergunta') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/pergunta/frequente" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_da_fazenda').text}>
                        <span className="material-icons sidebar-item-icon">sms_failed</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perguntas_frequentes').text}</span>
                    </Link>
                </li>

                <li style={{ display: displayAdmin }} className={`sidebar-item ${checkSelectedUrl('admin') ? 'selected-item-side-bar' : ''}`} >
                    <Link to="/admin" className="sidebar-item-link" title={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'perfil_da_fazenda').text}>
                        <span className="material-icons sidebar-item-icon">supervisor_account</span>
                        <span className="e-text">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'admin').text}</span>
                    </Link>
                </li>
            </ul>
        </div>
    </SidebarComponent>
  )
}

const mapStateToProps = state => {
    return { idiomaData: state.idiomaData, userData: state.auth.userData };
};

export default connect(mapStateToProps, { signOut })(SidebarLeft);
