import { isNullOrUndefined } from '@syncfusion/ej2-base';

export const MODULO = {
    FAZENDA: 0,
    AGRICOLA: 1,
    CLIMA: 2,
    EQUIPE: 3,
    ESTOQUE: 4,
    FINANCEIRO: 5,
    MAQUINA: 6,
    PECUARIA: 7,
    REPORTAGEM: 8,
    CONSOLIDACAO: 9,
    DASHBOARD: 10,
    ADMIN: 11,
    ANALISE_DESCRITIVA:12,
    ANALIZE_FAZENDA:13,
    ARVORE_DESCISAO:14,
    CALCULADORA:15,
    REGUA_REFERENCIA:16,
    BEM_ESTAR:17,
    EXPORTACAO_XLSX:18,
}

export const USUARIO = {
    INTTEGRA: 'U',
    FRANQUIA: 'UQ',
    FRANQUIA_GRUPO: 'GR',
    CLIENTE: 'S'
};

export const checkUserAcessoModulo = (userData, modulo) => {
    if (!isNullOrUndefined(userData.user_info) &&
        userData.user_info.logged_in &&
        !isNullOrUndefined(userData.user_info.acesso_modulo) &&
        userData.user_info.acesso_modulo.benchmkg
    ) {

        switch (modulo) {
            case MODULO.FAZENDA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.fazenda) && userData.user_info.acesso_modulo.fazenda;
            case MODULO.AGRICOLA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.agricola) && userData.user_info.acesso_modulo.agricola;
            case MODULO.CLIMA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.clima) && userData.user_info.acesso_modulo.clima;
            case MODULO.EQUIPE:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.equipe) && userData.user_info.acesso_modulo.equipe;
            case MODULO.ESTOQUE:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.estoque) && userData.user_info.acesso_modulo.estoque;
            case MODULO.FINANCEIRO:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.financeiro) && userData.user_info.acesso_modulo.financeiro;
            case MODULO.MAQUINA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.maquina) && userData.user_info.acesso_modulo.maquina;
            case MODULO.PECUARIA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.pecuaria) && userData.user_info.acesso_modulo.pecuaria;
            case MODULO.REPORTAGEM:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.reportagem) && userData.user_info.acesso_modulo.reportagem;
            case MODULO.CONSOLIDACAO:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.consolidacao) && userData.user_info.acesso_modulo.consolidacao;
            case MODULO.DASHBOARD:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.dashboard) && userData.user_info.acesso_modulo.dashboard;
            case MODULO.ADMIN:
                return userData.user_info.user.tipo === USUARIO.INTTEGRA;
            case MODULO.BEM_ESTAR:
                return userData;
            case MODULO.ANALISE_DESCRITIVA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.analise_descritiva) && userData.user_info.acesso_modulo.analise_descritiva;
            case MODULO.ANALIZE_FAZENDA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.analise_fazenda) && userData.user_info.acesso_modulo.analise_fazenda;
            case MODULO.ARVORE_DESCISAO:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.arvore_descisao) && userData.user_info.acesso_modulo.arvore_descisao;
            case MODULO.CALCULADORA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.calculadora) && userData.user_info.acesso_modulo.calculadora;
            case MODULO.REGUA_REFERENCIA:
                return !isNullOrUndefined(userData.user_info.acesso_modulo.regua_referencia) && userData.user_info.acesso_modulo.regua_referencia;
            case MODULO.EXPORTACAO_XLSX:
                    return !isNullOrUndefined(userData.user_info.acesso_modulo.exportacao_xlsx) && userData.user_info.acesso_modulo.exportacao_xlsx;
            default:
                return false;
        }
    } else {
        return false;
    }
}
