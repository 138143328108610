//import React  from 'react';
import { connect } from 'react-redux';
import { changeFilter, notificar } from '../../actions';
import SidebarRightFilterFazenda from '../filter/SidebarRightFilterFazenda';
import NotificaData from '../../model/NotificaData';
import IdiomaData from '../../model/IdiomaData';
import { MODULO, checkUserAcessoModulo } from '../../api/Modulo';
import { Navigate } from 'react-router-dom';

class SidebarRightFilterLancamento extends SidebarRightFilterFazenda {

    constructor(props) {
        super(props);
        
        this.state={sem_fazenda_lancado:false};
    }
    
    buildRenderFilterFazendaBase = () => {
        if(this.state.sem_fazenda_lancado){
            return (<Navigate to={process.env.REACT_APP_ROUTER_BASENAME_HEADER + 'lancamento/fazenda'} />);
        }else return (<></>)
        
    }

    safraFilterChange = ((_super) => {
        return () => {
            _super();
            if (this.safraFilterCheckSelected()) {
                if (this.fazendaFilterObj.dataSource.length === 0) {
                    this.props.notificar(NotificaData.createToastNotifica(IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'clique_aplicar_continuar').text, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                    this.props.notificar(NotificaData.createToastNotifica(IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'sem_fazenda_caracterizada').text, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                }
            }
        };

    })(this.safraFilterChange);

    clickAplicar = ((_super) => {
        return () => {
            _super();
            if (this.fazendaFilterCheckSelected()) {
                if (this.hasChangeParameter)
                    this.props.changeFilter(this.paramData);
                this.hasChangeParameter = false;
                this.sidebarobj.hide();
            } else {
                if (this.safraFilterCheckSelected()) {
                    if (this.fazendaFilterObj.dataSource.length === 0) {
                        this.props.notificar(NotificaData.createToastNotifica(IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'clique_aplicar_continuar').text, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                        this.props.notificar(NotificaData.createToastNotifica(IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'sem_fazenda_caracterizada').text, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                        //this.props.moveCaracterizaFazenda(true);
                        if (checkUserAcessoModulo(this.props.userData, MODULO.FAZENDA)) {
                            this.setState({sem_fazenda_lancado:true});
                        } else {
                            this.props.notificar(NotificaData.createToastNotifica(IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'acesso_modulo_fazenda').text, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                            this.props.notificar(NotificaData.createToastNotifica(`${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'usuario_sem_permissao_acesso').text}: ${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'title_modulo_cadastro_fazenda').text}`, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                        }
                    }
                }
            }
        };

    })(this.clickAplicar);
}

const mapStateToProps = state => {
    return { userData: state.auth.userData, idiomaData: state.idiomaData };
};

export default connect(mapStateToProps, { changeFilter, notificar })(SidebarRightFilterLancamento);