import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Breadcrumb} from 'react-bootstrap';
import { updateListReferencia } from '../actions';
import './ListReferencia.scss';

class ListReferencia extends Component {

    componentWillUnmount(){
        this.props.updateListReferencia({list:[]});
    }

    render(){
        const temp =this.props.listReferenciaData.list.map((el, index) =>
            <Breadcrumb.Item key={index} active>{el}</Breadcrumb.Item>
        );

        return(
            <Breadcrumb className="teste" id="list-referencia">
                {temp}
            </Breadcrumb>
        );
    }
}


const mapStateToProps = state => {
    return {  listReferenciaData: state.listReferenciaData };
};

export default connect(mapStateToProps, {updateListReferencia})(ListReferencia);
