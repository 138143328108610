import React from 'react';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import _ from 'lodash';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import IdiomaData from '../../model/IdiomaData';
import SidebarRightFilterBase from './SidebarRightFilterBase';
import ParamData from '../../model/ParamData';
import { USUARIO } from '../../api/Modulo';
import { HeaderContext } from '../../HeaderContext';

export default class SidebarRightFilterFranquia extends SidebarRightFilterBase {

    constructor(props) {
        super(props);
    
        this.paramData=new ParamData();
        this.dataFranquiaFilter =_.map( _.uniqBy(this.props.userData.info_fazenda.dados, 'franquia_id'), _.partialRight(_.pick, ['franquia_id', 'nome_franquia']));
        this.checkPermissionAccessFilter();
    }

    initFilterData =()=>{
        this.checkPermissionAccessFilter();
        const tempParaData=ParamData.recoverParamDataFromStorage();
        if(!isNullOrUndefined(tempParaData)){
            this.paramData=Object.assign(this.paramData, tempParaData );

            if(this.franquiaFilterObj.dataSource){
                const obj = this.franquiaFilterObj.dataSource.find(x=>x.franquia_id===this.paramData.franquiaid);
                if(obj){
                    this.franquiaFilterObj.value=this.paramData.franquiaid;
                }
            }
        }

        if(isNullOrUndefined(this.franquiaFilterObj.value)){
            const tmpObj = _.first(this.dataFranquiaFilter);
            if(tmpObj){
                this.franquiaFilterObj.value=tmpObj.franquia_id;
            }
        }
    }

    checkPermissionAccessFilter =()=>{
        if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA){
            //this.setState({displayFranquia:'inline'});
            this.displayFranquia='inline';

            if(this.franquiaFilterObj){
                this.franquiaFilterObj.enabled=true;
            }
        }else{
            this.displayFranquia='none';

            if(this.franquiaFilterObj){
                this.franquiaFilterObj.enabled=false;
            }
        }
    }

    franquiaFilterCheckSelected =() =>{
        if(!isNullOrUndefined(this.franquiaFilterObj.value) && !isNullOrUndefined(this.franquiaFilterObj.index)){
            this.franquiaFilterLabelErrorObj.style.display="none";
            return true;
        }else{
            this.franquiaFilterLabelErrorObj.style.display="inline-block";
            return false;
        }
    }

    franquiaFilterChange = () => {
        this.hasChangeParameter=true;
        this.paramData.setFranquiaId(this.franquiaFilterObj.value);
    }

    clickAplicar = ((_super) =>{
        return () => {
            _super();
            this.franquiaFilterCheckSelected();
        };
    })(this.clickAplicar);


    franquiaFilterSelect = (args) =>{
        this.hasUserSelectedParameter=true;
    }

    buildRenderFilterBase = () => {
        return (
        <div>
            <div className="filter-container-element" style={{display:this.displayFranquia}}>
                <label htmlFor="franquiaFilter" style={{ display: "inline-block", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'franquia').text}</label>
                <DropDownListComponent id="franquiaFilter"
                                key="franquiaFilter"
                                locale={this.props.idiomaData.langSelected}
                                dataSource={this.dataFranquiaFilter}
                                ref={(combobox) => { this.franquiaFilterObj = combobox; }}
                                fields={{value: "franquia_id", text: "nome_franquia"}}
                                placeholder="Franquia"
                                filterBarPlaceholder={`${IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'buscar').text} ${IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'franquia').text}`}
                                enabled={false}
                                ignoreAccent= {true}
                                allowFiltering={true}
                                filterType="Contains"
                                sortOrder='Ascending'
                                showPopupButton={true}
                                //noRecordsTemplate={this.noRecordsTemplateFilter}
                                popupHeight={this.popupHeight}
                                change={this.franquiaFilterChange}
                                select={this.franquiaFilterSelect}
                                focus={this.focusCb}/>
                <label className="e-error" htmlFor="franquiaFilter"  ref={l=>this.franquiaFilterLabelErrorObj=l} style={{ display: "none", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'informacao_requerida').text}</label>
            </div>
            {this.buildRenderFilterFranquiaBase()}
        </div>
        );
    };

    buildRenderFilterFranquiaBase = () => {
        return (<div></div>);
    }
}

SidebarRightFilterFranquia.contextType = HeaderContext;
