import React from 'react';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import _ from 'lodash';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import IdiomaData from '../../model/IdiomaData';
import SidebarRightFilterCliente from './SidebarRightFilterCliente';
import ParamData, { DIAGNOSTICO } from '../../model/ParamData';
import { USUARIO } from '../../api/Modulo';
import {HeaderContext} from "../../HeaderContext";

export default class SidebarRightFilterSafra extends SidebarRightFilterCliente {

    constructor(props) {
        super(props);
        this.moduloSafra = [DIAGNOSTICO.COMPLETO, DIAGNOSTICO.EXPRESSO, DIAGNOSTICO.EXPRESSO_REPRODUCAO];

        if (isNullOrUndefined(this.paramData)) {
            this.paramData = new ParamData();
        }

        this.dataSafra = _.map(this.props.userData.info_fazenda.safra, function (element) {
            return _.extend({}, element, { safra: element.primeiro_ano + '/' + element.ultimo_ano, safra_id: element.id});
        });
    }

    getUid = (safraid, diagnostico = null) => {
        if(!isNullOrUndefined(diagnostico) && this.safraFilterObj.dataSource){
            const sf = this.safraFilterObj.dataSource.find(x => x.safra_id === safraid && x.diagnostico === diagnostico);
            if(sf){
                return sf.uid;
            }
        }

        return null
    }

    iniciarFilter = (franquiaid_, clienteid_, safraid_ = null) => {
        if (this.franquiaFilterObj.dataSource) {
            const obj = this.franquiaFilterObj.dataSource.find(x => x.franquia_id === this.paramData.franquiaid);
            if (obj) {
                this.franquiaFilterObj.value = this.paramData.franquiaid;
            }
        }

        setTimeout(() => {
            if (this.franquiaFilterObj && isNullOrUndefined(this.franquiaFilterObj.index)) {
                this.franquiaFilterObj.value = franquiaid_;
            }
            this.franquiaFilterChange();

            if (this.clienteFilterObj.dataSource) {
                const obj = this.clienteFilterObj.dataSource.find(x => x.cli_codigo === this.paramData.clienteid);
                if (obj) {
                    this.clienteFilterObj.value = this.paramData.clienteid;
                } else {
                    const obj2 = this.clienteFilterObj.dataSource.find(x => x.cli_codigo === clienteid_);
                    if (obj2) {
                        this.clienteFilterObj.value = clienteid_;
                    }
                }
            }

            setTimeout(() => {
                if (isNullOrUndefined(this.clienteFilterObj.index)) {
                    this.clienteFilterObj.value = clienteid_;
                }
                this.clienteFilterChange();

                setTimeout(() => {
                    if (!isNullOrUndefined(this.paramData.diagnostico)) {
                        if (this.safraFilterObj.dataSource) {
                            const obj = this.safraFilterObj.dataSource.find(x => x.safra_id === this.paramData.safraid);
                            if (obj) {
                                const uid = this.getUid(this.paramData.safraid, this.paramData.diagnostico)
                                this.safraFilterObj.value = uid;
                            } else {
                                const obj2 = this.safraFilterObj.dataSource.find(x => x.safra_id === safraid_);
                                if (obj2) {
                                    const uid = this.getUid(safraid_)
                                    this.safraFilterObj.value = uid;
                                }
                            }
                        }

                        if (!isNullOrUndefined(this.safraFilterObj.index)) {
                            // this.safraFilterChange();
                        }
                    } else {
                        this.safraFilterObj.value = null;
                    }

                    this.checkPermissionAccessFilter();
                    this.hasChangeParameter = true;
                }, 150);
            }, 150);
        }, 150);
    }

    componentDidMount() {
        const tempParaData = ParamData.recoverParamDataFromStorage();

        if (!isNullOrUndefined(tempParaData)) {
            this.paramData = Object.assign(this.paramData, tempParaData);

            if (isNullOrUndefined(this.paramData.fazendaid_list) || this.paramData.fazendaid_list.length === 0) {
                this.paramData.setFazendaIdList([this.paramData.fazendaid]);
            }

            const selectedSafra = this.dataSafra.find(x => x.id === this.paramData.safraid)
            const selectedElem = this.props.userData.info_fazenda.dados.find(x => x.cli_codigo === this.paramData.clienteid &&
                x.franquia_id === this.paramData.franquiaid &&
                x.safra === selectedSafra.safra);
            if (selectedElem) {
                this.iniciarFilter(selectedElem.franquia_id, selectedElem.cli_codigo);
            } else {
                const selectedElem_ = _.first(this.props.userData.info_fazenda.dados);
                if (selectedElem_) {
                    this.iniciarFilter(selectedElem_.franquia_id, selectedElem_.cli_codigo);
                }
            }
        } else {
            const selectedElem = _.first(this.props.userData.info_fazenda.dados);
            this.iniciarFilter(selectedElem.franquia_id, selectedElem.cli_codigo);
        }
    }

    checkPermissionAccessFilter = ((_super) => {
        return () => {
            _super();
            if (this.props.userData.user_info.user.tipo === USUARIO.CLIENTE) {
                this.safraFilterObj.enabled = true;
            }
        };
    })(this.checkPermissionAccessFilter);

    franquiaFilterChange = ((_super) => {
        return () => {
            _super();
            this.resetComboBox(this.safraFilterObj);
        };
    })(this.franquiaFilterChange);

    clienteFilterChange = ((_super) => {
        return () => {
            _super();

            if (this.clienteFilterCheckSelected()) {
                let clientesSelected = this.props.userData.info_fazenda.dados.filter(v => v.cli_codigo === this.clienteFilterObj.value && this.moduloSafra.includes(v.diagnostico));

                let cc=0;
                const dataSafraFilter = _.uniqBy(_.map(clientesSelected, (element) => {
                    const sf = this.dataSafra.find(x => x.safra === element.safra);
                    return _.extend({}, element, sf, {uid: cc++});
                }), 'uid');

                this.safraFilterObj.dataSource = dataSafraFilter;
                this.safraFilterObj.enabled = true;
                this.safraFilterObj.dataBind();
            }
        };

    })(this.clienteFilterChange);

    itemTemplateSafraFilter(data) {
        return <div>{data.primeiro_ano}/{data.ultimo_ano}</div>;
    }

    safraFilterCheckSelected = () => {
        if (!isNullOrUndefined(this.safraFilterObj.value) && !isNullOrUndefined(this.safraFilterObj.index)) {
            this.safraFilterLabelErrorObj.style.display = "none";
            return true;
        } else {
            this.safraFilterLabelErrorObj.style.display = "inline-block";
            return false;
        }
    }

    safraFilterChange = () => {
        this.hasChangeParameter = true;
        if (this.safraFilterCheckSelected()) {
            this.paramData.setSafraId(this.safraFilterObj.itemData.safra_id);

            const obj_ = this.dataSafra.find(x => x.id === this.paramData.safraid)
            this.paramData.setAnoSafra([obj_.primeiro_ano, obj_.ultimo_ano]);
            this.paramData.setDiagnostico(this.safraFilterObj.itemData.diagnostico);
            this.paramData.setAcessoCadastroFornecedor(this.safraFilterObj.itemData.acesso_cadastro_fornecedor);
            this.paramData.setAcessoBemEstarAnimal(this.safraFilterObj.itemData.acesso_bem_estar_animal);
        } else {
            this.paramData.setSafraId(null);
            this.paramData.setAnoSafra([null, null]);
            this.paramData.setDiagnostico(null);
            this.paramData.setAcessoCadastroFornecedor(false);
            this.paramData.setAcessoBemEstarAnimal(false);
        }
    }

    clickAplicar = ((_super) => {
        return () => {
            _super();
            if (this.safraFilterCheckSelected()) {
                const obj_ = this.dataSafra.find(x => x.id === this.paramData.safraid)
                this.paramData.setAnoSafra([obj_.primeiro_ano, obj_.ultimo_ano]);

                if (this.hasChangeParameter && this.hasUserSelectedParameter) {
                    ParamData.saveParamDataFromStorage(this.paramData);
                }
            }
        };
    })(this.clickAplicar);

    safraFilterSelect = (args) => {
        if (args && args.itemData) {
            this.paramData.setDiagnostico(args.itemData.diagnostico);
            this.paramData.setAcessoCadastroFornecedor(args.itemData.acesso_cadastro_fornecedor);
            this.paramData.setAcessoBemEstarAnimal(args.itemData.acesso_bem_estar_animal);
        }

        this.hasUserSelectedParameter = true;
    }

    buildRenderFilterClienteBase = () => {
        return (
            <div>
                <div className="filter-container-element">
                    <label htmlFor="safraFilter" style={{ display: "inline-block", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'safra').text}</label>
                    <DropDownListComponent id="safraFilter"
                        key="safraFilter"
                        locale={this.props.idiomaData.langSelected}
                        allowCustom={false}
                        ref={(combobox) => { this.safraFilterObj = combobox; }}
                        fields={{ value: "uid", text: "safra", groupBy: 'diagnostico_name' }}
                        /* eslint-disable no-template-curly-in-string */
                        itemTemplate="<div>${safra} ${diagnostico_name}</div>"
                        enabled={false}
                        placeholder={IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'safra').text}
                        filterBarPlaceholder={`${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'buscar').text} ${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'safra').text}`}
                        sortOrder='Ascending'
                        ignoreAccent={true}
                        allowFiltering={true}
                        filterType="Contains"
                        showPopupButton={true}
                        //noRecordsTemplate={this.noRecordsTemplateFilter}
                        popupHeight={this.popupHeight}
                        change={this.safraFilterChange}
                        select={this.safraFilterSelect}
                        focus={this.focusCb} />
                    <label className="e-error" htmlFor="safraFilter" ref={l => this.safraFilterLabelErrorObj = l} style={{ display: "none", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'informacao_requerida').text}</label>
                </div>
                {this.buildRenderFilterSafraBase()}
            </div>
        );
    };

    buildRenderFilterSafraBase = () => {
        return (<></>);
    }
}

SidebarRightFilterSafra.contextType = HeaderContext;
