import * as TYPE from './types';

  export const signIn = userData => {
    return {
      type: TYPE.SIGN_IN,
      payload: userData
    };
  };

  export const signOut = () => {
    return {
      type: TYPE.SIGN_OUT
    };
  };

  export const updateFazendaCaracterizada = fazendaCaracterizada => {
    return {
      type: TYPE.UPDATE_FAZENDA_CARACTERIZADA,
      payload: fazendaCaracterizada
    };
  };

  export const changeFilter = paramData => {
    return {
      type: TYPE.CHANGE_FILTER,
      payload: paramData
    };
  };

  export const changeIdioma = idiomaData => {
    return {
      type: TYPE.CHANGE_IDIOMA,
      payload: idiomaData
    };
  };

  export const ativarLoading = loadingData => {
    return {
      type: TYPE.LOADING,
      payload: loadingData
    };
  };

  export const notificar = notificaData => {
    return {
      type: TYPE.NOTIFICAR,
      payload: notificaData
    };
  };

  export const updateListReferencia = listReferenciaData => {
    return {
      type: TYPE.UPDATE_LIST_REFERENCIA,
      payload: listReferenciaData
    };
  };
