export const NOTIFICACAO_MODULOS = {
    0: "sistema",
    1: "fazenda",
    2: "equipe",
    3: "financeiro",
    4: "pecuaria",
    5: "maquinas",
    6: "agricola",
    7: "estoque",
    8: "clima",
    9: "bemestar",
    10: "painel_consultas"
}

export const NOTIFICACAO_OPERACOES = {
    '000000': "sistema_paincons",
    '000001': "sistema_lancamentos",
    '001000': "fazenda_fazenda",
    '002000': "equipe_equipe",
    '003000': "financeiro_receitas",
    '003001': "financeiro_despesas",
    '003002': "financeiro_outrdeb",
    '003003': "financeiro_outrcred",
    '004000': "pecuaria_nascimentos",
    '004001': "pecuaria_desmames",
    '004002': "pecuaria_mortes",
    '004003': "pecuaria_confinamento",
    '004004': "pecuaria_tipsemconf",
    '004005': "pecuaria_compoutrent",
    '004006': "pecuaria_vendoutrsai",
    '004007': "pecuaria_estpecuario",
    '004008': "pecuaria_area",
    '004009': "pecuaria_montatoque",
    '004010': "pecuaria_nascesm",
    '005000': "maquinas_maquinas",
    '005001': "maquinas_implemento",
    '005002': "maquinas_parqmaq",
    '006000': "agricola_contdesp",
    '006001': "agricola_contprod",
    '006002': "agricola_perffin",
    '006003': "agricola_perffincc",
    '006004': "agricola_funcagric",
    '006005': "agricola_relmaqcult",
    '007000': "estoque_inspagos",
    '007001': "estoque_siloarmaz",
    '008000': "clima_clima",
    '009000': "bemestar_bemestar",
    '010000': "painelconsultas_paincons"
}

export const NOTIFICACAO_URLS = {
    '000000': "/dashboard",
    '000001': "/lancamento",
    '001000': "/lancamento/fazenda",
    '002000': "/lancamento/equipe/equipe",
    '003000': "/lancamento/financeiro/receita",
    '003001': "/lancamento/financeiro/despesa",
    '003002': "/lancamento/financeiro/outro/debito",
    '003003': "/lancamento/financeiro/outro/credito",
    '004000': "/lancamento/pecuaria/nascimento",
    '004001': "/lancamento/pecuaria/desmame",
    '004002': "/lancamento/pecuaria/morte",
    '004003': "/lancamento/pecuaria/confinamento",
    '004004': "/lancamento/pecuaria/tipsemiconfinamento",
    '004005': "/lancamento/pecuaria/movimento/entrada",
    '004006': "/lancamento/pecuaria/movimento/saida",
    '004007': "/lancamento/pecuaria/estoque",
    '004008': "/lancamento/pecuaria/area",
    '004009': "/lancamento/pecuaria/reproducao/monta/toque",
    '004010': "/lancamento/pecuaria/reproducao/nascimento/desmame",
    '005000': "/lancamento/maquina/maquinas",
    '005001': "/lancamento/maquina/implemento",
    '005002': "/lancamento/maquina/parque/maquina",
    '006000': "/lancamento/agricola/controle/despesa",
    '006001': "/lancamento/agricola/controle/producao",
    '006002': "/lancamento/agricola/perfil/financeiro",
    '006003': "/lancamento/agricola/perfil/financeiro/centro/custo",
    '006004': "/lancamento/agricola/funcionario",
    '006005': "/lancamento/agricola/relacao/maquina",
    '007000': "/lancamento/estoque/insumo/pecuario",
    '007001': "/lancamento/estoque/insumo/agricola",
    '008000': "/lancamento/clima",
    '009000': "/dashboard"
}

export const NOTIFICACAO_TIPOS = {
    0: 'validar',
    1: 'revalidar',
    2: 'divergente',
    3: 'lancamentos',
    4: 'indicadores',
    5: 'alerta',
    6: 'parecer'
}

export const NOTIFICACAO_SITUACOES = {
    0: 'pendente',
    1: 'visto',
    2: 'resolvido'
}

export const NOTIFICACAO_MODAL_ACOES = {
    0: 'click_resolvido',
    1: 'marcar_todos_visto',
    2: 'resolvido',
    3: 'click_divergente'
}

export const NOTIFICACO_MENSAGENS = {
    0: 'Dia 05 atualize seus dados.',
    1: 'Os indicadores do primeiro trimestre estão disponíveis.',
    2: 'Os indicadores do primeiro semestre da safra estão disponíveis.',
    3: 'Os indicadores do terceiro trimestre estão disponíveis.',
    4: 'Os indicadores do fechamento de safra estão disponíveis, em breve seu consultor irá auditar os dados e será enviada a Reportagem completa.',
    5: 'Módulo disponível para validação.',
    6: 'Módulo disponível para revalidação.',
    7: 'Lançamento realizado pelo técnico.',
    8: 'Lançamento validado pelo técnico.',
    9: 'Lançamento reprovado pelo técnico.',
    10: 'Parecer técnico respondido pela fazenda.',
    11: 'Lançamento excluido'
}
