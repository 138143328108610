//import React  from 'react';
import { connect } from 'react-redux';
import { changeFilter } from '../../actions';
import SidebarRightFilterSafra from '../filter/SidebarRightFilterSafra';

class SidebarRightFilterCaracterizaFazenda extends SidebarRightFilterSafra {

    componentDidMount(){
        super.componentDidMount();

        setTimeout( ()=>{
            this.hasChangeParameter=true;
            this.clickAplicar()
        }, 500);
    }

    clickAplicar = ((_super) =>{
        return () => {
            _super();
            if(this.safraFilterCheckSelected()){
                //this.paramData.setFazendaId(null);
                if(this.hasChangeParameter)
                    this.props.changeFilter(this.paramData);
                this.hasChangeParameter=false;
                this.sidebarobj.hide();
            }
        };         
    
    })(this.clickAplicar);
}

const mapStateToProps = state => {
    return { userData: state.auth.userData, idiomaData: state.idiomaData };
};

export default connect(mapStateToProps, { changeFilter })(SidebarRightFilterCaracterizaFazenda);