import React from 'react';
import { isNullOrUndefined, getValue } from '@syncfusion/ej2-base';
import ReactDOM from "react-dom/client";
import Twemoji from 'react-twemoji';
import _ from 'lodash';
import IdiomaData from '../model/IdiomaData';
import ParamData from '../model/ParamData';
import NotificaData from '../model/NotificaData';

class Util {

    static pageSizesList = [5, 12, 20, 30, 40, 50, 100, 300, 500, 1000, 2000];

    static orderDateSafra(mesInicio, mesFinal, anoInicio, anoFinal) {
        let arrayTemp = [];
        let mes = mesInicio, ano = anoInicio;
        for (let i = 1; i <= 12; i++) {
            if (mes > 12) {
                mes = 1;
                ano = anoFinal;
            }
            arrayTemp[i] = { ano: ano, mes: mes };
            mes = mes + 1;
        }
        return arrayTemp;
    }

    static createModelButtonForDialogSimNao(functionClickSim, functionClickNao, labelSimNao) {
        return [
            {
                click: functionClickSim,
                buttonModel: { content: labelSimNao[0], isPrimary: true, iconPosition: "Left", iconCss: 'e-icons e-certo_confirma', cssClass: "style-button-sim-dialog" }
            },
            {
                click: functionClickNao,
                buttonModel: { content: labelSimNao[1], isPrimary: true, iconPosition: "Left", iconCss: 'e-icons e-incorreto_cancela', cssClass: "style-button-nao-dialog" }
            }
        ];
    }

    static createModelButtonForDialogOk(functionClickOk, labelOk) {
        return [
            {
                click: functionClickOk,
                buttonModel: { content: labelOk, isPrimary: true, iconPosition: "Left", iconCss: 'e-icons e-certo_confirma', cssClass: "style-button-sim-dialog" },

            },
        ];
    }

    static getPageSizeLocal() {
        var pageSize = localStorage.getItem('page_size');
        if (isNullOrUndefined(pageSize)) {
            return 5;
        } else {
            return pageSize;
        }
    }

    static setPageSizeEventOnDatabound(grid) {
        if(!isNullOrUndefined(grid.pagerModule) && !isNullOrUndefined(grid.pagerModule.pagerObj)){
            grid.pagerModule.pagerObj.containerModule.pagerModule.dropDownChanged = function (args) {
                localStorage.setItem('page_size', args.pageSize);
            }
        }
    }

    static updateErrorMessage(fieldName, hide=true) {
        const f = document.getElementById(fieldName + '_Error');
        if (!isNullOrUndefined(f)) {
            if(hide){
                f.style.display = 'none';
            }else{
                f.style.display = 'block';
            }
        }
    }

    static displayErrorMessage(fieldName) {
        const f = document.getElementById(fieldName + '_Error');
        if (!isNullOrUndefined(f)) {
            f.style.display = null;
        }
    }

    static checkValorMaiorZero = (args) => {
        const value = getValue('value', args);
        if (isNullOrUndefined(value) || value === "") {
            return true;
        }
        return value > 0;
    }

    static checkValorMaiorDefinido = (limit_inferior) => (args) => {
        const value = getValue('value', args);
        if (isNullOrUndefined(value) || value === "") {
            return true;
        }

        return value >= limit_inferior;
    }

    static itemTemplateCb = (fieldName, width = "180px") => {
        return `<table style="table-layout:fixed; line-height: 92%; text-align: left; vertical-align: middle; display: inline-block; width: ${width}">` +
            '<tbody>' +
            '<tr>' +
            '<td style="text-transform: uppercase;white-space: pre-line;">${' + fieldName + '}</td>' +
            '</tr>' +
            '</tbody>' +
            '</table>';
    }

    static getComponentFlag(frag) {
        switch (frag) {
            case 1:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-brazil">🇧🇷</span></Twemoji>;

            case 2:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-paraguay">🇵🇾</span></Twemoji>;

            case 3:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-united-states">🇺🇸</span></Twemoji>;

            case 4:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-bolivia">🇧🇴</span></Twemoji>;

            case 5:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-colombia">🇨🇴</span></Twemoji>;

            case 6:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-spain">🇪🇸</span></Twemoji>;

            default:
                return <Twemoji options={{ className: "twemoji" }}><span className="flag-geral" role="img" aria-label="flag-brazil"></span></Twemoji>;
        }
    }

    static getComponentFlagSpan(frag) {
        switch (frag) {
            case 1:
                return (<span className="flag-geral" role="img" aria-label="flag-brazil">🇧🇷</span>);

            case 2:
                return (<span className="flag-geral" role="img" aria-label="flag-paraguay">🇵🇾</span>);

            case 3:
                return (<span className="flag-geral" role="img" aria-label="flag-united-states">🇺🇸</span>);

            case 4:
                return (<span className="flag-geral" role="img" aria-label="flag-bolivia">🇧🇴</span>);

            case 5:
                return (<span className="flag-geral" role="img" aria-label="flag-colombia">🇨🇴</span>);

            case 6:
                return (<span className="flag-geral" role="img" aria-label="flag-spain">🇪🇸</span>);

            default:
                return (<span className="flag-geral" role="img" aria-label="flag-brazil"></span>);
        }
    }

    static getArrayHtmlFlag(frag) {

        let tmp = document.createElement('div');
        const root = ReactDOM.createRoot(tmp);
        root.render(Util.getComponentFlag(frag));

        return tmp.innerHTML;
    }

    static numberFormat(number, decimals, dec_point, thousands_sep) {

        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return Math.round(n * k) / k;
            },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }

    static countDecimalPlacecountDecimalPlace(value) {
        if (Math.floor(value) === value || !value.toString().includes(".")) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    static changeColumnName = (column, columnNewName) => {
        if (!isNullOrUndefined(column)) {
            const div = document.querySelector(`div[e-mappinguid="grid-column${column.index}"]`);
            if (div)
                div.innerText = columnNewName;
        }
    }

    static getOffsetTimezone = () => {
        var offset = new Date().getTimezoneOffset() * 120;
        return offset;
    };

    static convertUnixTimeToDate = (unixTime) => {
        var offset = Util.getOffsetTimezone();
        return new Date((unixTime + offset) * 1000);
    };

    static convertUnixTime = (date) => {
        let dateUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMilliseconds()));
        return dateUtc.getTime() / 1000 | 0;
    };

    static copyObj(obj) {
        return Object.assign({}, obj);
    }

    static dateAdd(date, interval, units) {
        if (!(date instanceof Date))
            return undefined;
        var ret = new Date(date);
        var checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0); };
        switch (String(interval).toLowerCase()) {
            case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
            case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
            case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
            case 'week': ret.setDate(ret.getDate() + 7 * units); break;
            case 'day': ret.setDate(ret.getDate() + units); break;
            case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
            case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
            case 'second': ret.setTime(ret.getTime() + units * 1000); break;
            default: ret = undefined; break;
        }
        return ret;
    }

    static replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    static addGrupoIdadeEfetivoPecuario = (categoriaEfeitoPecuarioDados, grupoLabel) => {

        categoriaEfeitoPecuarioDados = _.map(categoriaEfeitoPecuarioDados, function (element) {
            let grupo_idade = null;
            let grupo_idade_label = '';
            let ordem = 0;
            if ([2].includes(element.item)) {
                grupo_idade = 0;
                grupo_idade_label = grupoLabel.find(x => x.item === 0).text;
            } else if ([3, 4, 5, 6, 7].includes(element.item)) {
                grupo_idade = 1;
                grupo_idade_label = grupoLabel.find(x => x.item === 1).text;
            } else if ([0, 1, 8, 9, 10, 11, 12].includes(element.item)) {
                grupo_idade = 2;
                grupo_idade_label = grupoLabel.find(x => x.item === 2).text;
            }

            switch (_.parseInt(element.item)) {
                case 0:
                    ordem = 6;
                    break;
                case 1:
                    ordem = 7;
                    break;
                case 2:
                    ordem = 0;
                    break;
                case 3:
                    ordem = 1;
                    break;
                case 4:
                    ordem = 2;
                    break;
                case 5:
                    ordem = 3;
                    break;
                case 6:
                    ordem = 4;
                    break;
                case 7:
                    ordem = 5;
                    break;
                case 8:
                    ordem = 8;
                    break;
                case 9:
                    ordem = 9;
                    break;
                case 10:
                    ordem = 10;
                    break;
                case 11:
                    ordem = 11;
                    break;
                case 12:
                    ordem = 12;
                    break;

                default:
                    ordem = -1;
                    break;
            }

            return _.extend({}, element, { grupo_idade: grupo_idade, grupo_idade_label: grupo_idade_label, ordem: ordem });
        });
        categoriaEfeitoPecuarioDados.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.order > a.order) ? -1 : 0));
        return categoriaEfeitoPecuarioDados;
    }

    static checkIsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

    static convertToJson(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return str;
        }
    }

    static updateValueAfterEnter(obj, elem) {
        /*elem.addEventListener('keydown', (e) => {
            if (e.which === 13) {
                e.preventDefault();
                obj.focusOut(e);
            }
        });*/
    }

    static updateValueAfterEnterByFieldName(grid, fieldName) {
        const columnIndex_ = grid.getColumnIndexByField(fieldName);
        grid.columnModel[columnIndex_].edit.obj.element.addEventListener("keyup", function (e) {
            //e.preventDefault();
            if (e.which === 13 || e.keyCode === 13) {
                grid.columnModel[columnIndex_].edit.obj.focusOut(e);
                grid.endEdit();
            }/*else if(e.which == 9 && !e.shiftKey) {
              if(grid.em_insert_mode){
                return;
              }
              grid.em_insert_mode=true;
              grid.columnModel[columnIndex_].edit.obj.focusOut(e);
              grid.endEdit();
            }*/
        });
    }

    static blockField(objInput) {
        objInput.setAttribute('readonly', true);
        objInput.setAttribute('disabled', 'disabled');

        objInput.classList.add("e-disabled");
        objInput.parentElement.classList.add("e-disabled");

        objInput.blur();
        objInput.parentElement.blur();
    }

    static buildListReferencia(props, labelModuloInfo, show = { showFazenda: true, showSafra: true, showModulo: true }) {

        let labelModulo = labelModuloInfo;
        let sufixoModulo = '';
        if (typeof labelModuloInfo === 'object') {
            labelModulo = labelModuloInfo.labelModulo;
            sufixoModulo = labelModuloInfo.sufixoModulo;
        }

        let showFazenda = true;
        let showSafra = true;
        let showModulo = true;
        if (typeof show === 'object') {
            showFazenda = show.showFazenda;
            showSafra = show.showSafra;
            showModulo = show.showModulo;
        } else {
            showFazenda = show;
        }

        let arr = [];
        if (showModulo) {
            arr = [...arr, `${IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'modulo').text}: ${IdiomaData.selectGroupItem(props.idiomaData, 'messages', labelModulo).text} ${sufixoModulo}`.trim()];
        }

        if (showSafra) {
            arr = [...arr, `${IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'safra').text}: ${ParamData.getSafraNome(props.paramData)}`.trim()];
        }

        if (showFazenda) {
            arr = [...arr, `${IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'fazenda').text}: ${props.paramData.nome_fazenda}`.trim()];
        }

        return { list: arr };
    }

    static showGridSpinner() {
        //document.querySelector('.e-spinner-pane').classList.remove('e-spin-hide')
        //document.querySelector('.e-spinner-pane').classList.add('e-spin-show')
        const d = document.querySelector('.e-spinner-pane');
        if (d && d.style)
            d.style.display = "block";
    }

    static hideGridSpinner() {
        //document.querySelector('.e-spinner-pane').classList.remove('e-spin-show')
        //document.querySelector('.e-spinner-pane').classList.add('e-spin-hide')
        const d = document.querySelector('.e-spinner-pane');
        if (d && d.style)
            d.style.display = null;
    }

    static buildKeyGrid(grid) {
        return function keyDownHandler(e) {

            if (e.repeat) {
                return;
            }

            if (grid) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    grid.endEdit();
                } else if (e.keyCode === 27 || e.key === "Escape") {
                    e.preventDefault();
                    grid.closeEdit();
                }
            }
        };
    }

    static buildActionFailureNotifica(args, props, callbackClickSim, callbackClickNao, callClickOk) {
        let buttomModel = [];
        buttomModel[0] = Util.createModelButtonForDialogSimNao(callbackClickSim, callbackClickNao, [IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'sim').text, IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'nao').text]);
        buttomModel[1] = Util.createModelButtonForDialogOk(callClickOk, IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'ok').text);
        props.notificar(NotificaData.createDialogNotifica(args, buttomModel, props.paramData));
    }

    static getHeightWidth(offsetHeight = 0, offsetWidth = 0) {
        let myWidth = 0, myHeight = 0;

        if (typeof (window.innerWidth) == 'number') {
            myWidth = window.innerWidth;
            myHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            myWidth = document.documentElement.clientWidth;
            myHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            myWidth = document.body.clientWidth;
            myHeight = document.body.clientHeight;
        }

        return { height: myHeight - offsetHeight, width: myWidth - offsetWidth };
    }

    static getDateFromTimestampUTC(timestamp) {
        if(timestamp){
            const dateTimeArr = timestamp.split(' ');
            const dateArr = dateTimeArr[0].split('-');
            const timeArr = dateTimeArr[1].split(':');
            const date = new Date();

            date.setUTCFullYear(parseInt(dateArr[0]), parseInt(dateArr[1])-1, parseInt(dateArr[2]));
            date.setUTCHours(parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]), 0);

            return date;
        }

        return null;
    }

    static removeEspecialCharAndWhiteSpace(text) {
        Util._text = text.replace(/[^a-zA-Z0-9]/g, '');
        return Util._text;
    }

    static checkPathUrl (path) {
        const pathUrl = window.location.pathname.split('/');

        for (let index = 0; index < path.length; index++) {
            if(path[index]!==pathUrl[index+1]){
                return false;
            }
        }

        return true;
    }

    static randomStr ( prefix="", length=15, sufix="" ) {
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return prefix + str + sufix;
    }

    static resetComboBox (obj) {
        obj.text = null;
        obj.enabled = false;
        obj.dataBind();
    }

    static getDateDDMMYYYY (config={is_month:false, date:null}) {

        const today = !isNullOrUndefined(config.date) ? config.date : new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        if(config.is_month){
            return (mm + '/' + yyyy);
        }else{
            return (dd + '/' + mm + '/' + yyyy);
        }
    }

    static getLastDayOfMonth(date) {
        const lastDayOfMonth = new Date(date.getUTCFullYear(), date.getUTCMonth()+1, 0);
        return lastDayOfMonth.getUTCDate()
    }
}

export default Util;
