import * as TYPE from '../actions/types';
import LoadingData from '../model/LoadingData';

const INITIAL_STATE = new LoadingData();

export const showLoader = () => {
    const loader = document.querySelector('.loader_inicial');
    const container = document.querySelector('#container-spinner');
    if(loader){
      loader.style.display = "inline";
      container.style.display = "inline";
    }
}

export const hideLoader = () => {
    const loader = document.querySelector('.loader_inicial');
    const container = document.querySelector('#container-spinner');
    if(loader){
      loader.style.display = "none";
      container.style.display = "none";
    }
}

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE.LOADING:

      if(action.payload.loading){
        showLoader();
      }else{
        hideLoader();
      }

      return { ...state,  ...action.payload };
    default:
      return state;
  }
};

export default loadingReducer;