import React, { Fragment } from 'react';
import SidebarLeft from '../SidebarLeft';

const LayoutMenuLeft = (props) => {
    return (
        <Fragment>
            <SidebarLeft  />
            <div className="control-section">
                <div className="col-lg-12 col-sm-12 col-md-12" id="content-darshboard">
                    {props.children}
                </div>
            </div>
        </Fragment>
    );
}

export default LayoutMenuLeft;
