import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import IdiomaData from '../../model/IdiomaData';
import './NotaPreenchimento.scss';
import { useNavigate } from "react-router-dom";
import { ativarLoading } from '../../actions';
import ParamData from '../../model/ParamData';

import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, Filter, Group, Resize } from '@syncfusion/ej2-react-grids';

import {
    NOTIFICACAO_MODULOS,
    NOTIFICACAO_OPERACOES,
    NOTIFICACAO_SITUACOES,
    NOTIFICACAO_TIPOS,
    NOTIFICACAO_URLS
} from "../notificacao/NotificacaoInterfaces";
import {closest } from "@syncfusion/ej2-base";
import { connect } from 'react-redux';

export const URL_BASE = process.env.REACT_APP_URL_BASE_API;

const NotificacoesModal = (props) => {
    let grid;
    let [notificacoes_empty] = useState(false);
    const [notificacoesData, setNotificacoes] = useState([]);
    let [load, setLoad] = useState(false);
    const [pendentesIds, setPendentesIds] = useState([]);
    const navigate = useNavigate();
    const user_data_local = JSON.parse(localStorage.getItem('USER_DATA_SESSION_BKM'));
    let userTipo = user_data_local.user_info.user.tipo || props.userData.user_info.user.tipo;
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${props.userData.access_token}`,
    }

    let params = {
        user_tipo: userTipo,
        fazenda_id: props.paramData.fazendaid,
        cli_codigo: props.paramData.clienteid,
        safra_id: props.paramData.safraid
    }

    // SE O USUÁRIO FOR FAZENDA FILTRO ATIVO APENAS NA SITUAÇÃO | DEIXAR === 'S'
    let allowFiltering = userTipo === 'S' ? false : true;
    let filterOptions = userTipo === 'S' ?
        { type: 'Excel'} :
        { type: 'Excel', columns:[{field: 'situacao', matchCase: false, operator: 'notequal', value: 'Visto' }]}

    async function getNotificacoes() {
        setLoad(true);
        try {
            const {data} = await axios.get(`${URL_BASE}/notificacoes/modal/get`, {params, headers});
            data.map((item) => {
                item.modulo = IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', NOTIFICACAO_MODULOS[item.modulo_id]).text;
                item.operacao = IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes',  NOTIFICACAO_OPERACOES[item.operacao_id.toString().padStart(6, '0')]).text;
                item.tipo = IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', NOTIFICACAO_TIPOS[item.tipo_id]).text;
                item.situacao = IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', NOTIFICACAO_SITUACOES[item.situacao_id]).text;
                return item;
            })

            setNotificacoes(data);
            const pendentes = data.filter(value => {
                return value.situacao_id === 0;
            });
            setPendentesIds(pendentes.map((pendente) => pendente.id));

        } catch (error) {
            console.log(error);
        }
        setLoad(false);
    }

    useEffect(() => {
        if(props.showNotificacoesModal){
            getNotificacoes();
        }

     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [props.showNotificacoesModal, props.paramData.fazendaid, props.paramData.safraid, props.userData.access_token]);



    const pageSettings = { pageSize: 15 };

    const settings = { persistSelection: true };

    const markAllAsSeen = async () => {
        setLoad(true);
        const payload = {listDataIds: pendentesIds, action: 1};
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${props.userData.access_token}`,
        }
        try {
            await axios.post(`${URL_BASE}/notificacoes/modal/update`, payload, {headers});
            await getNotificacoes();
        } catch (error) {
            console.log(error);
        }
        setLoad(false);
    }

    const recordClick = async (args) => {
        
        let rowObj = grid.getRowObjectFromUID(closest(args.target, '.e-row').getAttribute('data-uid'));
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${props.userData.access_token}`,
        }
        //CLICK BTN INFO
        if (args.target.classList.contains('btn-info-notification') || args.target.classList.contains('material-icons')){
            // USUÁRIO INTEGRA TEM ESSAS AÇÕES
            if(userTipo === 'U'){
                // SITUAÇÃO == PENDENTE
                if(rowObj.data.situacao_id === 0){
                    if(props.paramData.fazendaid !== rowObj.data.fazenda_id){
                        console.log(rowObj.data);
                        // FAZENDA QUE ESTÁ LOGADO É DIFERENTE DO CLICK NO MODAL
                        // SETO FAZENDA/SAFRA NO LOCALSTORAGE E RELOAD NA PÁGINA, ASSIM A PÁGINA JÁ RECARREGA COM OS PARÂMETROS CORRETO
                        const object = ParamData.recoverParamDataFromStorage();
                        object.fazendaid = rowObj.data.fazenda_id
                        object.safraid = rowObj.data.safra_id
                        ParamData.saveParamDataFromStorage(object)
                        console.log('salvou fazenda/safra no paramData');
                        window.location.href = (process.env.REACT_APP_URL_BASE+NOTIFICACAO_URLS[rowObj.data.operacao_id.toString().padStart(6, '0')].substring(1)).toString();
                    }else{
                        // CHAMA O NAVIGATE PARA O LANÇAMENTO CORRESPONDENTE (URL OPERACAO_ID)
                        props.closeModal();
                        navigate(NOTIFICACAO_URLS[rowObj.data.operacao_id.toString().padStart(6, '0')]);
                    }
                }

                // TIPO === DIVERGENTE
                if(rowObj.data.tipo_id === 2){
                    const payload = {
                        idNotificacao: rowObj.data.id,
                        idSituacao: 1,
                        action: 3
                    };

                    try {
                        await axios.post(`${URL_BASE}/notificacoes/modal/update`, payload, {headers});
                        props.closeModal()
                        navigate(NOTIFICACAO_URLS[rowObj.data.operacao_id.toString().padStart(6, '0')]);
                    } catch (error) {
                        console.log(error);
                    }
                }

            }else{
                // === 'S'  USUÁRIO FAZENDA CLICK SALVA COMO VISTO E FAZ O NAVIGATE
                const payload = {
                    idNotificacao: rowObj.data.id,
                    idSituacao: 1,
                    action: 3
                };

                try {
                    await axios.post(`${URL_BASE}/notificacoes/modal/update`, payload, {headers});
                    props.closeModal()
                    navigate(NOTIFICACAO_URLS[rowObj.data.operacao_id.toString().padStart(6, '0')]);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        // CLICK CHECKBOX RESOLVIDO
        if (args.target.classList.contains('checkbox-resolvido')) {
            const payload = {
                idNotificacao: rowObj.data.id,
                idSituacao: rowObj.data.situacao_id === 1 ? 2 : 0,
                action: 0
            };

            try {
                await axios.post(`${URL_BASE}/notificacoes/modal/update`, payload, {headers});
                await getNotificacoes();
            } catch (error) {
                console.log(error);
            }

        }

    };

    return (
        <Modal show={props.showNotificacoesModal} size="xl" onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', 'notificacoes').text}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {load &&
                    <div className="d-flex align-itens-center w-100 justify-content-center">
                        <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" /> <h2 className="ml-2">Carregando</h2>
                    </div>
                }
                {!load && !notificacoes_empty &&
                <GridComponent
                    dataSource={notificacoesData}
                    ref={g => grid = g}
                    allowPaging={true}
                    pageSettings={pageSettings}
                    allowResizing={true}
                    allowSorting={true}
                    sortSettings={{columns: [{ field: 'date', direction: 'Ascending' }] }}
                    allowFiltering={true}
                    filterSettings={filterOptions}
                    selectionSettings={settings}
                    recordClick={recordClick}
                >
                    <ColumnsDirective>
                        <ColumnDirective
                            headerText={'#'}
                            textAlign="Center"
                            width='40'
                            template='
                                <div>
                                    <button class="btn-info-notification">
                                        <span class="material-icons">info_outline</span>
                                    </button>
                                </div>
                            '
                        />
                        <ColumnDirective
                            field='fazenda_nome'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'fazenda').text}
                            type="number"
                            width='300'
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='safra'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'safra').text}
                            type="number"
                            width='100'
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='modulo'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'modulo').text}
                            type="number"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='date'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'data').text}
                            type="number"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='operacao'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'operacao').text}
                            type="number"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='tipo'
                            allowFiltering={allowFiltering}
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'tipo').text}
                            type="number"
                            textAlign="Left"
                        />
                        <ColumnDirective
                            field='situacao'
                            headerText={IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', 'situacao').text}
                            type="number"
                            textAlign="Left"
                        />

                        {userTipo !== 'S' &&
                            <ColumnDirective
                                isPrimaryKey='true'
                                field='visto'
                                props={props}
                                headerText={IdiomaData.selectGroupItem(props.idiomaData, 'notificacoes', 'resolvido').text}
                                type="boolean"
                                textAlign="Left"
                                defaultValue={0}
                                showColumnMenu={true}
                                allowFiltering={true}
                                allowSorting={false}
                                /* eslint-disable no-template-curly-in-string */
                                template='
                                    ${if(tipo_id == 2 || tipo_id == 6)}
                                        <input
                                            class="checkbox-resolvido"
                                            type="checkbox"
                                            name="default_${id}"
                                            value="${if(situacao_id == 2)}1${/if}"
                                            ${if(situacao_id == 2)} checked ${/if}
                                        />
                                    ${/if}
                                '
                            />
                        }


                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group, Resize]}/>
                </GridComponent>
                }

                {notificacoes_empty && !load &&
                    <h4 className="text-center py-5">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'ultimos_lancamentos_sem_registros').text}</h4>
                }
            </Modal.Body>
            <Modal.Footer>
                {/* BTN SÓ PRA USUÁRIO FAZENDA */}
                {userTipo === 'S' &&
                <Button variant="secondary" onClick={markAllAsSeen}>
                    Marcar todos como visto
                </Button>
                }
                <Button variant="secondary" onClick={props.closeModal}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default connect(ativarLoading) (NotificacoesModal);
