import React from 'react';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import _ from 'lodash';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import IdiomaData from '../../model/IdiomaData';
import SidebarRightFilterFranquia from './SidebarRightFilterFranquia';
import ParamData from '../../model/ParamData';
import { USUARIO } from '../../api/Modulo';
import { HeaderContext } from '../../HeaderContext';

export default class SidebarRightFilterCliente extends SidebarRightFilterFranquia {

    constructor(props) {
        super(props);

        if(isNullOrUndefined(this.paramData)){
            this.paramData=new ParamData();
        }
        
        this.dataClienteFilter =_.map( _.uniqBy(this.props.userData.info_fazenda.dados, 'cli_codigo'), _.partialRight(_.pick, ['cli_codigo', 'nome_cliente', 'franquia_id', 'nome_franquia']));

        if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA || 
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA ||
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA_GRUPO){
            this.dataClienteFilter = _.map(this.dataClienteFilter, x=>{x.nome_cliente=x.nome_cliente.trim().concat(' - ' + x.cli_codigo); return x; });
        }

        this.checkPermissionAccessFilter();
    }

    checkPermissionAccessFilter = ((_super) =>{
        return () => {
            _super();
            if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA){
                this.displayCliente='inline';
            }else if(this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA || this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA_GRUPO){
                this.displayCliente='inline';

                if(this.clienteFilterObj){
                    this.clienteFilterObj.enabled=true;
                }

            }else{
                this.displayCliente='none';

                if(this.clienteFilterObj){
                    this.clienteFilterObj.enabled=false;
                }
            }
         };
    })(this.checkPermissionAccessFilter);

    franquiaFilterChange = ((_super) =>{
        return () => {
            _super();
            this.resetComboBox(this.clienteFilterObj);
            if(this.franquiaFilterCheckSelected()){
                this.clienteFilterObj.dataSource=this.dataClienteFilter.filter(x=>x.franquia_id===this.franquiaFilterObj.value);
                this.clienteFilterObj.enabled = true;
                this.clienteFilterObj.dataBind();
            }
        };
    })(this.franquiaFilterChange);

    clienteFilterCheckSelected=()=>{
        if(!isNullOrUndefined(this.clienteFilterObj.value) && !isNullOrUndefined(this.clienteFilterObj.index)){
            this.clienteFilterLabelErrorObj.style.display="none";
            return true;
        }else{
            this.clienteFilterLabelErrorObj.style.display="inline-block";
            return false;
        }
    }

    clienteFilterChange = () => {
        this.hasChangeParameter=true;
        this.resetComboBox(this.safraFilterObj);
        this.paramData.setClienteId(this.clienteFilterObj.value);
    }

    clickAplicar = ((_super) =>{
        return () => {
            _super();
            this.clienteFilterCheckSelected();
        };
    })(this.clickAplicar);


    itemTemplateClienteBuild = ()=>{
        if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA || 
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA ||
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA_GRUPO){
            /* eslint-disable no-template-curly-in-string */
            return  '<span style="vertical-align: middle, line-height:30px, margin-left:10px" >${nome_cliente} - ${cli_codigo}</span>';
        }

        /* eslint-disable no-template-curly-in-string */
        return  '<span style="vertical-align: middle, line-height:30px, margin-left:10px" >${nome_cliente}</span>';
    }

    valueTemplateCliente = (args)=>{
        if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA || 
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA ||
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA_GRUPO){
            return  <span style={{verticalAlign: "middle", lineHeight:"30px", marginLeft:"10px"}} >{args.nome_cliente} - {args.cli_codigo}</span>;
        }

        return  <span style={{verticalAlign: "middle", lineHeight:"30px", marginLeft:"10px"}} >{args.nome_cliente}</span>;
    }

    headerTemplateCliente = (args)=>{
        if(this.props.userData.user_info.user.tipo===USUARIO.INTTEGRA || 
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA ||
            this.props.userData.user_info.user.tipo===USUARIO.FRANQUIA_GRUPO){
            return  <span style={{verticalAlign: "middle", lineHeight:"30px", marginLeft:"10px", fontWeight:"bold"}} >{IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'cliente').text} - Código</span>;
        }
        return <span></span>;
    }

    clienteFilterSelect = (args) =>{
        this.hasUserSelectedParameter=true;
    }

    buildRenderFilterFranquiaBase = () => {
        return (
        <div>
            <div className="filter-container-element" style={{display:this.displayCliente}}>
                <label htmlFor="clienteFilter" style={{ display: "inline-block", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'cliente').text}</label>
                <DropDownListComponent id="clienteFilter"
                                key="clienteFilter"
                                locale={this.props.idiomaData.langSelected}
                                allowCustom={false} 
                                ref={(combobox) => { this.clienteFilterObj = combobox; }}  
                                fields={{value: "cli_codigo", text: "nome_cliente", id:"franquia_id" }} 
                                enabled={false} 
                                //itemTemplate={this.itemTemplateClienteBuild()}
                                //valueTemplate={this.valueTemplateCliente}
                                //headerTemplate={this.headerTemplateCliente}
                                filterBarPlaceholder={`${IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'buscar').text} ${IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'cliente').text}`}
                                placeholder="Cliente"
                                sortOrder='Ascending'
                                ignoreAccent= {true}
                                allowFiltering={true}
                                filterType="Contains"
                                showPopupButton={true}
                                //noRecordsTemplate={this.noRecordsTemplateFilter}
                                popupHeight={this.popupHeight}
                                change={this.clienteFilterChange}
                                select={this.clienteFilterSelect}
                                focus={this.focusCb}/>
                <label className="e-error" htmlFor="clienteFilter"  ref={l=>this.clienteFilterLabelErrorObj=l} style={{ display: "none", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'informacao_requerida').text}</label>
            </div>
            {this.buildRenderFilterClienteBase()}
        </div>
        );
    };

    buildRenderFilterClienteBase = () => {
        return (<></>);
    }

    iniciarFilterCliente = (franquiaid_, clienteid_ ) => {

        if(this.franquiaFilterObj.dataSource){
            const obj = this.franquiaFilterObj.dataSource.find(x=>x.franquia_id===this.paramData.franquiaid);
            if(obj){
                this.franquiaFilterObj.value=this.paramData.franquiaid;
            }
        }

        setTimeout(()=>{
            if(isNullOrUndefined(this.franquiaFilterObj.index)){
                this.franquiaFilterObj.value=franquiaid_;
            }
            this.franquiaFilterChange();

            if(this.clienteFilterObj.dataSource){
                const obj = this.clienteFilterObj.dataSource.find(x=>x.cli_codigo===this.paramData.clienteid);
                if(obj){
                    this.clienteFilterObj.value=this.paramData.clienteid;
                }else{
                    const obj2 = this.clienteFilterObj.dataSource.find(x=>x.cli_codigo===clienteid_);
                    if(obj2){
                        this.clienteFilterObj.value=clienteid_;
                    }
                }
            }

            setTimeout( ()=>{
                if(isNullOrUndefined(this.clienteFilterObj.index)){
                    this.clienteFilterObj.value=clienteid_;
                }
                this.clienteFilterChange();

                    setTimeout( ()=>{
                        this.checkPermissionAccessFilter();
                        this.hasChangeParameter=true;
                    }, 150);
            }, 150);
        }, 150);
    }


    startClientData = () => {
        const tempParaData=ParamData.recoverParamDataFromStorage();
        
        if(!isNullOrUndefined(tempParaData)){
            this.paramData=Object.assign(this.paramData, tempParaData );

            if(isNullOrUndefined(this.paramData.fazendaid_list) || this.paramData.fazendaid_list.length===0){
                this.paramData.setFazendaIdList([this.paramData.fazendaid]);
            }

            const selectedElem=this.props.userData.info_fazenda.dados.find(x=>x.cli_codigo===this.paramData.clienteid && 
                                                                            x.franquia_id===this.paramData.franquiaid );
            if(selectedElem){
                this.iniciarFilterCliente(selectedElem.franquia_id, selectedElem.cli_codigo);
            }else{
                const selectedElem_=_.first(this.props.userData.info_fazenda.dados);
                if(selectedElem_){
                    this.iniciarFilterCliente(selectedElem_.franquia_id, selectedElem_.cli_codigo);
                }
            }                                                                         
        }else{
            const selectedElem=_.first(this.props.userData.info_fazenda.dados);
            this.iniciarFilterCliente(selectedElem.franquia_id, selectedElem.cli_codigo);
        } 
    }
}

SidebarRightFilterCliente.contextType = HeaderContext;
