import { loadCldr, setCulture, setCurrencyCode, cldrData, isNullOrUndefined, Internationalization } from '@syncfusion/ej2-base';

import numberingSystem from './cldr/cldr-core-master/supplemental/numberingSystems.json';
//pt -br 
import timeZoneNamePt from './cldr/cldr-dates-full-master/main/pt/timeZoneNames.json';
import currencyPt from './cldr/cldr-numbers-full-master/main/pt/currencies.json';
import caGregorianPt from './cldr/cldr-dates-full-master/main/pt/ca-gregorian.json';
import numberPt from './cldr/cldr-numbers-full-master/main/pt/numbers.json';
// es PY
import caGregorianEsPy from './cldr/cldr-dates-full-master/main/es-PY/ca-gregorian.json';
import timeZoneNameEsPy from './cldr/cldr-dates-full-master/main/es-PY/timeZoneNames.json';
import currencyEsPy from './cldr/cldr-numbers-full-master/main/es-PY/currencies.json';
import numberEsPy from './cldr/cldr-numbers-full-master/main/es-PY/numbers.json';
//En
import caGregorianEn from './cldr/cldr-dates-full-master/main/en/ca-gregorian.json';
import timeZoneNameEn from './cldr/cldr-dates-full-master/main/en/timeZoneNames.json';
import currencyEn from './cldr/cldr-numbers-full-master/main/en/currencies.json';
import numberEn from './cldr/cldr-numbers-full-master/main/en/numbers.json';
//Es bo
import caGregorianEsBO from './cldr/cldr-dates-full-master/main/es-BO/ca-gregorian.json';
import timeZoneNameEsBO from './cldr/cldr-dates-full-master/main/es-BO/timeZoneNames.json';
import currencyEsBO from './cldr/cldr-numbers-full-master/main/es-BO/currencies.json';
import numberEsBO from './cldr/cldr-numbers-full-master/main/es-BO/numbers.json';
// Es Co
import caGregorianEsCo from './cldr/cldr-dates-full-master/main/es-CO/ca-gregorian.json';
import timeZoneNameEsCo from './cldr/cldr-dates-full-master/main/es-CO/timeZoneNames.json';
import currencyEsCo from './cldr/cldr-numbers-full-master/main/es-CO/currencies.json';
import numberEsCo from './cldr/cldr-numbers-full-master/main/es-CO/numbers.json';



const getDateDMYCulture = (codeLang) => {
    switch (codeLang) {
        case 1:
            return caGregorianPt.main.pt.dates.calendars.gregorian.dateFormats.short;
        case 2:
            return caGregorianEsPy.main["es-PY"].dates.calendars.gregorian.dateFormats.short;
        case 3:
            return caGregorianEn.main.en.dates.calendars.gregorian.dateFormats.short;
        case 4:
            return caGregorianEsBO.main["es-BO"].dates.calendars.gregorian.dateFormats.short;
        case 5:
            return caGregorianEsCo.main["es-CO"].dates.calendars.gregorian.dateFormats.short;
        default:
             return caGregorianPt.main.pt.dates.calendars.gregorian.dateFormats.short;
    }
}

const getTimeFormatMediumCulture = (codeLang) => {
    switch (codeLang) {
        case 1:
            return caGregorianPt.main.pt.dates.calendars.gregorian.timeFormats.medium;
        case 2:
            return caGregorianEsPy.main["es-PY"].dates.calendars.gregorian.timeFormats.medium;
        case 3:
            return caGregorianEn.main.en.dates.calendars.gregorian.timeFormats.medium;
        case 4:
            return caGregorianEsBO.main["es-BO"].dates.calendars.gregorian.timeFormats.medium;
        case 5:
            return caGregorianEsCo.main["es-CO"].dates.calendars.gregorian.timeFormats.medium;
        default:
             return caGregorianPt.main.pt.dates.calendars.gregorian.timeFormats.medium;
    }
}

const getDateMYCulture = (codeLang) => {
    switch (codeLang) {
        case 1:
            return caGregorianPt.main.pt.dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
        case 2:
            return caGregorianEsPy.main["es-PY"].dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
        case 3:
            return caGregorianEn.main.en.dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
        case 4:
            return caGregorianEsBO.main["es-BO"].dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
        case 5:
            return caGregorianEsCo.main["es-CO"].dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
        default:
             return caGregorianPt.main.pt.dates.calendars.gregorian.dateTimeFormats.availableFormats.yM;
    }
}


export const getSymbolCurrencyByCode = (codeLang) =>{

    switch (codeLang) {
        case 1:
            return currencyPt.main.pt.numbers.currencies.BRL.symbol;
        case 2:
            return currencyEsPy.main["es-PY"].numbers.currencies.PYG.symbol;
        case 3:
            return currencyEn.main.en.numbers.currencies.USD.symbol;
        case 4:
            return currencyEsBO.main["es-BO"].numbers.currencies.BOB.symbol;
        case 5:
            return currencyEsCo.main["es-CO"].numbers.currencies.COP.symbol;
        default:
             return currencyPt.main.pt.numbers.currencies.BRL.symbol;
    }
}

export const getSymbolCurrency = () =>{
    let currencyArray=[];
    currencyArray[1]=getSymbolCurrencyByCode(1);
    currencyArray[2]=getSymbolCurrencyByCode(2);
    currencyArray[3]=getSymbolCurrencyByCode(3);
    currencyArray[4]=getSymbolCurrencyByCode(4);
    currencyArray[5]=getSymbolCurrencyByCode(5);
    return currencyArray;
}

export const getPercentFormatter = (decimalHouses) =>{
    const intlGenerate = new Internationalization();
    return intlGenerate.getNumberFormat({ format: 'P'+decimalHouses});
}

export const getCurrencyFormatter = (decimalHouses) => {
    const intlGenerate = new Internationalization();
    return intlGenerate.getNumberFormat({ format: 'C'+decimalHouses}); 
}

export const getIntFormatter = () => {
    const intlGenerate = new Internationalization();
    return  intlGenerate.getNumberFormat({ format: 'N0'});
}

export const getFloatFormatter = (decimalHouses) => {
    const intlGenerate = new Internationalization();
    return intlGenerate.getNumberFormat({ format: 'N'+decimalHouses});
}

export const getDateFormatter = (codeLang=1, tipo=0) => {
    const intlGenerate = new Internationalization();

    switch (tipo) {
        case 0:
            return intlGenerate.getDateFormat({format:getDateDMYCulture(codeLang) , type: 'date' }); 
            //return intlGenerate.getDateFormat({format:"dd'/'MM'/'y" , type: 'date' }); 
        case 1:
            return intlGenerate.getDateFormat({format:getDateMYCulture(codeLang) , type: 'date' });
            //return intlGenerate.getDateFormat({format:"MM'/'y" , type: 'date' });
        case 2 :
            let pattern= "MMM'/'y";
            if(codeLang===3){
                pattern= "y'/'MMM";
            }
            return intlGenerate.getDateFormat({format:pattern , type: 'date' });
        case 3:
            return intlGenerate.getDateFormat({format:getDateDMYCulture(codeLang)+' '+getTimeFormatMediumCulture(codeLang) , type: 'date' }); 
        default:
            break;
    } 
}

export const loaderCldr =(codeLang, decimalFloat=2)=>{

    //let locale='pt';
    let culture='pt';
    let currencycode='BRL';

    if(isNullOrUndefined(cldrData.main) || 
        isNullOrUndefined(cldrData.main.supplemental) || 
        isNullOrUndefined(cldrData.main.supplemental.numberingSystems)){

        loadCldr(numberingSystem);
    }

    switch (codeLang) {
        case 1:
            //locale='pt';
            culture='pt';
            currencycode='BRL';

            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main.pt)){
                loadCldr(timeZoneNamePt);
                loadCldr(currencyPt);
                loadCldr(caGregorianPt);
                loadCldr(numberPt);
            }
        break;

        case 2:
            //locale='es';
            culture='es-PY';
            currencycode='PYG';

            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main[culture])){
                loadCldr(timeZoneNameEsPy);
                loadCldr(currencyEsPy);
                loadCldr(caGregorianEsPy);
                loadCldr(numberEsPy);
            }
        break;

        case 3:
            //locale='en';
            culture='en';
            currencycode='USD';

            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main[culture])){
                loadCldr(timeZoneNameEn);
                loadCldr(currencyEn);
                loadCldr(caGregorianEn);
                loadCldr(numberEn);
            }
        break;

        case 4:
            //locale='es';
            culture='es-BO';
            currencycode='BOB';

            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main[culture])){
                loadCldr(timeZoneNameEsBO);
                loadCldr(currencyEsBO);
                loadCldr(caGregorianEsBO);
                loadCldr(numberEsBO);
            }
        break;

        case 5:
            //locale='es';
            culture='es-CO';
            currencycode='COP';

            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main[culture])){
                loadCldr(timeZoneNameEsCo);
                loadCldr(currencyEsCo);
                loadCldr(caGregorianEsCo);
                loadCldr(numberEsCo);
            }
        break;
    
        default:
            if(isNullOrUndefined(cldrData.main) || isNullOrUndefined(cldrData.main.pt)){
                loadCldr(timeZoneNamePt);
                loadCldr(currencyPt);
                loadCldr(caGregorianPt);
                loadCldr(numberPt);
            }
        break;
    }
    setCulture(culture);
    setCurrencyCode(currencycode);
    
    return {symbolCurrency: getSymbolCurrencyByCode(codeLang), currencycode:currencycode, culture:culture };
}