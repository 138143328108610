
import './Loading.scss';

const Loading =()=> {
    return (
        <div id="container-spinner">
            <div className="loader_inicial"></div>
        </div>
    );
}

export default Loading;