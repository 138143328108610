import React, { Component, Fragment } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Card from 'react-bootstrap/Card'
import { connect } from 'react-redux'
import iconeBemEstar from '../../image/menu/newicon.png'
import Util from '../../api/Util'
import { Link } from 'react-router-dom'
import IdiomaData from '../../model/IdiomaData'
import ParamData, { DIAGNOSTICO } from '../../model/ParamData'
import { checkUserAcessoModulo, MODULO } from '../../api/Modulo'
import ListReferencia from '../ListReferencia'
import './Header.scss'
import { NavDropdown } from 'react-bootstrap'
import * as ROUTE from '../../config/route'
import UltimoLancamento from './UltimoLancamento'
import NotaPreenchimento from './NotaPreenchimento'
import NotificacoesModal from './NotificacoesModal'
import { notificar, signOut } from '../../actions'
import { isNullOrUndefined } from '@syncfusion/ej2-base'
import baseConnect, { getConfigAccesToken } from '../../api/managerData'
import notificationIcon from '../../image/icones/notificacao-icon.svg'
import NotificacaoDialog from '../notificacao/NotificacaoDialog'
import axios from 'axios'
import { looksForNotifications } from '../../helpers/notificacoes'
import { HeaderContext } from '../../HeaderContext'
import {
  NOTIFICACAO_MODULOS,
  NOTIFICACAO_OPERACOES,
  NOTIFICACAO_SITUACOES,
  NOTIFICACAO_TIPOS,
} from '../notificacao/NotificacaoInterfaces'

export const URL_BASE = process.env.REACT_APP_URL_BASE_API
const BASE_URL_MAIN = process.env.REACT_APP_URL_BASE
const user_data_local = JSON.parse(
  localStorage.getItem('USER_DATA_SESSION_BKM'),
)

class Header extends Component {
  constructor(props) {
    super(props)
    this.styleImgCardMenu = { width: '25px', height: '25px', margin: '0 auto' }
    this.stileInlineCard = { cursor: 'pointer' }
    this.param_data = ParamData.recoverParamDataFromStorage()
    this.user = user_data_local || this.props?.userData
    this.fazenda_id =
      this.param_data?.fazendaid || this.props?.paramData?.fazendaid
    this.cli_codigo =
      this.param_data?.clienteid || this.props?.paramData?.clienteid
    this.safra_id = this.param_data?.safraid || this.props?.paramData?.safraid
    this.dash = true
    this.c = []

    this.state = {
      showNotaPreenchimento: false,
      showLancamentos: false,
      hasNotifications: 0,
      showFilterSidebar: false,
      fazendaId: this.fazenda_id,
      safraId: this.safra_id,
    }
    this.returnLooksForNotifications()
  }

  componentDidMount() {}

  componentDidUpdate() {}

  getFazendaId() {
    const param_data_local = JSON.parse(
      localStorage.getItem('param_data_local'),
    )
    return param_data_local?.fazendaid || this.fazenda_id
  }

  getSafraId() {
    const param_data_local = JSON.parse(
      localStorage.getItem('param_data_local'),
    )
    return param_data_local?.safraid || this.safra_id
  }

  returnLooksForNotifications = async () => {
    await looksForNotifications(
      this.user || '',
      this.getFazendaId() || '',
      this.cli_codigo || '',
      this.getSafraId() || '',
    )
    await this.hasNotifications()
  }

  hasNotifications = async () => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer  ${this.props.userData.access_token}`,
    }

    let params = {
      cli_codigo: this.cli_codigo || '',
      fazenda_id: this.getFazendaId() || '',
      safra_id: this.getSafraId() || '',
      user_tipo: this.user.user_info.user.tipo || '',
      take: 3,
    }

    try {
      const { data } = await axios.get(
        `${URL_BASE}/notificacoes/get/notifications/set`,
        { params, headers },
      )
      if (data[0]) {
        data.map(item => {
          item.modulo = NOTIFICACAO_MODULOS[item.modulo_id]
          item.operacao =
            NOTIFICACAO_OPERACOES[item.operacao_id.toString().padStart(6, '0')]
          item.tipo = NOTIFICACAO_TIPOS[item.tipo_id]
          item.situacao = NOTIFICACAO_SITUACOES[item.situacao_id]
          return item;
        })
      }
      this.setState({ hasNotifications: data.length || 0 })
      this.dialogNotifications = data
    } catch (error) {
      console.log(error)
    }
  }

  checkSelectedUrl = path => {
    if (process.env.NODE_ENV === 'production') {
      let arr = process.env.REACT_APP_ROUTER_BASENAME.split('/').filter(
        x => x !== '',
      )
      arr.push('lancamento')

      if (Util.checkPathUrl(arr.concat(path))) {
        return 'bg-selected'
      } else {
        return 'bg-not-selected'
      }
    }

    if (Util.checkPathUrl(['lancamento'].concat(path))) {
      return 'bg-selected'
    } else {
      return 'bg-not-selected'
    }
  }

  getFirstName = () => {
    const user = this.props.userData.user_info.user.nome.toLowerCase()
    return `${
      IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'ola').text
    }, ${user.split(' ')[0]}`
  }

  handleCloseNotaPreenchimento = () => {
    this.setState({
      showNotaPreenchimento: false,
    })
  }

  handleOpenNotaPreenchimento = () => {
    this.setState({
      showNotaPreenchimento: true,
    })
  }

  handleCloseLancamentos = () => {
    this.setState({
      showLancamentos: false,
    })
  }

  handleOpenLancamentos = () => {
    this.setState({
      showLancamentos: true,
    })
  }

  closeModal = () => {
    this.setState({
      showNotificacoesModal: false,
    })
  }

  handleOpenNotificacoesModal = () => {
    this.setState({
      showNotificacoesModal: true,
    })
  }

  handleCloseFilterSideBar = () => {
    this.setState({
      showFilterSidebar: false,
    })
  }

  logOut = async () => {
    signOut()
    const user_data = JSON.parse(localStorage.getItem('USER_DATA'))

    if (
      !isNullOrUndefined(user_data) &&
      !isNullOrUndefined(user_data.user_info) &&
      !isNullOrUndefined(user_data.user_info.user)
    ) {
      localStorage.setItem('last_user', user_data.user_info.user.email)
    }
    localStorage.removeItem('USER_DATA')

    await baseConnect
      .post(
        ROUTE.LOGOUT_AUTH,
        {
          lang_locale: this.props.idiomaData.langSelected,
        },
        {
          headers: getConfigAccesToken(this.props.userData),
        },
      )
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    return (
      <HeaderContext.Provider
        value={{
          safraId: this.state.safraId,
          fazendaId: this.state.fazendaId,
          returnLooksForNotifications: this.returnLooksForNotifications,
        }}
      >
        <Fragment>
          <Navbar
            id='menu-lancamento'
            variant='light'
            style={{ minHeight: '62.3px' }}
          >
            {this.props.isLancamento ? (
              <>
                {checkUserAcessoModulo(this.props.userData, MODULO.FAZENDA) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/fazenda'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['fazenda'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='19.674'
                          height='19.674'
                          viewBox='0 0 19.674 19.674'
                          className='s-ion-icon'
                        >
                          <g transform='translate(-2839 -6470)'>
                            <g transform='translate(2839 6470)'>
                              <rect
                                width='11.476'
                                height='1.64'
                                transform='translate(4.099 8.197)'
                                fill='#fff'
                              ></rect>
                              <rect
                                width='1.64'
                                height='1.64'
                                transform='translate(9.017 4.099)'
                                fill='#fff'
                              ></rect>
                              <path
                                d='M26.395,12.459,19.837,10l-6.558,2.459L10,18.2V29.674H29.674V18.2ZM20.656,23.116v4.918h-1.64V23.116Zm-3.279,4.918h-1.64V23.116h1.64Zm6.558,0H22.3V23.116h1.64Zm4.1,0H25.575V21.476H14.1v6.558H11.64v-9.4l2.77-4.846,5.428-2.035,5.428,2.035,2.769,4.846Z'
                                transform='translate(-10 -10)'
                                fill='#fff'
                              ></path>
                            </g>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'fazenda',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {checkUserAcessoModulo(this.props.userData, MODULO.EQUIPE) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/equipe'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['equipe'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='19.365'
                          height='23.5'
                          viewBox='0 0 19.365 23.5'
                          className='s-ion-icon'
                        >
                          <g transform='translate(-6.329 -0.748)'>
                            <g transform='translate(6.583 0.997)'>
                              <path
                                d='M25.414,22.658,24.4,18.1a2.137,2.137,0,0,0-1.7-1.641l-4.436-.824V14.616A4.61,4.61,0,0,0,20.621,10.6V8.793h1.013a.7.7,0,0,0,.637-.976,2.745,2.745,0,0,0-2.023-1.591V4.541s0-.007,0-.011,0-.007,0-.011V3.177A2.182,2.182,0,0,0,18.068,1H13.956a2.182,2.182,0,0,0-2.18,2.18V4.52s0,.007,0,.011,0,.007,0,.011V6.226A2.745,2.745,0,0,0,9.753,7.817a.7.7,0,0,0,.637.976H11.4V10.6a4.61,4.61,0,0,0,2.351,4.013v1.033l-4.433.816a2.136,2.136,0,0,0-1.7,1.642L6.609,22.658A1.1,1.1,0,0,0,7.684,24H24.34a1.1,1.1,0,0,0,1.075-1.339Zm-14.37.6V16.9l1.1-.2v1.84a1.286,1.286,0,0,0,1.285,1.285h5.164a1.286,1.286,0,0,0,1.285-1.285V16.692l1.1.2v6.359H11.044Zm2.947-6.9L15.2,17.68a1.13,1.13,0,0,0,1.615,0l1.214-1.331,1.1.205V18.54a.544.544,0,0,1-.543.543H13.43a.544.544,0,0,1-.543-.543V16.563ZM12.518,4.9h6.987V6.176H12.518ZM13.956,1.74h4.112a1.439,1.439,0,0,1,1.438,1.438v.982H12.518V3.177A1.439,1.439,0,0,1,13.956,1.74ZM12.268,6.918h7.488a2.006,2.006,0,0,1,1.805,1.133h-11.1A2.005,2.005,0,0,1,12.268,6.918ZM12.145,10.6V8.793h7.734V10.6a3.867,3.867,0,1,1-7.734,0Zm3.867,4.609a4.579,4.579,0,0,0,1.516-.262V15.8L16.271,17.18a.359.359,0,0,1-.52,0L14.5,15.813V14.95A4.58,4.58,0,0,0,16.012,15.211ZM7.4,23.121a.358.358,0,0,1-.07-.3l1.009-4.553a1.4,1.4,0,0,1,1.112-1.072l.847-.156v6.217H7.684A.357.357,0,0,1,7.4,23.121Zm17.215,0a.357.357,0,0,1-.28.134H21.722V17.035l.849.158a1.4,1.4,0,0,1,1.11,1.072l1.01,4.555A.358.358,0,0,1,24.62,23.121Z'
                                transform='translate(-6.583 -0.998)'
                                fill='#fff'
                                stroke='#fff'
                                strokeWidth='0.5'
                              ></path>
                            </g>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'equipe',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {checkUserAcessoModulo(
                  this.props.userData,
                  MODULO.FINANCEIRO,
                ) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/financeiro'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['financeiro'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22.396'
                          height='22.396'
                          viewBox='0 0 22.396 22.396'
                          className='s-ion-icon'
                        >
                          <g transform='translate(-21.5 -427.5)'>
                            <path
                              d='M11.7,1A10.7,10.7,0,1,0,22.4,11.7,10.71,10.71,0,0,0,11.7,1Zm0,20.5A9.806,9.806,0,1,1,21.5,11.7,9.818,9.818,0,0,1,11.7,21.5Z'
                              transform='translate(21 427)'
                              fill='#aa9b8f'
                              stroke='#fff'
                              strokeWidth='1'
                            ></path>
                            <path
                              d='M18.12,9.514h1.211a1.516,1.516,0,0,1,1.514,1.514h.606a2.122,2.122,0,0,0-2.12-2.12h-.3V8h-.606v.908h-.3A2.122,2.122,0,0,0,16,11.028v.3a2.122,2.122,0,0,0,2.12,2.12h1.211a1.516,1.516,0,0,1,1.514,1.514v.3a1.516,1.516,0,0,1-1.514,1.514H18.12a1.516,1.516,0,0,1-1.514-1.514H16a2.122,2.122,0,0,0,2.12,2.12h.3V18.3h.606v-.908h.3a2.122,2.122,0,0,0,2.12-2.12v-.3a2.122,2.122,0,0,0-2.12-2.12H18.12a1.516,1.516,0,0,1-1.514-1.514v-.3A1.516,1.516,0,0,1,18.12,9.514Z'
                              transform='translate(13.993 425.731)'
                              fill='#aa9b8f'
                              stroke='#fff'
                              strokeWidth='1'
                            ></path>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'financeiro',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {checkUserAcessoModulo(this.props.userData, MODULO.PECUARIA) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/pecuaria'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['pecuaria'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='23.47'
                          height='21.382'
                          viewBox='0 0 23.47 21.382'
                          className='s-ion-icon'
                        >
                          <g transform='translate(0.101 0.101)'>
                            <path
                              d='M43.05,293.17a1.6,1.6,0,0,1-.3-.027c-1.058-.2-2-1.421-2.6-2.41a1.6,1.6,0,0,1-.237-1.244c.208-.844,1.133-1.4,2.027-1.93.333-.2.648-.387.912-.58a7.547,7.547,0,0,0,1.416-1.768,8.579,8.579,0,0,1,2.816-3.027c2.074-1.082,4.679-.062,4.79-.018a.437.437,0,1,1-.325.812c-.026-.01-2.347-.913-4.061-.019A7.96,7.96,0,0,0,45,285.7a8.015,8.015,0,0,1-1.625,1.986c-.3.219-.646.426-.982.625-.706.423-1.506.9-1.627,1.389a.751.751,0,0,0,.135.578c.7,1.149,1.456,1.9,2.019,2.007a.622.622,0,0,0,.519-.125,4.684,4.684,0,0,1,4.492-.4,4.466,4.466,0,0,0,4.985-2.376.437.437,0,0,1,.776.4,5.306,5.306,0,0,1-6.039,2.8,3.831,3.831,0,0,0-3.69.268A1.5,1.5,0,0,1,43.05,293.17Z'
                              transform='translate(-39.767 -281.273)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M43.032,293.258a1.717,1.717,0,0,1-.315-.029c-1.1-.21-2.059-1.453-2.674-2.459a1.7,1.7,0,0,1-.249-1.325c.22-.888,1.163-1.451,2.077-2,.359-.214.653-.39.9-.575a7.47,7.47,0,0,0,1.386-1.736,8.672,8.672,0,0,1,2.86-3.068c2.121-1.107,4.767-.067,4.878-.022a.544.544,0,0,1,.012,1.005.546.546,0,0,1-.415,0c-.023-.009-2.318-.887-3.973-.023a7.877,7.877,0,0,0-2.453,2.7,8.1,8.1,0,0,1-1.651,2.013c-.308.225-.671.441-.99.631-.7.417-1.468.878-1.578,1.322a.651.651,0,0,0,.121.5c.676,1.106,1.422,1.856,1.949,1.958a.516.516,0,0,0,.433-.105,4.793,4.793,0,0,1,4.591-.415,4.353,4.353,0,0,0,4.856-2.323.541.541,0,0,1,.32-.269.544.544,0,0,1,.645.77,5.413,5.413,0,0,1-6.166,2.854,3.733,3.733,0,0,0-3.593.253A1.6,1.6,0,0,1,43.032,293.258Zm6.246-11.483a4.643,4.643,0,0,0-2.159.486,8.493,8.493,0,0,0-2.777,2.99,7.586,7.586,0,0,1-1.442,1.8c-.259.19-.558.37-.921.585-.876.523-1.781,1.063-1.978,1.864a1.5,1.5,0,0,0,.224,1.163c.592.969,1.511,2.165,2.53,2.36a1.4,1.4,0,0,0,1.123-.265,3.934,3.934,0,0,1,3.788-.284,5.2,5.2,0,0,0,5.91-2.75.331.331,0,0,0,.021-.254.333.333,0,0,0-.413-.214.334.334,0,0,0-.195.164,4.576,4.576,0,0,1-5.113,2.428,4.584,4.584,0,0,0-4.394.383.736.736,0,0,1-.6.144c-.594-.115-1.375-.883-2.091-2.056a.849.849,0,0,1-.147-.66c.131-.53.943-1.017,1.66-1.445.332-.2.689-.409.989-.63a7.949,7.949,0,0,0,1.6-1.959,8.04,8.04,0,0,1,2.534-2.775,5.708,5.708,0,0,1,4.149.015.332.332,0,0,0,.253,0,.33.33,0,0,0-.007-.611A7.506,7.506,0,0,0,49.278,281.775Z'
                              transform='translate(-39.748 -281.255)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M54.313,286.715a.438.438,0,0,1-.237-.8c.958-.619,2.506-1.937,2.59-2.835a.66.66,0,0,0-.232-.572.972.972,0,0,0-.8-.3c-.921.087-1.974,1.285-2.3,1.738a.437.437,0,0,1-.709-.512c.057-.08,1.428-1.956,2.929-2.1a1.856,1.856,0,0,1,1.484.531,1.529,1.529,0,0,1,.5,1.287c-.149,1.614-2.7,3.3-2.985,3.489A.449.449,0,0,1,54.313,286.715Z'
                              transform='translate(-41.614 -281.221)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M54.3,286.8a.544.544,0,0,1-.3-1c1.01-.653,2.466-1.939,2.541-2.755a.555.555,0,0,0-.2-.485.864.864,0,0,0-.718-.267c-.877.084-1.925,1.281-2.224,1.695a.544.544,0,0,1-.979-.233.537.537,0,0,1,.1-.4c.057-.081,1.442-1.994,3.006-2.141a1.964,1.964,0,0,1,1.567.561,1.631,1.631,0,0,1,.533,1.374c-.153,1.659-2.739,3.378-3.033,3.569A.55.55,0,0,1,54.3,286.8Zm1.423-4.727a1.081,1.081,0,0,1,.771.331.767.767,0,0,1,.265.659c-.087.944-1.626,2.26-2.639,2.915a.331.331,0,0,0-.1.457.335.335,0,0,0,.458.1c.284-.185,2.792-1.85,2.936-3.408a1.425,1.425,0,0,0-.467-1.2,1.754,1.754,0,0,0-1.4-.5c-1.468.138-2.839,2.033-2.853,2.053a.324.324,0,0,0-.058.244.33.33,0,0,0,.595.142c.335-.465,1.416-1.69,2.377-1.782C55.643,282.078,55.681,282.076,55.718,282.076Z'
                              transform='translate(-41.595 -281.202)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M54.139,304.105a.437.437,0,0,1-.424-.331c-.019-.073-.037-.149-.055-.225-.773-3.435-4.434-9.072-4.471-9.129a.437.437,0,0,1,.732-.478c.155.237,3.785,5.827,4.592,9.414.015.068.032.136.049.2a.435.435,0,0,1-.316.53A.389.389,0,0,1,54.139,304.105Z'
                              transform='translate(-41.115 -283.031)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M54.121,304.194a.544.544,0,0,1-.527-.411c-.017-.065-.032-.132-.049-.2l-.006-.029c-.77-3.417-4.421-9.038-4.457-9.093a.544.544,0,0,1,.911-.595c.155.237,3.8,5.844,4.607,9.449.015.066.031.132.048.2a.54.54,0,0,1-.061.413.534.534,0,0,1-.334.246A.428.428,0,0,1,54.121,304.194Zm-4.584-10.359a.324.324,0,0,0-.18.054.329.329,0,0,0-.1.456c.036.056,3.708,5.71,4.486,9.163l.007.026c.015.067.031.132.047.2a.332.332,0,0,0,.32.249.258.258,0,0,0,.077-.01.328.328,0,0,0,.243-.4c-.017-.067-.034-.136-.05-.2-.8-3.569-4.424-9.145-4.577-9.38A.333.333,0,0,0,49.537,293.835Z'
                              transform='translate(-41.097 -283.013)'
                              fill='none'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M64.827,288.881a.583.583,0,0,1-.067-.005c-.366-.056-.739-.12-1.112-.189a18.579,18.579,0,0,1-6.924-2.365.437.437,0,0,1,.522-.7,18.078,18.078,0,0,0,6.561,2.207c.364.067.73.129,1.086.183a.438.438,0,0,1-.066.87Z'
                              transform='translate(-42.198 -281.834)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M64.809,288.969a.623.623,0,0,1-.08-.006c-.369-.056-.744-.12-1.118-.19a18.665,18.665,0,0,1-6.968-2.384.544.544,0,0,1-.113-.76.536.536,0,0,1,.358-.214.548.548,0,0,1,.4.1,17.968,17.968,0,0,0,6.516,2.188c.364.067.728.128,1.084.183a.544.544,0,0,1-.083,1.082Zm-7.842-3.347a.293.293,0,0,0-.048,0,.33.33,0,0,0-.149.592,18.45,18.45,0,0,0,6.879,2.346c.372.069.745.132,1.11.188a.3.3,0,0,0,.05,0,.331.331,0,0,0,.05-.658c-.358-.055-.724-.117-1.089-.184a18.188,18.188,0,0,1-6.606-2.226A.322.322,0,0,0,56.967,285.623Z'
                              transform='translate(-42.18 -281.816)'
                              fill='none'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M48.876,287.484a.741.741,0,1,1-.741-.741A.74.74,0,0,1,48.876,287.484Z'
                              transform='translate(-40.864 -282.01)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M48.117,288.314a.848.848,0,1,1,.847-.847A.848.848,0,0,1,48.117,288.314Zm0-1.482a.634.634,0,1,0,.634.635A.635.635,0,0,0,48.117,286.832Z'
                              transform='translate(-40.845 -281.992)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M55.071,303.329a.437.437,0,0,1-.137-.853,13.51,13.51,0,0,0,9.273-12.9c0-.155,0-.31-.007-.466a.437.437,0,0,1,.874-.028c.005.165.007.33.007.494a14.382,14.382,0,0,1-9.874,13.726A.442.442,0,0,1,55.071,303.329Z'
                              transform='translate(-41.918 -282.29)'
                              fill='#fff'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <path
                              d='M55.053,303.417a.544.544,0,0,1-.171-1.061,13.4,13.4,0,0,0,9.2-12.794c0-.155,0-.308-.007-.462a.544.544,0,0,1,.526-.56.554.554,0,0,1,.561.525c.005.166.007.331.007.5a14.49,14.49,0,0,1-9.947,13.828A.545.545,0,0,1,55.053,303.417Zm9.566-14.664h-.01a.329.329,0,0,0-.231.105.323.323,0,0,0-.088.235c.005.156.007.312.007.469a13.618,13.618,0,0,1-9.346,13,.33.33,0,0,0-.211.416.34.34,0,0,0,.419.212,14.276,14.276,0,0,0,9.8-13.625c0-.163,0-.327-.007-.489A.335.335,0,0,0,64.619,288.754Z'
                              transform='translate(-41.9 -282.272)'
                              fill='none'
                              stroke='#fff'
                              strokeWidth='0.2'
                            ></path>
                            <g transform='translate(1.279 7.577)'>
                              <path
                                d='M42.91,290.652a.454.454,0,0,0-.9-.064,1.651,1.651,0,0,1-.431.358.437.437,0,0,0,.463.741,1.979,1.979,0,0,0,.808-.807A.448.448,0,0,0,42.91,290.652Z'
                                transform='translate(-41.264 -290.09)'
                                fill='#fff'
                                stroke='#fff'
                                strokeWidth='0.2'
                              ></path>
                              <path
                                d='M41.79,291.842a.543.543,0,0,1-.289-1,1.668,1.668,0,0,0,.386-.313.561.561,0,1,1,1.034.391,2.078,2.078,0,0,1-.843.844A.546.546,0,0,1,41.79,291.842Zm.647-1.556a.347.347,0,0,0-.343.3l0,.028-.018.022a1.768,1.768,0,0,1-.457.383.332.332,0,0,0-.106.455.34.34,0,0,0,.456.105,1.885,1.885,0,0,0,.766-.761.356.356,0,0,0,.054-.184A.349.349,0,0,0,42.437,290.286Z'
                                transform='translate(-41.246 -290.072)'
                                fill='#fff'
                                stroke='#fff'
                                strokeWidth='0.2'
                              ></path>
                            </g>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'pecuaria',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {this.props.paramData.diagnostico === DIAGNOSTICO.COMPLETO &&
                checkUserAcessoModulo(this.props.userData, MODULO.MAQUINA) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/maquina'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['maquina'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='21.396'
                          height='21.396'
                          viewBox='0 0 21.396 21.396'
                          className='s-ion-icon'
                        >
                          <g transform='translate(-15 -632)'>
                            <path
                              d='M13.744,56.667a3.744,3.744,0,1,0,3.745,3.745A3.745,3.745,0,0,0,13.744,56.667Zm0,5.991a2.247,2.247,0,1,1,2.247-2.246A2.249,2.249,0,0,1,13.744,62.658Z'
                              transform='translate(5 589.24)'
                              fill='#fff'
                            ></path>
                            <path
                              d='M29.829,21.373a5.318,5.318,0,0,0-1.736-1.159l-3.136-1.3-3.368-7.132h1.783V10H10v1.783h1.783v7.132H10V20.7h4.458A6.24,6.24,0,0,1,20.7,26.939v2.673h3.729a2.66,2.66,0,0,0,5.023,0H31.4V25.156A5.311,5.311,0,0,0,29.829,21.373Zm-14.48-2.458H13.566V11.783h1.783Zm1.783,0V11.783h2.485l3.368,7.132H17.132Zm9.807,10.7a.891.891,0,1,1,.891-.891A.893.893,0,0,1,26.939,29.613Zm2.673-1.783H29.45a2.66,2.66,0,0,0-5.023,0H22.482v-.891A8.008,8.008,0,0,0,19.49,20.7H24.6l2.811,1.164a3.565,3.565,0,0,1,2.2,3.294Z'
                              transform='translate(5 622)'
                              fill='#fff'
                            ></path>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'maquinas',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {this.props.paramData.diagnostico === DIAGNOSTICO.COMPLETO &&
                checkUserAcessoModulo(this.props.userData, MODULO.AGRICOLA) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/agricola'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['agricola'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='19.674'
                          height='19.674'
                          viewBox='0 0 19.674 19.674'
                          className='s-ion-icon'
                        >
                          <path
                            d='M20.656,26.21v-.636A4.886,4.886,0,0,1,22.1,22.1l1.856-1.856a3.193,3.193,0,0,0,3.811-.524,6.5,6.5,0,0,0,1.7-6.228,6.5,6.5,0,0,0-6.229,1.7,3.2,3.2,0,0,0-.467,3.921l-1.829,1.83c-.127.127-.244.26-.36.4a6.535,6.535,0,0,0-1.843-3.675l-.381-.381a3.914,3.914,0,0,0-.485-4.944,7.943,7.943,0,0,0-7.611-2.077,7.945,7.945,0,0,0,2.075,7.613,3.911,3.911,0,0,0,4.84.545l.4.4A4.885,4.885,0,0,1,19.016,22.3v3.919A25.777,25.777,0,0,0,10,28.158l.628,1.515a23.915,23.915,0,0,1,18.419,0l.627-1.514A25.646,25.646,0,0,0,20.656,26.21Zm3.736-9.864a4.913,4.913,0,0,1,3.449-1.428c.063,0,.127,0,.191,0A4.87,4.87,0,0,1,26.6,18.557a1.564,1.564,0,0,1-2.212-2.212Zm-7.683.365a2.277,2.277,0,0,1-3.218,0,6.31,6.31,0,0,1-1.828-5.045q.286-.026.573-.026a6.368,6.368,0,0,1,4.473,1.854A2.278,2.278,0,0,1,16.709,16.711Z'
                            transform='translate(-9.999 -9.999)'
                            fill='#fff'
                          ></path>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'agricola',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {checkUserAcessoModulo(this.props.userData, MODULO.ESTOQUE) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/estoque'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['estoque'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22.814'
                          height='22.814'
                          className='s-ion-icon'
                          viewBox='0 0 512 512'
                        >
                          <title>Document Text</title>
                          <path
                            d='M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z'
                            fill='none'
                            stroke='#fff'
                            strokeLinejoin='round'
                            strokeWidth='32'
                          ></path>
                          <path
                            d='M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160'
                            fill='#fff'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='32'
                          ></path>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'estoque',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}

                {this.props.paramData.diagnostico === DIAGNOSTICO.COMPLETO &&
                checkUserAcessoModulo(this.props.userData, MODULO.CLIMA) ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/clima'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['clima'])}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22.814'
                          height='22.814'
                          viewBox='0 0 22.814 22.814'
                          className='s-ion-icon'
                        >
                          <g transform='translate(-10 -10)'>
                            <path
                              d='M29.012,16.654a3.8,3.8,0,0,0-3.8-3.8,3.755,3.755,0,0,0-1.267.233,4.744,4.744,0,0,0-9.189,1.668,4.753,4.753,0,1,0,0,9.506H29.012a3.8,3.8,0,0,0,0-7.6Zm0,5.7H14.753a2.852,2.852,0,1,1,0-5.7h1.9v-1.9a2.849,2.849,0,0,1,5.65-.525,3.769,3.769,0,0,0-.9,2.427h1.9a1.9,1.9,0,1,1,3.8,0v.527a3.788,3.788,0,0,0-1.9,3.275h1.9a1.9,1.9,0,1,1,1.9,1.9Z'
                              fill='#fff'
                            ></path>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(12.852 26.635)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(17.129 26.635)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(21.407 26.635)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(25.685 26.635)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(10.951 30.913)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(15.228 30.913)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(19.506 30.913)'
                              fill='#fff'
                            ></circle>
                            <circle
                              cx='0.95'
                              cy='0.95'
                              r='0.95'
                              transform='translate(23.784 30.913)'
                              fill='#fff'
                            ></circle>
                          </g>
                        </svg>
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'clima',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}
                {this.props.paramData.acesso_bem_estar_animal ? (
                  <Link
                    to={
                      process.env.REACT_APP_ROUTER_BASENAME_HEADER +
                      'lancamento/bem/estar'
                    }
                  >
                    <Navbar.Brand className='navbar-brand-menu'>
                      <Card
                        bg='light'
                        style={this.stileInlineCard}
                        className={this.checkSelectedUrl(['bem', 'estar'])}
                      >
                        <img
                          src={iconeBemEstar}
                          style={{ width: '24px' }}
                          className='m-auto'
                          alt=''
                        />
                        <Card.Body>
                          <Card.Title>
                            {
                              IdiomaData.selectGroupItem(
                                this.props.idiomaData,
                                'messages',
                                'bem_estar_animal',
                              ).text
                            }
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </Navbar.Brand>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <div id='left-menus'>
              {this.props.isLancamento ? (
                <>
                  <NavDropdown
                    title={
                      <div className='notificacao-div'>
                        <span className='notificacao-badge'>
                          {this.state.hasNotifications}
                        </span>
                        <img
                          src={notificationIcon}
                          alt='Ícone notificação'
                          className='notificacao-icone'
                        />
                      </div>
                    }
                    id='nsd-notificacao'
                  >
                    <div>
                      <NotificacaoDialog
                        {...this.props}
                        dialogNotifications={this.dialogNotifications}
                        returnLooksForNotifications={
                          this.returnLooksForNotifications
                        }
                      />
                    </div>
                  </NavDropdown>
                </>
              ) : (
                <></>
              )}

              <div id='user-data'>
                <NavDropdown title={this.getFirstName()}>
                  {this.props.paramData.diagnostico ===
                    DIAGNOSTICO.COMPLETO && (
                    <>
                      <NavDropdown.Item
                        onClick={this.handleOpenNotaPreenchimento}
                      >
                        <span className='material-icons'>description</span>
                        <p>
                          {
                            IdiomaData.selectGroupItem(
                              this.props.idiomaData,
                              'messages',
                              'notas_preenchimento',
                            ).text
                          }
                        </p>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={this.handleOpenLancamentos}>
                        <span className='material-icons'>timer</span>
                        <p>
                          {
                            IdiomaData.selectGroupItem(
                              this.props.idiomaData,
                              'messages',
                              'ultimos_lancamentos',
                            ).text
                          }
                        </p>
                      </NavDropdown.Item>
                    </>
                  )}
                  <NavDropdown.Item onClick={this.handleOpenNotificacoesModal}>
                    <span className='material-icons'>notifications_active</span>
                    <p>
                      {
                        IdiomaData.selectGroupItem(
                          this.props.idiomaData,
                          'notificacoes',
                          'notificacoes',
                        ).text
                      }
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={this.logOut}
                    href={BASE_URL_MAIN + ROUTE.LOGIN}
                  >
                    <span className='material-icons'>exit_to_app</span>
                    <p>
                      {
                        IdiomaData.selectGroupItem(
                          this.props.idiomaData,
                          'messages',
                          'logout',
                        ).text
                      }
                    </p>
                  </NavDropdown.Item>
                </NavDropdown>
                <span>
                  {
                    IdiomaData.selectGroupItem(
                      this.props.idiomaData,
                      'messages',
                      'bem_vindo',
                    ).text
                  }
                </span>
              </div>
            </div>
          </Navbar>
          <ListReferencia />

          <UltimoLancamento
            {...this.props}
            showLancamentos={this.state.showLancamentos}
            handleCloseLancamentos={this.handleCloseLancamentos}
          />
          <NotaPreenchimento
            {...this.props}
            showNotaPreenchimento={this.state.showNotaPreenchimento}
            handleCloseNotaPreenchimento={this.handleCloseNotaPreenchimento}
          />
          <NotificacoesModal
            {...this.props}
            closeModal={this.closeModal}
            showNotificacoesModal={this.state.showNotificacoesModal}
          />
          <div id='content-body'>{this.props.children}</div>
        </Fragment>
      </HeaderContext.Provider>
    )
  }
}

const mapStateToProps = state => {
  return {
    idiomaData: state.idiomaData,
    paramData: state.paramData,
    userData: state.auth.userData,
    caracterizaFazenda: state.caracterizaFazenda,
  }
}

export default connect(mapStateToProps, { notificar })(Header)
