import { DataManager, UrlAdaptor, Query, DataUtil } from '@syncfusion/ej2-data';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import axios from 'axios';

export const URL_BASE=process.env.REACT_APP_URL_BASE_API;

export const getDataManager = (objConfig_) =>{

    //DataUtil.serverTimezoneOffset = (2 * (new Date().getTimezoneOffset() / 60));
    DataUtil.serverTimezoneOffset=0;

    const objConfig ={
        url: isNullOrUndefined(objConfig_.url) ? null : URL_BASE + objConfig_.url,
        insertUrl: isNullOrUndefined(objConfig_.insertUrl) ? null : URL_BASE + objConfig_.insertUrl,
        removeUrl: isNullOrUndefined(objConfig_.removeUrl) ? null : URL_BASE + objConfig_.removeUrl,
        updateUrl: isNullOrUndefined(objConfig_.updateUrl) ? null : URL_BASE + objConfig_.updateUrl,
        adaptor: isNullOrUndefined(objConfig_.adaptor) ? new UrlAdaptor() : objConfig_.adaptor,
        headers: isNullOrUndefined(objConfig_.headers) ? null : objConfig_.headers,
        crossDomain: true
    };

    return  new DataManager(objConfig);
}

export const getQueryParam = (objConfig_) => {
    const query=new Query();

    if(objConfig_){
        Object.entries(objConfig_).forEach(([key, value]) => {
            query.addParams(key, value);
        });
    }    

    return query;
}

export const updateQueryParam = (grid, objConfig_) => {

    if(objConfig_){
        Object.entries(objConfig_).forEach(([key, value]) => {
            const objIndex = grid.query.params.findIndex((x => x.key === key));
            if(objIndex>0)
            grid.query.params[objIndex].value = value;
        });
    }
}

export const getConfigAccesToken = (userData) => {
  return {Authorization: `Bearer ${userData.access_token}`};
}

export const getHeaderDataManager = (userData) => {
    return [{ Accept: 'application/json', ...getConfigAccesToken(userData) }];
}

export class CustomUrlAdaptor extends UrlAdaptor { 

    processResponse() {        
        const data = super.processResponse.apply(this, arguments);

        return data;
    }
}

const baseConnect = axios.create({
    baseURL: URL_BASE,
});

export default baseConnect;
