import { combineReducers } from 'redux';
import authReducer from './authReducer';
import changeFilterReducer from './changeFilterReducer';
import changeIdiomaReducer from './changeIdiomaReducer';
import loadingReducer from './loadingReducer';
import notificarReducer from './notificarReducer';
import updateListReferenciaReducer from './updateListReferenciaReducer';

export default combineReducers({
    auth: authReducer,
    paramData: changeFilterReducer,
    notificaData: notificarReducer,
    loadingData: loadingReducer,
    idiomaData: changeIdiomaReducer,
    listReferenciaData: updateListReferenciaReducer,
});
  