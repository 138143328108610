import React from 'react';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import SidebarRightFilterSafra from './SidebarRightFilterSafra';
import _ from 'lodash';
import ParamData from '../../model/ParamData';
import { loaderCldr } from '../../config/LoaderCldr';
import IdiomaData from '../../model/IdiomaData';
import { HeaderContext } from '../../HeaderContext';

export default class SidebarRightFilterFazenda extends SidebarRightFilterSafra {

    constructor(props) {
        super(props);

        this.dataFazendaFilter = this.props.userData.info_fazenda.fazenda_caracterizada;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    franquiaFilterChange = ((_super) => {
        return () => {
            this.resetComboBox(this.fazendaFilterObj);
            _super();
        };
    })(this.franquiaFilterChange);

    clienteFilterChange = ((_super) => {
        return () => {
            this.resetComboBox(this.fazendaFilterObj);
            _super();
        };
    })(this.clienteFilterChange);

    safraFilterChange = ((_super) => {
        return () => {
            _super();
            this.resetComboBox(this.fazendaFilterObj);
            if (this.safraFilterCheckSelected()) {
                this.fazendaFilterObj.dataSource = this.dataFazendaFilter.filter(x => x.cli_codigo === this.clienteFilterObj.value && x.safra_id === this.safraFilterObj.itemData.safra_id);
                this.fazendaFilterObj.dataSource = _.uniqBy(this.fazendaFilterObj.dataSource, 'fazenda_id');
                this.fazendaFilterObj.enabled = true;
                this.fazendaFilterObj.dataBind();

                if (this.fazendaFilterObj.dataSource) {
                    const objTmp = this.fazendaFilterObj.dataSource.find(x => x.fazenda_id === this.paramData.fazendaid);
                    if (objTmp) {
                        this.fazendaFilterObj.value = this.paramData.fazendaid;
                        this.fazendaFilterChange();
                        if (this.runFilterAutomatic) {
                            setTimeout(() => this.clickAplicar(), 100);
                        }
                    }
                }
            }
        };

    })(this.safraFilterChange);

    fazendaFilterCheckSelected = () => {
        if (!isNullOrUndefined(this.fazendaFilterObj.value) && !isNullOrUndefined(this.fazendaFilterObj.index)) {
            this.fazendaFilterLabelErrorObj.style.display = "none";
            return true;
        } else {
            this.fazendaFilterLabelErrorObj.style.display = "inline-block";
            return false;
        }
    }

    fazendaFilterChange = () => {
        this.hasChangeParameter = true;
        this.paramData.setFazendaId(this.fazendaFilterObj.value);

        if (isNullOrUndefined(this.paramData.fazendaid_list) || this.paramData.fazendaid_list.length === 0 || this.paramData.fazendaid_list.length === 1) {
            this.paramData.setFazendaIdList([this.fazendaFilterObj.value]);
        }

        this.fazendaFilterCheckSelected();
    }

    clickAplicar = ((_super) => {
        return () => {
            _super();
            if (this.fazendaFilterCheckSelected()) {
                const faz = this.fazendaFilterObj.getDataByValue(this.fazendaFilterObj.value);
                if (faz) {
                    this.paramData.setCasaDecimalMoeda(faz.casa_decimal_moeda);
                    this.paramData.setMoeda(faz.moeda);
                    this.paramData.setTipoPeso(faz.tipo_peso);
                    this.paramData.setNomeFazenda(faz.nome);
                    this.paramData.setIsSiga(faz.faz_chave === null ? false : true)
                    loaderCldr(faz.moeda, faz.casa_decimal_moeda);

                    if (this.hasChangeParameter && this.hasUserSelectedParameter) {
                        ParamData.saveParamDataFromStorage(this.paramData);
                    }
                }
            }
        };
    })(this.clickAplicar);

    fazendaFilterSelect = (args) => {
        this.hasUserSelectedParameter = true;
    }

    buildRenderFilterSafraBase = () => {
        return (
            <div>
                <div ref={r => this.containerFazendaFilter = r} className="filter-container-element">
                    <label htmlFor="fazendaFilter" style={{ display: "inline-block", verticalAlign: "bottom" }} >{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'fazenda').text}</label>
                    <DropDownListComponent id="fazendaFilter"
                        key="fazendaFilter"
                        locale={this.props.idiomaData.langSelected}
                        allowCustom={false}
                        ref={(combobox) => { this.fazendaFilterObj = combobox; }}
                        fields={{ value: "fazenda_id", text: "nome" }}
                        enabled={false}
                        placeholder="Fazenda"
                        filterBarPlaceholder={`${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'buscar').text} ${IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'fazenda').text}`}
                        sortOrder='Ascending'
                        //noRecordsTemplate={this.noRecordsTemplateFilter}
                        ignoreAccent={true}
                        allowFiltering={true}
                        filterType="Contains"
                        showPopupButton={true}
                        popupHeight={this.popupHeight}
                        change={this.fazendaFilterChange}
                        select={this.fazendaFilterSelect}
                        focus={this.focusCb} />
                    <label className="e-error" htmlFor="fazendaFilter" ref={l => this.fazendaFilterLabelErrorObj = l} style={{ display: "none", verticalAlign: "bottom" }}>{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'informacao_requerida').text}</label>
                </div>
                {this.buildRenderFilterFazendaBase()}
            </div>
        );
    }

    buildRenderFilterFazendaBase = () => {
        return (<></>);
    }
}

SidebarRightFilterFazenda.contextType = HeaderContext;
