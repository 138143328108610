import { each, parseInt } from 'lodash'
class IdiomaData {
  constructor() {
    this.langSelected = null
    this.langData = []
  }

  setIdiomaData(data, lang) {
    this.langSelected = lang
    this.langData = data
  }

  static selectGroup(idiomaData, group, is_int_item = false) {
    let dd = []
    if (Array.isArray(group)) {
      dd = idiomaData.langData.filter(gp => group.includes(gp.group))
    } else {
      dd = idiomaData.langData.filter(gp => gp.group === group)
    }

    if (is_int_item) {
      each(dd, x => (x.item = parseInt(x.item)))
    }

    return dd
  }

  static selectItemFromGroupList(groupList, item) {
    let tmp = groupList.find(i => String(i.item) === String(item))
    if (!tmp) {
      return { text: '' }
    }
    return tmp
  }

  static selectGroupItem(idiomaData, group, item) {
    let tmp = idiomaData.langData.find(
      gp => gp.group === group && String(gp.item) === String(item),
    )
    if (!tmp) {
      return { text: '' }
    }

    return tmp
  }

  /*constructor(){
        this.langSelected=null;
        this.langData=[];
        this.langData["pt-br"]=null;
        this.langData["en"]=null;
        this.langData["es"]=null;
    }

    setIdiomaData(data, lang){
        this.langData[lang]=data;
    }

    setSelectedLang(lang){
        this.langSelected=lang;
    }

    getIdiomaDataSelected(){
        if(!this.langSelected)
          this.langSelected="pt-br";

        return this.langData[this.langSelected];
    }

    checkLangIsLoaded(lang){
      if(this.langData.length>0 && this.langData[this.langSelected]){
          return true;
      }

      return false;
    }*/

  static getLangSaved() {
    return localStorage.getItem('lang')
  }

  static saveLang(lang) {
    localStorage.setItem('lang', lang)
  }
}

export default IdiomaData
