import { isNullOrUndefined } from '@syncfusion/ej2-base';
import _ from 'lodash';

export const DIAGNOSTICO = {
   COMPLETO: 0,
   EXPRESSO: 1,
   EXPRESSO_REPRODUCAO: 2,
}

/*export const PARAM_DATA_SAVE_STORAGE_NAME={
   PARAM_DATA_SAFRA:'param_data_safra',
   PARAM_DATA_FAZENDA:'param_data_fazenda',
   PARAM_DATA_MULTI_FAZENDA:'param_data_multi_fazenda',
}*/

class ParamData {

   constructor(franquiaid = null, clienteid = null, safraid = null, fazendaid = null) {
      this.franquiaid = franquiaid ? franquiaid : null;
      this.clienteid = clienteid ? clienteid : null;
      this.safraid = safraid ? safraid : null;
      this.fazendaid = fazendaid ? fazendaid : null;
      this.fazendaid_list = [];

      this.primeiro_ano = null;
      this.segundo_ano = null;

      this.primeiro_mes = 7;
      this.ultimo_mes = 6;

      this.moeda = 0;
      this.casa_decimal_moeda = 2;
      this.tipo_peso = 0;
      this.diagnostico = null;

      this.is_siga = null;
      this.acesso_cadastro_fornecedor = false;
      this.acesso_bem_estar_animal = false;

      this.nome_fazenda = null;
   }

   setNomeFazenda(nome_fazenda) {
      this.nome_fazenda = nome_fazenda;
   }

   setDiagnostico(diagnostico) {
      this.diagnostico = diagnostico;
   }

   setFazendaIdList(fazendaid_list) {
      this.fazendaid_list = fazendaid_list;
      this.fazendaid = _.first(fazendaid_list);
   }

   setFranquiaId(franquiaid) {
      this.franquiaid = franquiaid;
   }

   setClienteId(clienteid) {
      this.clienteid = clienteid;
   }

   setSafraId(safraid) {
      this.safraid = safraid;
   }

   setFazendaId(fazendaid) {
      this.fazendaid = fazendaid;
   }

   setCasaDecimalMoeda(casa_decimal_moeda) {
      this.casa_decimal_moeda = casa_decimal_moeda;
   }

   setMoeda(moeda) {
      this.moeda = moeda;
   }

   setTipoPeso(tipo_peso) {
      this.tipo_peso = tipo_peso;
   }

   setIsSiga(is_siga) {
      this.is_siga = is_siga;
   }

   setAnoSafra(array) {
      [this.primeiro_ano, this.segundo_ano] = array;
   }

   setAcessoCadastroFornecedor(acesso_cadastro_fornecedor) {
      this.acesso_cadastro_fornecedor = acesso_cadastro_fornecedor;

      if(isNullOrUndefined(this.acesso_cadastro_fornecedor)){
         this.acesso_cadastro_fornecedor=false;
      }
   }

   setAcessoBemEstarAnimal(acesso_bem_estar_animal) {
      this.acesso_bem_estar_animal = acesso_bem_estar_animal;

      if(isNullOrUndefined(this.acesso_bem_estar_animal)){
         this.acesso_bem_estar_animal=false;
      }
   }

   static getParametroQuery(paramData) {
      return {
         clienteid: paramData.clienteid,
         safraid: paramData.safraid,
         fazendaid: paramData.fazendaid
      }
   }

   static getAnoSafra(paramData) {
      return [paramData.primeiro_ano, paramData.segundo_ano];
   }

   static getSafraNome(paramData) {
      return paramData.primeiro_ano + "/" + paramData.segundo_ano;
   }

   static getSafraAnteriorNome(paramData) {
      return (_.parseInt(paramData.primeiro_ano) - 1) + "/" + (_.parseInt(paramData.segundo_ano) - 1);
   }

   static getSafraPosteriorNome(paramData) {
      return (_.parseInt(paramData.primeiro_ano) + 1) + "/" + (_.parseInt(paramData.segundo_ano) + 1);
   }

   static recoverParamDataFromStorage(save_storage_name = 'param_data') {

      let paramData = sessionStorage.getItem(save_storage_name + '_session');
      if (paramData) {
         return JSON.parse(paramData);
      }

      paramData = localStorage.getItem(save_storage_name + '_local');
      if (paramData) {
         return JSON.parse(paramData);
      } else {
         return new ParamData();
      }
   }

   static saveParamDataFromStorage(paramData, save_storage_name = 'param_data') {
      sessionStorage.setItem(save_storage_name + '_session', JSON.stringify(paramData));
      localStorage.setItem(save_storage_name + '_local', JSON.stringify(paramData));
   }
}

export default ParamData;
