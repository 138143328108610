import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isNullOrUndefined } from '@syncfusion/ej2-base'
import { DialogComponent } from '@syncfusion/ej2-react-popups'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'
import NotificaData from '../model/NotificaData'
import _ from 'lodash'
import './Notificacao.scss'
import Util from '../api/Util'
import ParamData from '../model/ParamData'
import {
  getDateFormatter,
  getIntFormatter,
  getFloatFormatter,
  getPercentFormatter,
  getCurrencyFormatter,
  getSymbolCurrency,
} from '../config/LoaderCldr'
//import {  notificarClear } from '../actions';
import informativoImg from '../image/informativo.png'
import warningImg from '../image/warning.png'
import IdiomaData from '../model/IdiomaData'

class Notificacao extends Component {
  /*constructor(props) {
        super(props);
    }*/

  onCloseDialog = () => {
    //this.dialogObj.visible=false;
    //console.log('onCloseDialog')
    // console.log(this.dialogObj.buttons)
  }

  onBeforeCloseDialog = () => {
    //this.dialogObj.visible=false;

    //console.log('onBeforeClose')
    //console.log(this.dialogObj.buttons)

    if (this.dialogObj.buttons && this.dialogObj.buttons.length > 0) {
      this.dialogObj.buttons.forEach(element => {
        if (element.click) {
          window.removeEventListener('click', element.click, false)
        }
      })
      this.dialogObj.buttons = []
    }
  }

  getMessageValidation(objInfo, paramData) {
    const code = objInfo.ERROR_CODE
    let objMessage = { message: '', tipo: null, nivel: null }
    let message = IdiomaData.selectGroupItem(
      this.props.idiomaData,
      'messages',
      code,
    ).text

    //loaderCldr(paramData.moeda);
    const nFormatterInt = getIntFormatter()
    const dFormatterDateMMMY = isNullOrUndefined(paramData)
      ? null
      : getDateFormatter(paramData.moeda, 2)
    const dFormatterDateDMY = isNullOrUndefined(paramData)
      ? null
      : getDateFormatter(paramData.moeda, 0)
    const nFormatterFloat = getFloatFormatter(2)
    const nFormatterCurrency = isNullOrUndefined(paramData)
      ? null
      : getCurrencyFormatter(paramData.casa_decimal_moeda)
    const nFormatterPercente = getPercentFormatter(1)
    const safraNome = isNullOrUndefined(paramData)
      ? null
      : ParamData.getSafraNome(paramData)
    const safraNomePosterior = isNullOrUndefined(paramData)
      ? null
      : ParamData.getSafraPosteriorNome(paramData)
    const currencySymbolList = getSymbolCurrency()
    const currencySymbol = isNullOrUndefined(paramData)
      ? null
      : currencySymbolList[paramData.moeda]
    const nFormatterFloatCurrency = getFloatFormatter(
      paramData.casa_decimal_moeda,
    )
    let date = null

    switch (code) {
      case 11111:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11112:
        message = message.replace(
          ':area_reutilizada_total',
          nFormatterFloat(objInfo.area_reutilizada_total),
        )
        message = message.replace(
          ':media_area_ilp',
          nFormatterFloat(objInfo.area_total_ilp),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11113:
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = message.replace(
          ':precipitacao',
          nFormatterInt(objInfo.precipitacao),
        )
        message = message.replace(':data', dFormatterDateMMMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11114:
        message = message.replace(
          ':precipitacao_acumulada',
          nFormatterInt(objInfo.precipitacao_total),
        )
        message = message.replace(
          ':limite_superior_precipitacao',
          nFormatterInt(2200),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11115:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11116:
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = message.replace(':mes', dFormatterDateMMMY(date))
        message = message.replace(
          ':area_total_mes',
          nFormatterFloat(objInfo.area_total_mes),
        )
        message = message.replace(
          ':area_fazenda',
          nFormatterFloat(objInfo.area_total_fazenda),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11117:
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = Util.replaceAll(message, ':mes', dFormatterDateMMMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11118:
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = message.replace(':mes', dFormatterDateMMMY(date))
        message = message.replace(':dif', nFormatterInt(objInfo.dif))
        message = message.replace(
          ':nome_mes',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'mes',
            objInfo.nome_mes,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11119:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11120:
        message = message.replace(
          ':total_animal_nascido',
          nFormatterInt(objInfo.total_animal_nascido),
        )
        message = message.replace(
          ':total_prenhes',
          nFormatterInt(objInfo.total_prenhes),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11121:
        message = message.replace(
          ':idade',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'idade',
            objInfo.idade,
          ).text,
        )
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = message.replace(':mes', dFormatterDateMMMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11122:
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = message.replace(':mes', dFormatterDateMMMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11123:
        message = message.replace(
          ':salario_com_beneficio',
          nFormatterCurrency(objInfo.salario_com_beneficio),
        )
        message = message.replace(':hpd_l', nFormatterCurrency(objInfo.hpd_l))
        message = message.replace(':hpd_u', nFormatterCurrency(objInfo.hpd_u))
        message = message.replace(
          ':funcao',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'funcao',
            objInfo.funcao,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11124:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11125:
        message = message.replace(
          ':min_cabeca',
          nFormatterInt(objInfo.min_cabeca),
        )
        message = message.replace(
          ':max_cabeca',
          nFormatterInt(objInfo.max_cabeca),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11126:
        const tipoPesoSimbolo = IdiomaData.selectGroupItem(
          this.props.idiomaData,
          'tipo_peso',
          objInfo.tipo_peso,
        ).text
        message = Util.replaceAll(
          message,
          ':tipo_peso_unidade',
          tipoPesoSimbolo,
        )
        message = Util.replaceAll(message, ':moeda', currencySymbol)
        message = Util.replaceAll(
          message,
          ':min_valor_tipo_peso',
          nFormatterFloatCurrency(objInfo.min_valor_tipo_peso),
        )
        message = Util.replaceAll(
          message,
          ':max_valor_tipo_peso',
          nFormatterFloatCurrency(objInfo.max_valor_tipo_peso),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break

      case 11127:
        message = message.replace(':hpd_l', nFormatterFloat(objInfo.hpd_l))
        message = message.replace(':hpd_u', nFormatterFloat(objInfo.hpd_u))
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'desmame',
            objInfo.categoria,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11128:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'desmame',
            objInfo.categoria,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11129:
        message = message.replace(
          ':qtde_toque',
          nFormatterInt(objInfo.qtde_toque),
        )
        message = message.replace(
          ':qtde_monta',
          nFormatterInt(objInfo.qtde_monta),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11130:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_geral',
            objInfo.categoria,
          ).text,
        )
        if (objInfo.categoria === 18) {
          message = message.replace(':symbol_percent', '%')
        }
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l_1))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u_1))
        message = message.replace(':safra', safraNome)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11131:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_geral',
            objInfo.categoria,
          ).text,
        )
        if (objInfo.categoria === 18) {
          message = message.replace(':symbol_percent', '%')
        }
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l_2))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u_2))
        message = message.replace(':safra', safraNomePosterior)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11132:
        message = message.replace(
          ':hpd_l',
          nFormatterPercente(objInfo.hpd_l_1_g),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterPercente(objInfo.hpd_u_1_g),
        )
        message = message.replace(':safra', safraNome)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11133:
        message = message.replace(
          ':hpd_l',
          nFormatterPercente(objInfo.hpd_l_2_g),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterPercente(objInfo.hpd_u_2_g),
        )
        message = message.replace(':safra', safraNomePosterior)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11134:
        message = message.replace(
          ':valor',
          nFormatterPercente(objInfo.quantidade1),
        )
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l_1))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u_1))
        message = message.replace(':safra', safraNome)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11135:
        message = message.replace(
          ':valor',
          nFormatterPercente(objInfo.quantidade2),
        )
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l_2))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u_2))
        message = message.replace(':safra', safraNomePosterior)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11136:
        message = message.replace(
          ':hpd_l',
          nFormatterPercente(objInfo.hpd_l_1_t),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterPercente(objInfo.hpd_u_1_t),
        )
        message = message.replace(':safra', safraNome)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11137:
        message = message.replace(
          ':hpd_l',
          nFormatterPercente(objInfo.hpd_l_2_t),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterPercente(objInfo.hpd_u_2_t),
        )
        message = message.replace(':safra', safraNomePosterior)
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11138:
        message = message.replace(
          ':total_nascimento',
          nFormatterInt(objInfo.total_nascimento),
        )
        message = message.replace(
          ':total_prenhe',
          nFormatterInt(objInfo.total_prenhe),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11139:
        //message=Util.replaceAll(message, ':sexo', objInfo.sexo===0?IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'machos').text:IdiomaData.selectGroupItem(this.props.idiomaData,'messages', 'femeas').text);
        //message=message.replace(':total_desmame',  nFormatterInt(objInfo.total_desmame));
        //message=message.replace(':total_nascimento',  nFormatterInt(objInfo.total_nascimento));
        //date=Util.convertUnixTimeToDate(objInfo.carimbo_nascimento);
        //message=Util.replaceAll(message, ':carimbo_nascimento', dFormatterDateMMMY(date));
        message = message.replace(
          ':numero_mes',
          nFormatterInt(objInfo.numero_mes),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11140:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11141:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11142:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_efetivo_pecuario',
            objInfo.categoria,
          ).text,
        )
        message = message.replace(':hpd_l', nFormatterFloat(objInfo.hpd_l_peso))
        message = message.replace(':hpd_u', nFormatterFloat(objInfo.hpd_u_peso))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11143:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_efetivo_pecuario',
            objInfo.categoria,
          ).text,
        )
        message = message.replace(
          ':hpd_l',
          nFormatterCurrency(objInfo.hpd_l_valor),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterCurrency(objInfo.hpd_u_valor),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11144:
        date = Util.convertUnixTimeToDate(objInfo.data_saida)
        message = message.replace(':data_saida', dFormatterDateDMY(date))
        date = Util.convertUnixTimeToDate(objInfo.data_entrada)
        message = message.replace(':data_entrada', dFormatterDateDMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11145:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11146:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_efetivo_pecuario',
            objInfo.categoria,
          ).text,
        )
        message = message.replace(
          ':peso_saida',
          nFormatterFloat(objInfo.peso_saida),
        )
        message = message.replace(
          ':peso_entrada',
          nFormatterFloat(objInfo.peso_entrada),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11147:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_efetivo_pecuario',
            objInfo.categoria,
          ).text,
        )
        message = message.replace(':hpd_l', nFormatterFloat(objInfo.hpd_l_peso))
        message = message.replace(':hpd_u', nFormatterFloat(objInfo.hpd_u_peso))
        message = message.replace(
          ':peso_saida',
          nFormatterFloat(objInfo.peso_saida),
        )
        message = message.replace(
          ':unidade_peso',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'tipo_peso',
            paramData.tipo_peso,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11148:
        message = message.replace(
          ':valor',
          nFormatterCurrency(objInfo.custo_diaria),
        )
        message = message.replace(
          ':hpd_l',
          nFormatterCurrency(objInfo.hpd_l_custo_diaria),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterCurrency(objInfo.hpd_u_custo_diaria),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11149:
        message = message.replace(
          ':valor_por_producao',
          nFormatterCurrency(objInfo.desembolso_por_producao),
        )
        message = message.replace(
          ':perfil_financeiro_agricultura',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'perfil_financeiro_agricultura',
            objInfo.perfil_financeiro_agricultura,
          ).text,
        )
        message = message.replace(
          ':hpd_l',
          nFormatterCurrency(objInfo.hpd_l_valor_por_saca),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterCurrency(objInfo.hpd_u_valor_por_saca),
        )
        message = message.replace(
          ':unidade_producao',
          IdiomaData.selectGroupItem(this.props.idiomaData, 'unidade', 'sacas')
            .text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11150:
        message = message.replace(
          ':categoria',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_efetivo_pecuario',
            objInfo.categoria,
          ).text,
        )
        message = message.replace(
          ':hpd_l',
          nFormatterCurrency(objInfo.hpd_l_valor),
        )
        message = message.replace(
          ':hpd_u',
          nFormatterCurrency(objInfo.hpd_u_valor),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11151:
        message = message.replace(
          ':centro_custo',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'centro_custo_despesa',
            objInfo.centro_custo,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11152:
        message = message.replace(
          ':qtde_nascimento',
          nFormatterInt(objInfo.total_nascimento),
        )
        message = message.replace(
          ':qtde_desmame',
          nFormatterInt(objInfo.total_desmame),
        )
        message = Util.replaceAll(
          message,
          ':sexo',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'sexo',
            objInfo.sexo,
          ).text,
        )
        date = Util.convertUnixTimeToDate(objInfo.data)
        message = Util.replaceAll(message, ':mes_ano', dFormatterDateMMMY(date))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.INFORMATIVO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11153:
        message = message.replace(
          ':qtde_matrizes_prenhes',
          nFormatterInt(objInfo.qtde_matrizes_prenhes),
        )
        message = message.replace(
          ':qtde_reprod_nascimento',
          nFormatterInt(objInfo.qtde_reprod_nascimento),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.INFORMATIVO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11154:
        message = message.replace(
          ':qtde_matrizes_prenhes',
          nFormatterInt(objInfo.qtde_matrizes_prenhes),
        )
        message = message.replace(
          ':qtde_nascimento',
          nFormatterInt(objInfo.qtde_nascimento),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.INFORMATIVO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11155:
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11156:
        message = message.replace(
          ':safra_anterior',
          ParamData.getSafraAnteriorNome(paramData),
        )
        message = message.replace(':hpd_l', nFormatterPercente(objInfo.hpd_l))
        message = message.replace(':hpd_u', nFormatterPercente(objInfo.hpd_u))
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11157:
        message = message.replace(
          ':qtde_toque',
          nFormatterInt(objInfo.qtde_toque),
        )
        message = message.replace(
          ':qtde_monta',
          nFormatterInt(objInfo.qtde_monta),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11158:
        message = message.replace(
          ':qtde_vaca_chance',
          nFormatterInt(objInfo.qtde_vaca_chance),
        )
        message = message.replace(
          ':qtde_vaca',
          nFormatterInt(objInfo.qtde_vaca),
        )
        message = message.replace(
          ':categoria_vaca_chance',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_geral',
            objInfo.categoria_vaca_chance,
          ).text,
        )
        message = message.replace(
          ':categoria_vaca',
          IdiomaData.selectGroupItem(
            this.props.idiomaData,
            'categoria_geral',
            objInfo.categoria_vaca,
          ).text,
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11159:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 11160:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 11161:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.AVISO,
          nivel: NotificaData.NIVEL_MESSAGE.DESCISAO,
        }
        break
      case 81111:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 81112:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 81113:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 91111:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 91112:
        message = message.replace(
          ':qtdefazenda',
          nFormatterInt(objInfo.qtde_fazendas),
        )
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break
      case 91113:
        objMessage = {
          message: message,
          tipo: NotificaData.TIPO_MESSAGE.ERRO,
          nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
        }
        break

      default:
        if (isNullOrUndefined(message)) {
          objMessage = {
            message: IdiomaData.selectGroupItem(
              this.props.idiomaData,
              'messages',
              'erro_indefinido',
            ).text,
            tipo: NotificaData.TIPO_MESSAGE.AVISO,
            nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
          }
        } else {
          objMessage = {
            message: message,
            tipo: NotificaData.TIPO_MESSAGE.AVISO,
            nivel: NotificaData.NIVEL_MESSAGE.INFORMATIVO,
          }
        }
        break
    }

    return objMessage
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isNullOrUndefined(nextProps.idiomaData) &&
      !isNullOrUndefined(this.props.idiomaData) &&
      !isNullOrUndefined(nextProps.idiomaData.langSelected) &&
      !isNullOrUndefined(this.props.idiomaData.langSelected) &&
      nextProps.idiomaData.langSelected !== this.props.idiomaData.langSelected
    ) {
      return false
    }

    return true
  }

  componentDidUpdate() {
    const notificaData = this.props.notificaData

    if (
      !isNullOrUndefined(notificaData) &&
      !isNullOrUndefined(notificaData.tipo)
    ) {
      if (notificaData.tipo === NotificaData.TIPO_OBJ.DIALOG) {
        //width='250px'
        //content='This is a Dialog with content'
        const data = notificaData?.data

        //console.log('NOTIFICA ERROR DATA')
        //console.log(data)

        if (!isNullOrUndefined(data)) {
          let response = ''
          let subString = ''

          if (
            Array.isArray(data) &&
            data[0] &&
            data[0].error &&
            data[0].error.response
          ) {
            response = JSON.parse(data[0].error.response)
            const str = response.message.trim()

            subString = ''
            if (str.indexOf('DETAIL:') > 0) {
              subString = str.substring(
                str.indexOf('DETAIL:') + 7,
                str.lastIndexOf('CONTEXT:'),
              )
            }

            if (subString === '') {
              subString = str
            }
          } else if (!isNullOrUndefined(data.message)) {
            subString = '{' + data.message + '}'
          } else if (
            typeof data === 'object' &&
            !isNullOrUndefined(data.error) &&
            !isNullOrUndefined(data.error[0]) &&
            !isNullOrUndefined(data.error[0].error)
          ) {
            response = JSON.parse(data.error[0].error.response)
            const str = response.message.trim()

            subString = ''
            if (str.indexOf('DETAIL:') > 0) {
              subString = str.substring(
                str.indexOf('DETAIL:') + 7,
                str.lastIndexOf('CONTEXT:'),
              )
            }

            if (subString === '') {
              subString = str
            }
          } else {
            response = { message: '' }
            //console.log('NOTIFICACOAO:')
            //console.log(data)
            // alert(data)
            return
          }

          subString = _.replace(subString, '{', '[')
          subString = _.replace(subString, /}([^}]*)$/, ']$1')
          const objErrorInfo = Util.convertToJson(subString)

          let rowsMessage = ''
          let nivelMessage = 0

          if (Array.isArray(objErrorInfo)) {
            objErrorInfo.forEach(element => {
              let obj = {}
              obj = Util.convertToJson(element)

              const objMessage = this.getMessageValidation(
                obj,
                notificaData.paramData,
              )
              rowsMessage =
                rowsMessage +
                '<tr><td valign="center"><span class="statustxt e-inactivecolor"></span></td><td style="font-size: 19px;">' +
                objMessage.message +
                '</td></tr>'
              nivelMessage = _.max([nivelMessage, objMessage.nivel])
            })
          } else {
            rowsMessage =
              '<tr><td valign="center"><span class="statustxt e-inactivecolor"></span></td><td style="font-size: 19px;">' +
              subString +
              '</td></tr>'
            nivelMessage = NotificaData.NIVEL_MESSAGE.INFORMATIVO
          }

          let imgIcon = ''
          if (nivelMessage === 0) {
            imgIcon = `<img src=${informativoImg} style="width:50px;height:50px;"/>`
          } else if (nivelMessage === 1) {
            imgIcon = `<img src=${warningImg} style="margin:0;width:50px;height:50px;"/>`
          }

          this.dialogObj.content = `<div style="height:100%;width:100%;">
                                        <div style='float:left;position:relative;top:10%;margin:0;'>${imgIcon}</div>
                                        <div style="float:left">
                                        <table width="450px" style="margin-left:5px;float:top;">
                                         ${rowsMessage}
                                        </div></div></table>`
          if (nivelMessage === 0) {
            if (!isNullOrUndefined(notificaData.buttomModel[0])) {
              const callbackClicksim = notificaData.buttomModel[0][0].click
              notificaData.buttomModel[0][0].click = function (args) {
                callbackClicksim(args)
                this.dialogObj.visible = false
                //this.props.notificarClear();
              }.bind(this)
            }

            if (!isNullOrUndefined(notificaData.buttomModel[0])) {
              const callbackClicknao = notificaData.buttomModel[0][1].click
              notificaData.buttomModel[0][1].click = function (args) {
                callbackClicknao(args)
                this.dialogObj.visible = false
                //this.props.notificarClear();
              }.bind(this)
            }
          } else if (nivelMessage === 1) {
            if (!isNullOrUndefined(notificaData.buttomModel[1][0])) {
              const callbackClickOk = notificaData.buttomModel[1][0].click
              notificaData.buttomModel[1][0].click = function (args) {
                callbackClickOk(args)
                this.dialogObj.visible = false
                //this.props.notificarClear();
              }.bind(this)
            }
          } else {
            notificaData.buttomModel[1] = Util.createModelButtonForDialogOk(
              () => {
                this.dialogObj.visible = false
              },
            )
          }

          this.dialogObj.buttons = notificaData.buttomModel[nivelMessage]
          this.dialogObj.visible = true
        }
      } else if (notificaData.tipo === NotificaData.TIPO_OBJ.TOAST) {
        const msg = notificaData.data
        const tipo_message = notificaData.tipo_message
        const position = notificaData.position
        const toasts = [
          {
            title: IdiomaData.selectGroupItem(
              this.props.idiomaData,
              'messages',
              'title_aviso',
            ).text,
            content: msg,
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
          },
          {
            title: IdiomaData.selectGroupItem(
              this.props.idiomaData,
              'messages',
              'title_sucesso',
            ).text,
            content: msg,
            cssClass: 'e-toast-success',
            icon: 'e-success toast-icons',
          },
          {
            title: IdiomaData.selectGroupItem(
              this.props.idiomaData,
              'messages',
              'title_erro',
            ).text,
            content: msg,
            cssClass: 'e-toast-danger',
            icon: 'e-error toast-icons',
          },
          {
            title: IdiomaData.selectGroupItem(
              this.props.idiomaData,
              'messages',
              'title_informativo',
            ).text,
            content: msg,
            cssClass: 'e-toast-info',
            icon: 'e-info toast-icons',
          },
        ]

        this.toastObj.position.X = position.X
        this.toastObj.position.Y = position.Y
        this.toastObj.show(toasts[tipo_message])
      }
    }
  }

  render() {
    return (
      <>
        <ToastComponent
          ref={toast => {
            this.toastObj = toast
          }}
          position={{ X: 'Right', Y: 'Top' }}
        />
        <DialogComponent
          ref={dl => (this.dialogObj = dl)}
          target='#root'
          content=''
          visible={false}
          //close={this.onCloseDialog}
          beforeClose={this.onBeforeCloseDialog}
          isModal={true}
          showCloseIcon={true}
          height={'auto'}
          width={'600px'}
          animationSettings={{ effect: 'None' }}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return { notificaData: state.notificaData, idiomaData: state.idiomaData }
}

export default connect(mapStateToProps, {})(Notificacao)
