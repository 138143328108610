import React, { Component } from 'react';
import { notificar } from "../../actions";
import { connect} from "react-redux";
import './NotificacaoDialog.scss';
import axios from "axios";
import  "../Loading.scss";
import { Link } from "react-router-dom";
import {

    NOTIFICACAO_URLS
} from './NotificacaoInterfaces';
import IdiomaData from "../../model/IdiomaData";
import NotificaData from '../../model/NotificaData';

const URL_BASE = process.env.REACT_APP_URL_BASE_API;
class NotificacaoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificacoes: [],
            tipoUser: JSON.parse(localStorage.getItem('USER_DATA_SESSION_BKM')).user_info.user.tipo || this.props.userData.user_info.user.tipo
        };
    }

    componentDidMount() {
       
        console.log(this.props.dialogNotifications)
    }

    createNotification = async () => {
        const param_data_local = JSON.parse(localStorage.getItem('param_data_local'));

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${this.props.userData.access_token}`,
        }

        let params = {
            cli_codigo: param_data_local.clienteid || this.props.paramData.clienteid,
            fazenda_id: param_data_local.fazendaid || this.props.paramData.fazendaid,
            safra_id:  param_data_local.safraid || this.props.paramData.safraid,
        }

        try {
            await axios.get(`${URL_BASE}/notificacoes/creates`, {params, headers})
        } catch (error) {
            this.props.notificar(NotificaData.createToastNotifica('não add', NotificaData.TIPO_MESSAGE.ERRO));
            console.log(error)
        }
    }

    markAsSeen(notificacao_id){
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${this.props.userData.access_token}`,
        }

        let params = {
            notificacao_id: notificacao_id,
        }

        try {
           axios.get(`${URL_BASE}/notificacoes/update`, {params, headers})
        } catch (error) {
            console.log(error)
        }
       
        this.props.returnLooksForNotifications()
    }

    render() {
        return (
            <div className="nd-container">
                <div className="nd-header">
                    <h4 className="nd-title">{IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes', 'notificacoes').text}</h4>
                    <div>
                        {/*USUÁRIO TÉCNICO MOSTRA 'VER TODAS'*/}
                        {this.state.tipoUser === 'U' ?
                            <div className="nd-ver-todas link link-1" onClick={this.props.handleOpenNotificacoesModal} >{IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes', 'ver_todas').text}
                                <span className="material-icons">chevron_right</span>
                            </div>
                             : ''
                        }
                    </div>
                </div>
                { this.props.dialogNotifications?.length > 0 ? 
                    this.props.dialogNotifications.map((item, index) => {
                        return (
                            <div key={index}>
                                <hr className="nd-br"/>
                                <Link to={NOTIFICACAO_URLS[item.operacao_id.toString().padStart(6, '0')]} onClick={()=>this.markAsSeen(item.id)}>
                                    <div className="nb-notificacao-card">
                                        <div className="nb-tipo-info">
                                            {IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes', item.tipo).text +
                                            ' - ' + IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes', item.modulo).text +
                                            ' - ' + IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes', item.operacao).text}
                                        </div>
                                        <div className="nb-mensagem">
                                            {IdiomaData.selectGroupItem(this.props.idiomaData, 'notificacoes_messages', item.mensagem_id).text}
                                        </div>
                                        <div className="nb-tempo">{item.date}  {item.visto ? '' : <span className="nb-badge"> </span> }</div>
                                    </div>
                                </Link>
                            </div>
                        )
                    }) :
                    <>
                        <hr className="nd-br"/>
                        <div className="nb-sem-notificacoes">{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'nao_ha_notificacoes').text}</div>
                    </>
                }
                   
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { paramData: state.paramData, idiomaData: state.idiomaData, userData: state.auth.userData };
};

export default connect(mapStateToProps, { notificar })(NotificacaoDialog);
