import React from 'react';
import Header from './Header';
import LayoutMenuLeft from './LayoutMenuLeft';
import {notificar} from "../../actions";
import { connect } from "react-redux";
import { Outlet, useLocation } from 'react-router-dom';
import SidebarRightFilterLancamento from '../lancamento/SidebarRightFilterLancamento';
import SidebarRightFilterCaracterizaFazenda from '../lancamento/SidebarRightFilterCaracterizaFazenda';
import { Suspense } from 'react';
import Loading from '../Loading';

const PageLayout = (props) => {
    const {pathname} = useLocation()
    const splitedPathname = pathname
        .split("/")
        .filter((it) => it.trim() !== "")
    const isLancamento = splitedPathname.length ? splitedPathname[0] === "lancamento" : false

    const switchLancamentoFilter = () => {
        switch (pathname) {
            case  "/lancamento/fazenda":
                return <SidebarRightFilterCaracterizaFazenda />
            default:
                return <SidebarRightFilterLancamento />;
        }
    }

    const switchFilter = () => {
        const firstRoute = splitedPathname[0]
        switch (firstRoute) {
            case "lancamento":
                return switchLancamentoFilter()
            default:
                return <></>;
        }
    }

    return (
            <LayoutMenuLeft>
                <Header isLancamento={isLancamento}>
                    {switchFilter()}
                    <Suspense maxDuration={5000} fallback={<Loading />}>
                        <Outlet />
                    </Suspense>
                </Header>
            </LayoutMenuLeft>
        );
}

const mapStateToProps = state => {
    return { paramData: state.paramData, userData: state.auth.userData };
};

export default connect(mapStateToProps, {notificar})(PageLayout);
