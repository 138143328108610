import * as TYPE from '../actions/types';
import IdiomaData from '../model/IdiomaData';

const INITIAL_STATE = new IdiomaData();

const changeIdiomaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TYPE.CHANGE_IDIOMA:
        return { ...state,  ...action.payload };
        default:
        return state;
    }
};

export default changeIdiomaReducer