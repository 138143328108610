import * as TYPE from '../actions/types';
import ParamData from '../model/ParamData';

const INITIAL_STATE =  new ParamData();  

const changeFilterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPE.CHANGE_FILTER:
      return { ...state,  ...action.payload };
    default:
      return state;
  }
};

export default changeFilterReducer;