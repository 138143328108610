import * as TYPE from '../actions/types';
import NotificaData from '../model/NotificaData';

const INITIAL_STATE =  new NotificaData();

const notificarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case TYPE.NOTIFICAR:
        return { ...state, ...action.payload };
      default:
        return state;
    }
};

export default notificarReducer;