import React, {Component, Fragment, lazy } from 'react';
import ClearCache from "react-clear-cache";
import * as ROUTE from '../config/route';
import {connect} from 'react-redux';
import Idioma from './Idioma';
import ToggleTheme from './template/theme/ToggleTheme';
import Notificacao from './Notificacao';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../component/auth/PrivateRoute';
import { enableRipple } from '@syncfusion/ej2-base';
import { notificar, ativarLoading } from '../actions';
import { fetchMetaVersion, emptyCacheStorageVersion } from '../version/Version';
import { BlipChat } from "blip-chat-widget";
import './App.scss';
import PageLayout from './template/PageLayout';

enableRipple(true);

const Home = lazy(() => import('./Home'));
const Login = lazy(() => import("./auth/Login"));

const HomeLancamento = lazy(() => import("./lancamento/Home"));
const Clima = lazy(() => import("./lancamento/clima/Clima"));

const MenuEstoqueInsumo = lazy(() => import("./lancamento/estoque/MenuEstoqueInsumo"));
const EstoqueInsumoPecuario = lazy(() => import("./lancamento/estoque/EstoqueInsumoPecuario"));
const EstoqueInsumoAgricola = lazy(() => import("./lancamento/estoque/EstoqueInsumoAgricola"));

const MenuAgricola = lazy(() => import("./lancamento/agricola/MenuAgricola"));
const AgriculturaFuncionario = lazy(() => import("./lancamento/agricola/AgriculturaFuncionario"));
const AgriculturaPerfilFinanceiro = lazy(() => import("./lancamento/agricola/AgriculturaPerfilFinanceiro"));
const AgriculturaPerfilFinanceiroCentroCusto = lazy(() => import("./lancamento/agricola/AgriculturaPerfilFinanceiroCentroCusto"));
const AgriculturaRelacaoMaquina = lazy(() => import("./lancamento/agricola/AgriculturaRelacaoMaquina"));
const ProducaoVolumosoControleDespesa = lazy(() => import("./lancamento/agricola/ProducaoVolumosoControleDespesa"));
const ProducaoVolumosoControleProducao = lazy(() => import("./lancamento/agricola/ProducaoVolumosoControleProducao"));

const MenuChecklist = lazy(() => import("./checklist/MenuChecklist"));
const Analitico = lazy(() => import("./checklist/Analitico"));
const Sintetico = lazy(() => import("./checklist/Sintetico"));

const MenuExportacao = lazy(() => import("./exportacao/MenuExportacao"));
const ExportacaoFazendaExcel = lazy(() => import("./exportacao/ExportacaoFazendaExcel"));
const ExportacaoRefatoracao = lazy(() => import("./exportacao/Refatoracao"));

const MenuConfiguracao = lazy(() => import("./configuracao/MenuConfiguracao"));
const DistribuicaoArea = lazy(() => import("./configuracao/DistribuicaoArea"));

const OutroSetor = lazy(() => import("./configuracao/OutroSetor"));
const CotacaoPeso = lazy(() => import("./configuracao/cotacao_peso/CotacaoPeso"));
const CotacaoCultura = lazy(() => import("./configuracao/cotacao_cultura/CotacaoCultura"));
const PonderacaoSobreAnalise = lazy(() => import("./configuracao/PonderacaoSobreAnalise"));
const AnaliseCaixa = lazy(() => import("./configuracao/AnaliseCaixa"));

const DeparaCentroCustoSiga = lazy(() => import("./configuracao/DeparaCentroCustoSiga"));
const ImportacaoFazendaSiga = lazy(() => import("./configuracao/ImportacaoFazendaSiga"));
const FechamentoCompetencia = lazy(() => import("./configuracao/FechamentoCompetencia"));
const MoedaCotacao = lazy(() => import("./configuracao/MoedaCotacao"));
const DesabilitarValidacao = lazy(() => import("./configuracao/DesabilitarValidacao"));
const HistoricoImportacao = lazy(() => import("./configuracao/HistoricoImportacao"));

const AnaliseDescritiva = lazy(() => import("./configuracao/estatistica/AnaliseDescritiva"));
const AnaliseFazenda = lazy(() => import("./configuracao/estatistica/AnaliseFazenda"));
const ArvoreDescisao = lazy(() => import("./configuracao/estatistica/ArvoreDescisao"));
const Calculadora = lazy(() => import("./configuracao/estatistica/Calculadora"));
const ReguaReferencia = lazy(() => import("./configuracao/estatistica/ReguaReferencia"));

const MenuMeta = lazy(() => import("./configuracao/meta/MenuMeta"));
const Placar = lazy(() => import("./configuracao/meta/Placar"));
const Reportagem = lazy(() => import("./configuracao/meta/Reportagem"));

const MenuProcessamento = lazy(() => import("./processamento/MenuProcessamento"));
const ReportagemProcessado = lazy(() => import("./processamento/Reportagem"));
//const EnviarConsolidacao = lazy(() => import("./processamento/EnviarConsolidacao"));

const PainelConsulta = lazy(() => import("./dashboard/DashboardNew"));

const MenuAcompanhamento = lazy(() => import("./acompanhamento_cliente/MenuAcompanhamento"));
const AcompanhamentoCliente = lazy(() => import("./acompanhamento_cliente/AcompanhamentoCliente"));
const MapaCultural = lazy(() => import("./acompanhamento_cliente/MapaCultural"));
const PerfilDisc = lazy(() => import("./acompanhamento_cliente/PerfilDisc"));

const PerguntaFrequente = lazy(() => import("./suporte/PerguntaFrequente"));

const Suporte = lazy(() => import("./suporte/Suporte"));

const MenuAdmin = lazy(() => import("./admin/MenuAdmin"));
const PerfilDesembolsoPecuario = lazy(() => import("./admin/PerfilDesembolsoPecuario"));
const ReferenciaEstatisticas = lazy(() => import("./admin/ReferenciaEstatisticas"));
const ConsolidadoProcessamento = lazy(() => import("./processamento/Consolidado"));
const ConsolidadoIndicador = lazy(() => import("./admin/ConsolidadoIndicador"));
const ReferenciaSafra = lazy(() => import("./admin/ReferenciaSafra"));

const DeparaCentroCustoSigaDefault = lazy(() => import("./admin/DeparaCentroCustoSigaDefault"));
const DesabilitarCategoria = lazy(() => import("./admin/DesabilitarCategoria"));
const DeParaCategoria = lazy(() => import("./admin/DeParaCategoria"));
const CotacaoMoedaGeral = lazy(() => import("./admin/CotacaoMoedaGeral"));
const ListFazenda = lazy(() => import("./admin/ListFazenda"));
const AtualizarCadastroFazenda = lazy(() => import("./admin/AtualizarCadastroFazenda"));
const ListPontoChecagem = lazy(() => import("./admin/ListPontoChecagem"));

const MenuMaquina = lazy(() => import("./lancamento/maquina/MenuMaquina"));
const Implemento = lazy(() => import("./lancamento/maquina/Implemento"));
const Maquina = lazy(() => import("./lancamento/maquina/Maquina"));
const MaquinaParque = lazy(() => import("./lancamento/maquina/MaquinaParque"));

const MenuPecuaria = lazy(() => import("./lancamento/pecuaria/MenuPecuaria"));
const Nascimento = lazy(() => import("./lancamento/pecuaria/Nascimento"));
const Desmame = lazy(() => import("./lancamento/pecuaria/Desmame"));
const Morte = lazy(() => import("./lancamento/pecuaria/Morte"));

const Confinamento = lazy(() => import("./lancamento/pecuaria/Confinamento"));
const TipSemiConfinamento = lazy(() => import("./lancamento/pecuaria/TipSemiConfinamento"));
const RecriaNoCochoConfinamento = lazy(() => import("./lancamento/pecuaria/RecriaNoCochoConfinamento"));

const MovimentacaoEntrada = lazy(() => import("./lancamento/pecuaria/MovimentacaoEntrada"));
const MovimentacaoSaida = lazy(() => import("./lancamento/pecuaria/MovimentacaoSaida"));
const EstoquePecuario = lazy(() => import("./lancamento/pecuaria/EstoquePecuario"));
const Area = lazy(() => import("./lancamento/pecuaria/Area"));
const ReproducaoMontaToque = lazy(() => import("./lancamento/pecuaria/ReproducaoMontaToque"));
const ReproducaoNascimentoDesmame = lazy(() => import("./lancamento/pecuaria/ReproducaoNascimentoDesmame"));
const Fornecedores = lazy(() => import("./lancamento/pecuaria/fornecedor/Fornecedores"));
const MediaRebanhoExpresso = lazy(() => import("./lancamento/pecuaria/MediaRebanhoExpresso"));

const MenuFinanceiro = lazy(() => import("./lancamento/financeiro/MenuFinanceiro"));
const Despesa = lazy(() => import("./lancamento/financeiro/Despesa"));
const Receita = lazy(() => import("./lancamento/financeiro/Receita"));
const OutroCredito = lazy(() => import("./lancamento/financeiro/OutroCredito"));
const OutroDebito = lazy(() => import("./lancamento/financeiro/OutroDebito"));
const InsumoRebanhoExpresso = lazy(() => import("./lancamento/financeiro/InsumoRebanhoExpresso"));

const MenuEquipe = lazy(() => import("./lancamento/equipe/MenuEquipe"));
const Equipe = lazy(() => import("./lancamento/equipe/Equipe"));

const Fazenda = lazy(() => import("./lancamento/fazenda/Fazenda"));

const BemEstarAnimal = lazy(() => import("./lancamento/bem_estar/BemEstarAnimal"));

const NoMatchPage = lazy(() => import("./NoMatchPage"));

class App extends Component {

    componentDidMount(){
        this.blipChat=null;
        this.buildBlipChat();

        fetchMetaVersion(this.props);
        setTimeout(() => {
          this.props.ativarLoading({loading:false});
        }, 5000);
    }

    componentDidUpdate(){
      this.buildBlipChat();

      setTimeout(() => {
        this.props.ativarLoading({loading:false});
      }, 5000);
    }

    buildBlipChat = () => {

        if(this.blipChat){
          this.blipChat.destroy();
        }

        this.blipChat = new BlipChat()
            .withCustomStyle("div { padding: 0; }")
            .withAppKey(process.env.REACT_APP_BLIP_CHAT_KEY)
            .withButton({
                "color": "#49A95A",
                "icon": "https://blipmediastore.blob.core.windows.net/public-medias/Media_d6d0a50f-c1c8-498f-b533-431e736931fa"
            })
            .withAccount({
              fullName: this.props.userData ? this.props.userData.user_info.user.nome : 'Não logado',
              email: this.props.userData ? this.props.userData.user_info.user.email : null,
              phoneNumber: this.props.userData ? this.props.userData.user_info.user.telefone : null,
              city: this.props.userData ? this.props.userData.user_info.user.cidade : null,
            })
            .withTarget('blip-chat-container')
            .withCustomCommonUrl('https://chat.blip.ai/')
            .withEventHandler(BlipChat.ENTER_EVENT, function () {
              console.log('BlipChat enter');
            })
            .withEventHandler(BlipChat.LEAVE_EVENT, function () {
              console.log('BlipChat leave');
            })
            .withEventHandler(BlipChat.LOAD_EVENT, function () {
              console.log('BlipChat chat loaded');
            })
            .withEventHandler(BlipChat.CREATE_ACCOUNT_EVENT, function () {
              console.log('BlipChat account created');
            });

        this.blipChat.build();

        document.getElementById('blip-chat-notifications').style.position = 'absolute';
        const moveChat = document.createElement('span');
        moveChat.id = "move-chat";
        moveChat.innerHTML = "&#x2725;";
        const openIframe = document.getElementById("blip-chat-open-iframe");
        openIframe.appendChild(moveChat)

        moveChat.addEventListener('click', (e) => {
            e.stopPropagation();
        })

        moveChat.addEventListener('mousedown', () => {
            this.dragElement(openIframe, true);
            document.querySelector('iframe').style.pointerEvents = 'none';
        })
        moveChat.addEventListener('mouseup', () => {
            this.dragElement(openIframe, false);
            document.querySelector('iframe').style.pointerEvents = 'auto';
        })
    }

    dragElement(elmnt, move) {
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        elmnt.onmousedown = dragMouseDown;

        function dragMouseDown(e) {
            e.preventDefault();
            pos3 = e.clientX;
            pos4 = e.clientY;
            if (move) {
                document.onmouseup = closeDragElement;
                document.onmousemove = elementDrag;
            }
        }

        function elementDrag(e) {
            e.preventDefault();
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    render() {
        return (
          <ClearCache auto={false} duration={900000} basePath={process.env.REACT_APP_URL_BASE_META_VERSION}>
            {({ isLatestVersion, emptyCacheStorage }) => (
                  <Fragment>
                    <Idioma/>
                    <ToggleTheme/>
                    <Notificacao/>
                    <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
                      <Routes>
                        <Route element={<PrivateRoute/>}>
                          <Route path='/' element={<PageLayout/>}>
                            <Route index element={<Home />}/>
                            
                            <Route path='lancamento'>
                              <Route index element={<HomeLancamento />}/>
                              <Route path='clima' element={<Clima/>}/>
                              <Route path='fazenda' element={<Fazenda/>}/>
                              <Route path='bem/estar' element={<BemEstarAnimal/>}/>

                              <Route path='equipe'>
                                <Route index element={<MenuEquipe/>}/>
                                <Route path='equipe' element={<Equipe/>}/>
                              </Route>

                              <Route path='financeiro'>
                                <Route index element={<MenuFinanceiro/>}/>
                                <Route path='receita' element={<Receita/>}/>
                                <Route path='despesa' element={<Despesa/>}/>
                                <Route path='outro/credito' element={<OutroCredito/>}/>
                                <Route path='outro/debito' element={<OutroDebito/>}/>
                                <Route path='insumo/rebanho' element={<InsumoRebanhoExpresso/>}/>
                              </Route>

                              <Route path='pecuaria'>
                                <Route index element={<MenuPecuaria/>}/>
                                <Route path='nascimento' element={<Nascimento/>}/>
                                <Route path='desmame' element={<Desmame/>}/>
                                <Route path='morte' element={<Morte/>}/>
                                <Route path='confinamento' element={<Confinamento/>}/>
                                <Route path='tipsemiconfinamento' element={<TipSemiConfinamento/>}/>
                                <Route path='recriacochoconfinamento' element={<RecriaNoCochoConfinamento/>}/>
                                <Route path='movimento/entrada' element={<MovimentacaoEntrada/>}/>
                                <Route path='movimento/saida' element={<MovimentacaoSaida/>}/>
                                <Route path='estoque' element={<EstoquePecuario/>}/>
                                <Route path='area' element={<Area/>}/>
                                <Route path='reproducao/monta/toque' element={<ReproducaoMontaToque/>}/>
                                <Route path='reproducao/nascimento/desmame' element={<ReproducaoNascimentoDesmame/>}/>
                                <Route path='fornecedor' element={<Fornecedores/>}/>
                                <Route path='rebanho/medio' element={<MediaRebanhoExpresso/>}/>
                              </Route>

                              <Route path='maquina'>
                                <Route index element={<MenuMaquina/>}/>
                                <Route path='implemento' element={<Implemento/>}/>
                                <Route path='maquinas' element={<Maquina/>}/>
                                <Route path='parque/maquina' element={<MaquinaParque/>}/>
                              </Route>

                              <Route path='agricola'>
                                <Route index element={<MenuAgricola/>}/>
                                <Route path='controle/producao' element={<ProducaoVolumosoControleProducao/>}/>
                                <Route path='controle/despesa' element={<ProducaoVolumosoControleDespesa/>}/>
                                <Route path='perfil/financeiro' element={<AgriculturaPerfilFinanceiro/>}/>
                                <Route path='perfil/financeiro/centro/custo' element={<AgriculturaPerfilFinanceiroCentroCusto/>}/>
                                <Route path='relacao/maquina' element={<AgriculturaRelacaoMaquina/>}/>
                              </Route>

                              <Route path='estoque'>
                                <Route index element={<MenuEstoqueInsumo/>}/>
                                <Route path='insumo/pecuario' element={<EstoqueInsumoPecuario/>}/>
                                <Route path='insumo/agricola' element={<EstoqueInsumoAgricola/>}/>
                              </Route>
                            </Route>

                            <Route path='/checklist'>
                              <Route index element={<MenuChecklist />} />
                              <Route path='analitico' element={<Analitico/>}/>
                              <Route path='sintetico' element={<Sintetico/>}/>
                            </Route>

                            <Route path='/exportacao'>
                              <Route index element={<MenuExportacao />} />
                              <Route path='fazenda' element={<ExportacaoFazendaExcel/>}/>
                              <Route path='refatoracao' element={<ExportacaoRefatoracao/>}/>
                            </Route>

                            <Route path='/configuracao'>
                              <Route index element={<MenuConfiguracao/>}/>
                              <Route path='distribuicao/area' element={<DistribuicaoArea/>}/>
                              <Route path='outro/setor' element={<OutroSetor/>}/>
                              <Route path='cotacao/peso' element={<CotacaoPeso/>}/>
                              <Route path='cotacao/cultura' element={<CotacaoCultura/>}/>
                              <Route path='ponderacao/analise' element={<PonderacaoSobreAnalise/>}/>
                              <Route path='analise/caixa' element={<AnaliseCaixa/>}/>
                              <Route path='depara/centro/custo/siga' element={<DeparaCentroCustoSiga/>}/>
                              <Route path='importacao/fazenda/siga' element={<ImportacaoFazendaSiga/>}/>
                              <Route path='fechamento/competencia' element={<FechamentoCompetencia/>}/>
                              <Route path='cotacao/moeda' element={<MoedaCotacao/>}/>
                              <Route path='estatistica/analise/descritiva' element={<AnaliseDescritiva/>}/>
                              <Route path='estatistica/analise/fazenda' element={<AnaliseFazenda/>}/>
                              <Route path='estatistica/arvore/descisao' element={<ArvoreDescisao/>}/>
                              <Route path='estatistica/calculadora' element={<Calculadora/>}/>
                              <Route path='estatistica/regua/referencia' element={<ReguaReferencia/>}/>
                              <Route path='validacao' element={<DesabilitarValidacao/>}/>
                              <Route path='historico/importacao' element={<HistoricoImportacao/>}/>

                              <Route path='metas'>
                                <Route index element={<MenuMeta/>}/>
                                <Route path='placar' element={<Placar/>}/>
                                <Route path='reportagem' element={<Reportagem/>}/>
                              </Route>
                            </Route>

                            <Route path='/processamento'>
                              <Route index element={<MenuProcessamento/>}/>
                              <Route path='reportagem' element={<ReportagemProcessado/>}/>
                            </Route>
                            
                            <Route path='/dashboard' element={<PainelConsulta/>}/>
                            
                            <Route path='/perfil/fazenda'>
                              <Route index element={<MenuAcompanhamento/>}/>
                              <Route path='acompanhamento' element={<AcompanhamentoCliente/>}/>
                              <Route path='mapa/cultural' element={<MapaCultural/>}/>
                              <Route path='perfil/disc' element={<PerfilDisc/>}/>
                            </Route>
                            
                            <Route path='/suporte' element={<Suporte/>}/>

                            <Route path='/pergunta/frequente' element={<PerguntaFrequente/>}/>

                            <Route path='/admin'>
                              <Route index element={<MenuAdmin/>}/>
                              <Route path='perfil/desembolso/pecuario' element={<PerfilDesembolsoPecuario/>}/>
                              <Route path='referencia/indicador' element={<ReferenciaEstatisticas/>}/>
                              <Route path='processamento/consolidado' element={<ConsolidadoProcessamento/>}/>
                              <Route path='indicador/consolidado' element={<ConsolidadoIndicador/>}/>
                              <Route path='depara/centro/custo/default' element={<DeparaCentroCustoSigaDefault/>}/>
                              <Route path='desabilita/categoria' element={<DesabilitarCategoria/>}/>
                              <Route path='depara/categoria' element={<DeParaCategoria/>}/>
                              <Route path='cotacao/moeda/geral' element={<CotacaoMoedaGeral/>}/>
                              <Route path='list/fazenda' element={<ListFazenda/>}/>
                              <Route path='atualizar' element={<AtualizarCadastroFazenda/>}/>
                              <Route path='ponto/checagem' element={<ListPontoChecagem/>}/>
                              <Route path='referencia/safra' element={<ReferenciaSafra/>}/>
                            </Route>
                            
                            <Route  path='/lancamento/agricola/funcionario' element={<AgriculturaFuncionario/>}/>
                          </Route>
                        </Route>
                        <Route exact path={ROUTE.LOGIN} element={<Login/>}/>
                        <Route path="*" element={<NoMatchPage/>}/>
                      </Routes>
                      <div style={{position:"fixed", left: "130px", top: "97%", zIndex:10005}}>
                        <div style={{cursor: "pointer"}}>
                            {!isLatestVersion && (
                                <div className="shine-version" onClick={e => {
                                    e.preventDefault();
                                    emptyCacheStorageVersion(false);
                                    emptyCacheStorage();
                                  }}
                                >
                                  <div id='new-version-update'>Click update: {process.env.REACT_APP_VERSION_APP}</div>
                                </div>
                            )}
                          </div>
                          {process.env.REACT_APP_VIEW_VERSION_TRANSPILER} {process.env.REACT_APP_VERSION_APP}
                      </div>
                    </Router>
                  </Fragment>
            )}
        </ClearCache>
        );
  }
}

const mapStateToProps = state => {
    return {userData: state.auth.userData};
};

export default connect( mapStateToProps, {notificar, ativarLoading})(App);
