import React, { useState, useEffect } from 'react';
import  { URL_BASE } from '../../api/managerData';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { getFloatFormatter } from '../../config/LoaderCldr';
import './NotaPreenchimento.scss';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import IdiomaData from "../../model/IdiomaData";

const NotaPreenchimento = (props) => {

    const formatterFloat = getFloatFormatter(2);

    const [load, setLoad] = useState(false);
    const [nota_financeiro, setNotaFinanceiro] = useState('');
    const [nota_produtivo, setNotaProdutivo] = useState('');
    const [nota_estoque, setNotaEstoque] = useState('');
    const [nota_clima, setNotaClima] = useState('');
    //const [nota_agrupada, setNotaAgrupada] = useState(0);
    const [nota_geral, setNotaGeral] = useState(0);


    function validarNotasMovimentacao(nota, value, is_estoque = false) {
        const valid = <span className="material-icons text-success">done</span>;
        const invalid = <span className="material-icons text-danger">close</span>;

        //if (is_estoque && !value) return ('------');

        if (nota !== '' && value) {
            return (valid);
        } else {
            return (invalid);
        }
    }

    function validarNotasValidacao(existe_movimentacao, value, is_estoque = false) {
        const valid = <span className="material-icons text-success">done</span>;
        const invalid = <span className="material-icons text-danger">close</span>;

        //if (is_estoque && !existe_movimentacao) return ('------');

        //if (existe_movimentacao) {
            if (value) {
                return (valid)
            } else {
                return (invalid)
            }
        /*} else {
            return (invalid);
        }*/
    }

    useEffect(() => {
        async function getNotas() {

            setLoad(true);

            let payload = {
                safra: props.paramData.safraid,
                registro: props.paramData.fazendaid,
                tipo_registro: 1,
            };

            let total = 0;

            let headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${props.userData.access_token}`,
            }

            try {
                const { data } = await axios.post(`${URL_BASE}/dashboard/notas`, payload, { "headers": headers });

                setNotaFinanceiro(data.financeiro_nota);
                setNotaProdutivo(data.produtivo_nota);
                setNotaEstoque(data.estoque_nota);
                setNotaClima(data.clima_nota);

                Object.keys(data).forEach((item) => {
                    total += parseFloat(isNullOrUndefined(data[item].nota) ? 0 : data[item].nota);
                });

                setNotaGeral(parseFloat(total).toFixed(2));
                setLoad(false);

            } catch (error) {
                console.log(error);
            }
        }

        if (props.showNotaPreenchimento) {
            getNotas();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showNotaPreenchimento, props.paramData.fazendaid, props.paramData.safraid]);

    return (
        <Modal show={props.showNotaPreenchimento} size="lg" onHide={props.handleCloseNotaPreenchimento}>
            <Modal.Header closeButton>
                <Modal.Title>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'notas_preenchimento').text}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-lg-12">
                    <div className="w-100">
                        {load &&
                            <div className="d-flex align-itens-center w-100 justify-content-center">
                                <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" /> <h2 className="ml-2">Carregando</h2>
                            </div>
                        }
                        {!load &&

                            <div className="w-100">
                                <Table bordered hover variant="light" striped className="mt-5">
                                    <thead>
                                        <tr>
                                            <th>Frente</th>
                                            <th className="text-center">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'movimentacao').text}</th>
                                            <th className="text-center">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'validacao_dados').text}</th>
                                            <th className="text-center">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'nota').text}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{`${IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'financeiro').text} (${IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'despesa').text})`}</td>
                                            <td className="text-center">
                                                {!isNullOrUndefined(nota_financeiro) &&
                                                 !isNullOrUndefined(nota_financeiro.check) &&
                                                 !isNullOrUndefined(nota_financeiro.check.registros) &&
                                                 validarNotasMovimentacao(nota_financeiro.nota, nota_financeiro.check.registros)}
                                            </td>
                                            <td className="text-center">
                                                {!isNullOrUndefined(nota_financeiro) &&
                                                 !isNullOrUndefined(nota_financeiro.check) &&
                                                 !isNullOrUndefined(nota_financeiro.check.validados) &&
                                                 validarNotasValidacao(nota_financeiro.nota, nota_financeiro.check.validados)}
                                            </td>
                                            <td className="text-center">
                                                {nota_financeiro.nota &&
                                                    <div>
                                                        {formatterFloat(parseFloat(nota_financeiro.nota))}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'produtivo').text}</td>
                                            <td className="text-center">
                                                {!isNullOrUndefined(nota_produtivo) &&
                                                 !isNullOrUndefined(nota_produtivo.check) &&
                                                 !isNullOrUndefined(nota_produtivo.check.registros) &&
                                                 validarNotasMovimentacao(nota_produtivo.nota, nota_produtivo.check.registros)
                                                }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    !isNullOrUndefined(nota_produtivo) &&
                                                    !isNullOrUndefined(nota_produtivo.check) &&
                                                    !isNullOrUndefined(nota_produtivo.check.validados) &&
                                                    validarNotasValidacao(nota_produtivo.check.registros, nota_produtivo.check.validados)
                                                }
                                            </td>
                                            <td className="text-center">
                                                {nota_produtivo.nota &&
                                                    <div>
                                                        {formatterFloat(parseFloat(nota_produtivo.nota))}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'estoque_insumo_pago').text}</td>
                                            <td className="text-center">
                                                {(!isNullOrUndefined(nota_estoque) &&
                                                  !isNullOrUndefined(nota_estoque.check) &&
                                                  !isNullOrUndefined(nota_estoque.check.registros) &&
                                                  validarNotasMovimentacao(nota_estoque.nota, nota_estoque.check.registros))
                                                }
                                            </td>
                                            <td className="text-center">
                                                {(!isNullOrUndefined(nota_estoque) &&
                                                  !isNullOrUndefined(nota_estoque.check) &&
                                                  !isNullOrUndefined(nota_estoque.check.validados) &&
                                                  validarNotasValidacao(nota_estoque.check.registros, nota_estoque.check.validados)) 
                                                }
                                            </td>
                                            <td className="text-center">
                                                {nota_estoque.nota &&
                                                    <div>
                                                        {formatterFloat(parseFloat(nota_estoque.nota))}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'clima').text}</td>
                                            <td className="text-center">
                                                {!isNullOrUndefined(nota_clima) &&
                                                 !isNullOrUndefined(nota_clima.check) &&
                                                 !isNullOrUndefined(nota_clima.check.registros) &&
                                                 validarNotasMovimentacao(nota_clima.nota, nota_clima.check.registros)}
                                            </td>
                                            <td className="text-center">
                                                {!isNullOrUndefined(nota_clima) &&
                                                 !isNullOrUndefined(nota_clima.check) &&
                                                 !isNullOrUndefined(nota_clima.check.validados) &&
                                                 validarNotasValidacao(nota_clima.check.registros, nota_clima.check.validados)}
                                            </td>
                                            <td className="text-center">
                                                {nota_clima.nota &&
                                                    <div>
                                                        {formatterFloat(parseFloat(nota_clima.nota))}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            {nota_geral <= 6.0 &&
                                                <td colSpan={4} className="text-center bg-danger text-light">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'nota_geral').text}: <div className="nota">{formatterFloat(nota_geral)}</div> </td>
                                            }

                                            {nota_geral > 6.0 && nota_geral <= 7.9 &&
                                                <td colSpan={4} className="text-center bg-warning text-light">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'nota_geral').text}:  <div className="nota">{formatterFloat(nota_geral)}</div> </td>
                                            }

                                            {nota_geral >= 8.0 &&
                                                <td colSpan={4} className="text-center bg-success text-light">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'nota_geral').text}:  <div className="nota">{formatterFloat(nota_geral)}</div> </td>
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="w-100 mt-5">
                                    <ul className="list-unstyled">
                                        <li className="d-flex align-items-center">
                                            <span className="material-icons text-success">
                                                done
                                            </span>
                                            <strong className="pl-2">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'notas_preenchimento_msg_1').text}</strong>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <span className="material-icons text-danger">
                                                close
                                            </span>
                                            <strong className="pl-2">{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'notas_preenchimento_msg_2').text}</strong>
                                        </li>

                                        <li className="d-flex align-items-center">
                                            <span>
                                                ---
                                            </span>
                                            <strong className="pl-2" style={{marginLeft: '0.6rem'}}>{IdiomaData.selectGroupItem(props.idiomaData, 'messages', 'notas_preenchimento_msg_3').text}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleCloseNotaPreenchimento}>
                    Fechar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default NotaPreenchimento;
