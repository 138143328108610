import * as TYPE from '../actions/types';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import _ from 'lodash';

const user = localStorage.getItem('USER_DATA_SESSION_BKM');

const INITIAL_STATE = user ? {
    isSignedIn: true,
    userData: JSON.parse(user)
} : {};

const authReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {

      case TYPE.SIGN_IN:
        return { ...state, isSignedIn: true, userData: action.payload };

      case TYPE.SIGN_OUT:
        return { ...state, isSignedIn: false, userData: null };

      case TYPE.UPDATE_FAZENDA_CARACTERIZADA:

        if(Array.isArray(action.payload)){
          _.forEach(action.payload, (value) => {
            const index = state.userData.info_fazenda.fazenda_caracterizada.findIndex(x =>  x.cli_codigo===value.cli_codigo && 
                                                                                            x.safra_id===value.safra_id && 
                                                                                            x.fazenda_id===value.fazenda_id );
              /*if(index>-1){
                  state.userData.info_fazenda.fazenda_caracterizada.push({ id: value.fazenda_id, 
                    safra_id: value.safra_id, 
                    tipo_peso: value.tipo_peso, 
                    cli_codigo: value.cli_codigo, 
                    casa_decimal_moeda:value.casa_decimal_moeda, 
                    moeda: value.moeda, 
                    nome: _.toUpper(value.nome)  
                  });
              }*/

              if(index>-1){
                state.userData.info_fazenda.fazenda_caracterizada.splice(index, 1);
                
                if(isNullOrUndefined(value.delete) || !value.delete)
                  state.userData.info_fazenda.fazenda_caracterizada.push(value);
              }else{
                if(isNullOrUndefined(value.delete) || !value.delete)
                  state.userData.info_fazenda.fazenda_caracterizada.push(value);
              }
          });
        }else if( typeof action.payload === 'object'){

            const index = state.userData.info_fazenda.fazenda_caracterizada.findIndex(x =>  x.cli_codigo===action.payload.cli_codigo && 
                                                                                            x.safra_id===action.payload.safra_id && 
                                                                                            x.fazenda_id===action.payload.fazenda_id);

            //const index = state.userData.info_fazenda.fazenda_caracterizada.findIndex(x =>  x.id===action.payload.id);
              
            if(index>-1){
              state.userData.info_fazenda.fazenda_caracterizada.splice(index, 1);     

              if(isNullOrUndefined(action.payload.delete) || !action.payload.delete)
                state.userData.info_fazenda.fazenda_caracterizada.push(action.payload);
            }else{
              if(isNullOrUndefined(action.payload.delete) || !action.payload.delete)
                state.userData.info_fazenda.fazenda_caracterizada.push(action.payload);
            }
        }/*else {
          const index = state.userData.info_fazenda.fazenda_caracterizada.findIndex(x=>x.id===action.payload);
          if(index>-1){
            state.userData.info_fazenda.fazenda_caracterizada.splice(index, 1);  
          }
        }*/

        //return { ...state,  userData: state.userData };
        return state;
      default:
        return state;
    }
};

export default authReducer;