import * as TYPE from '../actions/types';
import ListReferenciaData from '../model/ListReferenciaData';

const INITIAL_STATE = new ListReferenciaData();

const updateListReferenciaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case TYPE.UPDATE_LIST_REFERENCIA:
        return { ...state, ...action.payload };
      default:
        return state;
    }
};

export default updateListReferenciaReducer;