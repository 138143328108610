import React, { useState, useEffect } from 'react';
import './ToggleTheme.scss';

const ToggleTheme = (props) => {

    const THEME_DARK='dark';
    const THEME_LIGHT='light';
    const [theme, setTheme] = useState(window.localStorage.getItem("theme") || THEME_LIGHT);

    useEffect(() => {

        //document.querySelector("head").insertAdjacentHTML("beforeend", `<link id="css_dark" rel="stylesheet" type="text/css" href="${process.env.REACT_APP_URL_BASE}/dark.css" />`)
        if(theme===THEME_DARK){
            //document.querySelector("head").insertAdjacentHTML("beforeend", `<link id="css_dark" rel="stylesheet" type="text/css" href="${process.env.REACT_APP_URL_BASE}/dark.css" />`)

            setDarkTheme();
            import('./Dark.scss');

        }else{
            setDefaultTheme();
        }

    }, [theme]);

    const onChange = () => {

        if(theme===THEME_DARK){
            window.localStorage.setItem("theme", "light");
        }else{
            window.localStorage.setItem("theme", "dark");

            /*if(document.getElementById("css_dark")){
                document.getElementById("css_dark").remove();
            }*/
        }

        setTheme(theme===THEME_LIGHT ? THEME_DARK : THEME_LIGHT);
        window.location.reload();
    }

    return (
        <div id='toggleTheme'>
            <label id="switch" className="switch">
                <input  id="slider" type="checkbox" checked={ theme===THEME_LIGHT } onChange={onChange} />
                <span className="slider round" />
            </label>
        </div>
    );
}

export const setDefaultTheme = () => {
    import('../../../../node_modules/@syncfusion/ej2-base/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-inputs/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-lists/styles/tailwind.css');
    //import('../../../../node_modules/@syncfusion/ej2-react-popups/styles/bootstrap.css');
    import('../../../../node_modules/@syncfusion/ej2-react-popups/styles/tailwind.css');
    //import('../../../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap.css';
    //import('../../../../node_modules/@syncfusion/ej2-react-buttons/styles/bootstrap4.css';
    import('../../../../node_modules/@syncfusion/ej2-react-buttons/styles/tailwind.css');
    //import('../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
    import('../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-calendars/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-splitbuttons/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-layouts/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-grids/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-navigations/styles/tailwind.css');
    //import('../../../../ode_modules/@syncfusion/ej2-react-notifications/styles/tailwind.css');
    //import('../../../../node_modules/@syncfusion/ej2-react-notifications/styles/material.css');
    import('../../../../node_modules/@syncfusion/ej2-react-notifications/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-richtexteditor/styles/tailwind.css');
    import('../../../../node_modules/@syncfusion/ej2-react-pivotview/styles/tailwind.css');
    //import('../../../../node_modules/@syncfusion/ej2-react-treegrid/styles/tailwind.css');
    import('../../../sass/base/extension_file.scss');

    setTimeout(() => {
        import('../../../../node_modules/@syncfusion/ej2/check-box/compatibility/fluent.css');        
    }, 1000);
}

export const setDarkTheme = () => {
    import('../../../../node_modules/@syncfusion/ej2-base/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-inputs/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-lists/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-popups/styles/tailwind-dark.css');
    // import('../../../../node_modules/@syncfusion/ej2-react-buttons/styles/tailwind-dark.css'); //conflito com o "Verificado Input"
    import('../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-calendars/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-splitbuttons/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-layouts/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-grids/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-navigations/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-notifications/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-richtexteditor/styles/tailwind-dark.css');
    import('../../../../node_modules/@syncfusion/ej2-react-pivotview/styles/tailwind-dark.css');
    import('../../../sass/base/extension_file_dark.scss');

    setTimeout(() => {
        import('../../../../node_modules/@syncfusion/ej2/check-box/compatibility/fluent-dark.css');
    }, 1000);
}

export default ToggleTheme;

