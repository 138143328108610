import React, {Component} from 'react';
import Twemoji from 'react-twemoji';
import './Idioma.scss';
import baseConnect from '../api/managerData';
import {changeIdioma, ativarLoading} from '../actions';
import { connect } from 'react-redux';
import * as ROUTE from '../config/route';
import IdiomaData from '../model/IdiomaData';

class Idioma  extends Component {

    constructor(args){
        super(args);
        this.state={selectedIdiomaBr:'selected' ,
                    selectedIdiomaEs:null,
                    selectedIdiomaEn:null,
                    selectedIdiomaEsBo:null
                };

        this.idiomaData= new IdiomaData();
    }

    componentDidMount() {
        let lang = IdiomaData.getLangSaved();
        if(!lang){
          lang='pt'
        }
        this.idiomaChange(lang);
    }

    idiomaChange= async (lang)=>{
        if(this.idiomaData.langSelected === lang){
            return;
        }

        switch(lang){
            case 'pt':
                this.setState({
                    selectedIdiomaBr:'selected',
                    selectedIdiomaEs:null,
                    selectedIdiomaEn:null,
                    selectedIdiomaEsBo:null});
            break;

            case 'es':
                this.setState({
                    selectedIdiomaBr:null,
                    selectedIdiomaEs:'selected',
                    selectedIdiomaEn:null,
                    selectedIdiomaEsBo:null});
            break;

            case 'en':
                this.setState({
                    selectedIdiomaBr:null ,
                    selectedIdiomaEs:null,
                    selectedIdiomaEn:'selected',
                    selectedIdiomaEsBo:null});
            break;

            case 'es-bo':
                this.setState({
                    selectedIdiomaBr:null ,
                    selectedIdiomaEs:null,
                    selectedIdiomaEn:null,
                    selectedIdiomaEsBo:'selected'});
                break;

            default:
                this.setState({
                    selectedIdiomaBr:'selected',
                    selectedIdiomaEs:null,
                    selectedIdiomaEn:null,
                    selectedIdiomaEsBo:null});
            break;
        }

        this.props.ativarLoading({loading:true});
        IdiomaData.saveLang(lang);

        const response = await baseConnect.post(ROUTE.IDIOMA_LIST, {
            lang_locale:lang
        }).catch(error => {
            this.props.ativarLoading({loading:false});
            if(error && error.response){
               alert(error.response.data.message);
            }
        });

        if(response && response.data){
            this.idiomaData.setIdiomaData(response.data, lang);
            this.props.changeIdioma(this.idiomaData);
            this.props.ativarLoading({loading:false});
        }

    }

    render(){
        return (
            <Twemoji className="position-idioma" options={{ className: 'twemoji' }}>
                <span className={`flag ${this.state.selectedIdiomaBr}`} onClick={(e)=>this.idiomaChange('pt')} role="img" aria-label="flag-brazil">🇧🇷</span>
                <span className={`flag ${this.state.selectedIdiomaEs}`} onClick={(e)=>this.idiomaChange('es')} role="img" aria-label="flag-spain">🇪🇸</span>
                {/*<span className={`flag ${this.state.selectedIdiomaEn}`} onClick={(e)=>this.idiomaChange('en')} role="img" aria-label="flag-united-states">🇺🇸</span>*/}
                {/*<span className={`flag ${this.state.selectedIdiomaEsBo}`} onClick={(e)=>this.idiomaChange('es-bo')} role="img" aria-label="flag-bolivia">🇧🇴</span>*/}
            </Twemoji>
        );
    }
}

export default connect( null, {changeIdioma, ativarLoading})(Idioma);
