
import NotificaData from '../model/NotificaData';

export const emptyCacheStorageVersion =(clearAll=false)=>{
    sessionStorage.clear();
    if(clearAll){
      localStorage.clear();
    }else{
        localStorage.removeItem('USER_DATA_SESSION_BKM');
        localStorage.removeItem('USER_DATA');
        for (var i = 0; i < localStorage.length; i++){
            if (localStorage.key(i).substring(0,21) === 'dashboard_data_local_') {
                localStorage.removeItem(localStorage.key(i));
            }
        }
    }
}

export const fetchMetaVersion = (props) => {
    try {
        fetch(process.env.REACT_APP_URL_BASE_META_VERSION+'/meta.json', {
            cache: 'no-store'
        })
            .then(function (response) { return response.json(); })
            .then( (meta) =>{
            const newVersion = JSON.stringify(meta.version);
            const currentVersion = localStorage.getItem('APP_VERSION');
            const isUpdated = newVersion === currentVersion;

            const dateData = new Date();
            const dtSave = localStorage.getItem('date_save_data_session');

            if(dtSave && (dateData.getTime() - dtSave  >= 432000000)){
                emptyCacheStorageVersion(false);
            }

            setTimeout(() => {
              if(!isUpdated){
                  props.notificar(NotificaData.createToastNotifica('Updating: '+process.env.REACT_APP_VERSION_APP, NotificaData.TIPO_MESSAGE.INFORMATIVO));
                  emptyCacheStorageVersion(false);
                  localStorage.setItem('APP_VERSION', newVersion);
                  document.location.reload(true);
              }
            }, 3000);
        });
    }
    catch (err) {
        console.error(err);
    }
}

export const getAppVersionFromMeta = async () => {
    try {
        await fetch(process.env.REACT_APP_URL_BASE_META_VERSION+'/meta.json', {
            cache: 'no-store'
        })
            .then(function (response) { return response.json(); })
            .then( (meta) =>{
            const versionApp = meta.version_app;
            return versionApp;
        });
    }
    catch (err) {
        console.error(err);
        return '';
    }
}

export const updateVersionInfo = (id, versionApp) =>{
    if(id){
        const elemVersion = document.getElementById(id);
        if(elemVersion){
            elemVersion.innerHTML=`Click update: ${process.env.REACT_APP_VERSION_APP} to ${versionApp}`;
        }
    }
}