import React, { Component } from 'react';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import IdiomaData from '../../model/IdiomaData';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import './SidebarRightFilterBase.scss';
import { HeaderContext } from "../../HeaderContext";

export default class SidebarRightFilterBase extends Component {
    constructor(props) {
        super(props);
        this.tipoBtnAplicar = 0;
        //this.enableDock = true;
        //this.dockSize = '72px';
        this.height = '100%';
        this.width = '100%';
        this.zIndex = 2000;
        this.position = 'Right';
        this.popupHeight = '250px';

        this.state = {
            showFilterClass: 'hide'
        };

        this.hasChangeParameter = false;
        this.hasUserSelectedParameter = false;
        this.runFilterAutomatic = true;
       
    }

    clickAplicar = () => {
       
    }

    buildRenderFilterButtonAplica = () => {
        const { returnLooksForNotifications } = this.context
        switch (this.tipoBtnAplicar) {
            case 0:
                return (<ButtonComponent className="center-align-bt bt-custon mt-4" ref={btn => this.btnAplicar = btn} onClick={() => { this.clickAplicar(); returnLooksForNotifications();}} >{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'aplicar').text}</ButtonComponent>);
            case 1:
                return (<ProgressButtonComponent className="center-align-bt bt-custon mt-4" ref={btn => this.btnAplicar = btn} enableProgress={true} duration={150000}  onClick={this.clickAplicar} content={IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'aplicar').text} />);
            default:
                break;
        }
    }

    createdSidebarFilter = () => {
        this.setState({ showFilterClass: "show" });
    }

    toggleClick() {
        this.sidebarobj.toggle();
    }

    closeClick = () => {
        this.sidebarobj.hide();
    }

    openClick = () => {
        this.sidebarobj.show();
        this.setState({
            width: '100%',
            zIndex: 2000
        })
    }

    noRecordsTemplateFilter = () => {
        return <span>{IdiomaData.selectGroupItem(this.props.idiomaData, 'messages', 'registro_nao_localizado').text}</span>
    }

    focusCb() {
        this.showPopup();
    }

    resetComboBox(obj) {
        if (obj) {
            obj.text = null;
            obj.enabled = false;
            obj.dataBind();
        }
    }

    render() { 
        return (
            <div>
                <span id="icon-button-menu-side-bar" className="e-icons menu default e-rtl" onClick={this.openClick}></span>
                <SidebarComponent animate={true}
                    id="default-sidebar"
                    ref={Sidebar => this.sidebarobj = Sidebar}
                    position={this.position}
                    width={this.width}
                    zIndex={this.zIndex}
                    height={this.height}
                    created={this.createdSidebarFilter}
                >
                    <div className={`filter-container filter-sidebar-${this.state.showFilterClass}`}>
                        {this.buildRenderFilterBase()}
                        <div className="filter-container-element text-center">
                            {this.buildRenderFilterButtonAplica()}
                        </div>
                    </div>
                </SidebarComponent>
            </div>
        );
    }

    buildRenderFilterBase = () => {
        return (<div></div>);
    }
}

SidebarRightFilterBase.contextType = HeaderContext;
