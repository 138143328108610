
export const LOGIN = 'login';
export const LOGIN_AUTH = '/login';
export const LOGOUT_AUTH = '/logout';

export const HOME = '/';
export const HOME_LANCAMENTO = '/lancamento/home';
export const IDIOMA_LIST = '/idioma/list';

//clima
export const CLIMA_VALIDAR = '/clima/verificado/list/update';

export const ESTOQUE_INSUMO_AGRICOLA_VALIDAR = '/estoque/insumo/agricola/validar/update';
export const ESTOQUE_INSUMO_PECUARIO_VALIDAR = '/estoque/insumo/pecuario/validar/update';

export const FINANCEIRO_RECEITA_VALIDAR = '/financeiro/receita/validar/update';
export const FINANCEIRO_DESPESA_VALIDAR = '/financeiro/despesa/validar/update';
export const FINANCEIRO_OUTRO_DEBITO_VALIDAR = '/financeiro/outro/debito/validar/update';
export const FINANCEIRO_OUTRO_CREDITO_VALIDAR = '/financeiro/outro/credito/validar/update';
export const EQUIPE_VALIDAR = '/equipe/validar/update';

export const PECUARIA_NASCIMENTO_VALIDAR = '/pecuaria/nascimento/validar/update';
export const PECUARIA_CONFINAMENTO_VALIDAR = '/pecuaria/confinamento/validar/update';
export const PECUARIA_TIP_SEMICONFINAMENTO_VALIDAR = '/pecuaria/tipsemiconfinamento/validar/update';
export const PECUARIA_RECRIA_NO_COCHO_VALIDAR = '/pecuaria/recriacochoconfinamento/validar/update';
export const PECUARIA_DESMAME_VALIDAR = '/pecuaria/desmame/validar/update';
export const PECUARIA_MOV_ENTRADA_VALIDAR = '/pecuaria/mov/entrada/validar/update';
export const PECUARIA_MOV_SAIDA_VALIDAR = '/pecuaria/mov/saida/validar/update';
export const PECUARIA_MORTE_VALIDAR = '/pecuaria/morte/validar/update';

export const PECUARIA_AREA_VALIDAR = '/pecuaria/area/validar/update';
export const PECUARIA_ESTOQUE_PECUARIO_VALIDAR = '/pecuaria/estoque/pecuario/validar/update';

export const PECUARIA_REPROD_MONTA_TOQUE_VALIDAR = '/pecuaria/reproducao/monta/toque/validar/update';
export const PECUARIA_REPROD_NASCIMENTO_DESMAME_VALIDAR = '/pecuaria/reproducao/nascimento/desmame/validar/update'

export const PECUARIA_MAQUINA_VALIDAR = '/maquina/validar/update';
export const PECUARIA_MAQUINA_IMPLEMENTOS_VALIDAR = '/maquina/implementos/validar/update';
export const PECUARIA_MAQUINA_PARQUE_VALIDAR = '/maquina/parque/validar/update';

export const PECUARIA_AGRICOLA_PRODUCAO_VOLUMOSO_DESPESA_VALIDAR = '/agricola/producao/volumoso/despesa/validar/update';
export const PECUARIA_AGRICOLA_PRODUCAO_VOLUMOSO_PRODUCAO_VALIDAR = '/agricola/producao/volumoso/producao/validar/update';
